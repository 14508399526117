import React from 'react';
import {View} from 'react-native';
import theme from '../utilities/theme';

interface Props {
  spacing: keyof typeof theme.spacing;
}
export function Spacer({spacing}: Props) {
  return <View style={{height: theme.spacing[spacing]}} />;
}
