import React, { useEffect, useLayoutEffect, useState } from "react";
import { SearchView } from "./SearchView";
import { useSearchResults } from "../../../api/hooks/useSearchResultsQuery/useSearchResultsQuery";
import {
  Actions,
  ResultRowId,
  ResultType,
  SearchFilter,
  SearchResult,
  SearchScreenNavigationProp,
  SearchScreenRouteProp,
} from "./types";
import axios from "axios";
import { ErrorView } from "../../../components/ErrorView";
import { LoadingView } from "../../../components/LoadingView";
import { useNavigation, useRoute } from "@react-navigation/native";
import FilterIcon from "../../../resources/icons/filter-outline.svg";
import { useTranslation } from "react-i18next";
import { getFilterFromResultType } from "../../../sections/Database/Search/helpers";
import { SearchBox } from "../../../components/SearchBox";
import { NoInternetView } from "../../../components/NoInternetView";
import { NoResultsView } from "./components/NoResultsView";
import { EmptySearchView } from "./components/EmptySearchView";
import { useKeyboard } from "../../../utilities/hooks/useKeyboard/useKeyboard";
import { HeaderButton } from "../../../components/HeaderButton";
import { useAnalytics } from "../../../api/hooks/useAnalytics/useAnalytics";

export function SearchScreen() {
  const navigation = useNavigation<SearchScreenNavigationProp>();
  const [oldData, setOldData] = useState<SearchResult[]>([]);
  const [searchFieldText, setSearchFieldText] = useState("");
  const { results, isLoading, error } = useSearchResults(searchFieldText);
  const { t } = useTranslation();
  const route = useRoute<SearchScreenRouteProp>();
  const selectedFilters = route.params.selectedFilters;
  const { keyboardHeight, keyboardShown } = useKeyboard();
  const sendAnalyticEvent = useAnalytics();

  useLayoutEffect(() => {
    const onFilterButtonPressed = () => {
      navigation.navigate("SearchFilter", {
        selectedFilters: selectedFilters,
      });
    };
    navigation.setOptions({
      headerTitleAlign: "center",
      headerTitle: () => {
        return (
          <SearchBox
            placeholder={t("Database.searchPlaceholder")}
            onExternalSearchFieldTextChange={setSearchFieldText}
          />
        );
      },
      headerRight: () => (
        <HeaderButton onPress={onFilterButtonPressed} content={FilterIcon} />
      ),
    });
  }, [selectedFilters]);

  useEffect(() => {
    if (results) {
      setOldData(results);
    }
  }, [results]);

  const filteredData = filterDataForFilters(results, selectedFilters);
  const actions: Actions = {
    onSearchRowPress: ({ id, type, name }: ResultRowId) => {
      sendAnalyticEvent({
        name: "database_search_row_pressed",
        data: JSON.stringify({ name: name, type: ResultType[type] }),
      });
      switch (type) {
        case ResultType.lrOne:
          navigation.navigate("LRCalculator", {
            id: { id: id, type: "test" },
          });
          break;
        case ResultType.lrList:
          navigation.navigate("LRList", {
            diseaseName: name,
            diseaseId: { type: "Test", id: id },
          });
          break;
        case ResultType.disease:
          navigation.navigate("Disease", {
            diseaseId: id,
          });
          break;
        case ResultType.probabilities:
          navigation.navigate("Probability", {
            id: id,
          });
          break;
      }
    },
    onBlockedSearchRowPress: () => {
      navigation.navigate("AccountModal");
    },
    onClearButtonPress: () => {
      setSearchFieldText("");
    },
  };

  if (error) {
    return axios.isAxiosError(error) ? <NoInternetView /> : <ErrorView />;
  }

  if (isLoading) {
    return oldData.length === 0 && searchFieldText !== "" ? (
      <LoadingView />
    ) : (
      <SearchView
        data={oldData}
        actions={actions}
        keyboardHeight={keyboardHeight}
        keyboardShown={keyboardShown}
      />
    );
  }

  if (searchFieldText == "") {
    return <EmptySearchView />;
  }

  return filteredData.length > 0 ? (
    <SearchView
      keyboardHeight={keyboardHeight}
      keyboardShown={keyboardShown}
      data={filteredData}
      actions={actions}
    />
  ) : (
    <NoResultsView />
  );
}

function filterDataForFilters(
  data: SearchResult[] | undefined,
  filter: SearchFilter[]
) {
  if (data && data.length > 0) {
    return data.filter((x) => filter.includes(getFilterFromResultType(x.type)));
  } else {
    return [];
  }
}
