import { useContext } from "react";
import { SessionContext } from "../../../context/Session/Session";

export function useIsIdBlocked(): (id: number) => boolean {
  const { access } = useContext(SessionContext);
  return (id: number) => {
    const lastDigit = id % 10;
    return lastDigit < 8 && access === "none" ? true : false;
  };
}
