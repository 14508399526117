export const palette = {
  blue: "#0a84ff",
  green: "#34c759",
  orange: "#ff9500",
  yellow: "#ffcc00",
  red: "#ff453A",

  black: "#000000",
  white: "#FFFFFF",
  gray: "#636366",
  darkGray: "#3C3C43",
  lightGray: "#aeb0b2",
  lighterGray: "#e5e5ea",
  lightestGray: "#f3f3f8",

  transparent: "rgba(255,255,255,0)",
};

const theme = {
  colors: {
    transparent: palette.transparent,

    surfaceDefault: palette.lightestGray,
    surfaceSecondary: palette.white,

    buttonDefault: palette.blue,
    buttonWarning: palette.orange,
    buttonCritical: palette.red,
    buttonSuccess: palette.green,
    buttonSecondary: palette.darkGray,
    buttonSubdued: palette.gray,

    textPrimary: palette.darkGray,
    textSecondary: palette.gray,
    textCritical: palette.red,
    textSubdued: palette.lightGray,
    textPlaceholder: palette.gray,
    textOnFilledButton: palette.white,
    textOnActiveRadioButton: palette.white,
    textOnInactiveRadioButton: palette.darkGray,

    dividerDefault: palette.gray,

    textInputBorderDefault: palette.darkGray,

    paginatorInactive: palette.lightGray,
    paginatorActive: palette.darkGray,

    sliderMinimumTrack: palette.blue,
    sliderMaximumTrack: palette.lighterGray,

    activeRadioButton: palette.blue,
    inactiveRadioButton: palette.white,
    radioButtonBorder: palette.lightGray,

    iconDefault: palette.gray,
    iconSubdued: palette.lightGray,
  },
  dimensions: {
    rowHeight: 44,
    maxCardWidth: 600,
    maxButtonWidth: 600,
    maxInputWidth: 600,
    smallIconSize: 16,
    iconDefault: 24,
    searchBarWidth: 180,
  },
  spacing: {
    extraTight: 4,
    tight: 8,
    baseTight: 12,
    base: 16,
    loose: 24,
    extraLoose: 40,
    defaultHorizontalGutter: 16,
    defaultVerticalGutter: 24,
    subtitleIndent: 16,
    extraCarouselWidthSubstraction: 36,
    spaceBetweenCarouselCards: 16,
  },
  breakpoints: {
    phone: 0,
    tablet: 768,
  },
  fontSize: {
    large: 20,
    normal: 16,
    small: 12,
  },
  componentShadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
};

export default theme;
