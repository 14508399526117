import { Text, View } from "react-native";
import theme from "../../../../utilities/theme";
import { Actions } from "../types";
import { useTranslation } from "react-i18next";
import { SpaceBetween } from "../../../../components/SpaceBetween";
import FlaskIcon from "../../../../resources/icons/flask.svg";
import WikiIcon from "../../../../resources/icons/wiki.svg";
import BookIcon from "../../../../resources/icons/book.svg";
import { Disease } from "../../../../api/hooks/useDiseaseQuery/types";
import { IconButton, IconButtonType } from "../../../../components/IconButton";
import { Card } from "../../../../components/Card";

interface Props {
  disease: Disease;
  actions: Actions;
}

const ICON_BUTTON_WIDTH = 64;

export function AdditionalInfoLinksCard({ disease, actions }: Props) {
  const { t } = useTranslation();

  const circleButtonTextStyle = {
    marginTop: theme.spacing.extraTight,
    color: theme.colors.textSecondary,
    fontSize: theme.fontSize.small,
  };

  const testButton =
    disease.testDiseaseId.length > 0 ? (
      <View style={{ alignItems: "center", width: ICON_BUTTON_WIDTH }}>
        <IconButton
          style={IconButtonType.circled}
          icon={FlaskIcon}
          onPress={() => actions.onTestButtonPress()}
        />
        <Text style={circleButtonTextStyle}>{t("Database.tests")}</Text>
      </View>
    ) : null;

  const wikiButton = disease.wiki ? (
    <View style={{ alignItems: "center", width: ICON_BUTTON_WIDTH }}>
      <IconButton
        style={IconButtonType.circled}
        icon={WikiIcon}
        onPress={() => actions.onWikiButtonPress()}
      />
      <Text style={circleButtonTextStyle}>{t("Database.wikipedia")}</Text>
    </View>
  ) : null;

  const referencesButton =
    disease.sources.length > 0 ? (
      <View style={{ alignItems: "center", width: ICON_BUTTON_WIDTH }}>
        <IconButton
          style={IconButtonType.circled}
          icon={BookIcon}
          onPress={() => actions.onReferenceButtonPress()}
        />
        <Text style={circleButtonTextStyle}>{t("Database.references")}</Text>
      </View>
    ) : null;

  return (
    <View
      style={{
        width: "100%",
        marginHorizontal: theme.spacing.defaultHorizontalGutter,
      }}
    >
      <Card>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <SpaceBetween spacing={"extraLoose"}>
            {testButton}
            {wikiButton}
            {referencesButton}
          </SpaceBetween>
        </View>
      </Card>
    </View>
  );
}
