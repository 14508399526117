import { useQuery } from "../../../api/hooks/useQuery/useQuery";
import axios, { AxiosError } from "axios";
import { ForgotPasswordResponse } from "./types";

export function useServerForgotPassword({
  email,
  onCompletion,
  onError,
}: {
  email: string;
  onCompletion: (response: ForgotPasswordResponse) => void;
  onError: (error: Error) => void;
}): { execute: () => void } {
  const { execute: executeWithoutJson } = useQuery<ForgotPasswordResponse>({
    server: "auth",
    path: `auth/start-forgot-password`,
    type: "POST",
    onError: (error) => {
      if (
        error &&
        axios.isAxiosError(error) &&
        (error as AxiosError).response?.status === 404
      ) {
        onCompletion("NoUser");
      } else {
        onError(error);
      }
    },
    onCompletion: () => {
      onCompletion("EmailSent");
    },
  });

  const execute = () => executeWithoutJson(JSON.stringify({ email }));

  return { execute };
}
