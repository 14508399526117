import { useEffect, useState } from "react";
import { useQuery } from "../useQuery/useQuery";

export function useBrowseDiseaseSpecialtiesQuery() {
  const [specialties, setSpecialties] = useState<string[]>([]);
  const { execute, isLoading, error } = useQuery<string[]>({
    server: "database",
    path: `browse/diseases`,
    type: "GET",
    onCompletion: (response) => {
      setSpecialties(response.data);
    },
  });
  useEffect(() => {
    execute();
  }, []);
  return { specialties, isLoading, error };
}
