import { LoadingView } from "./LoadingView";
import axios from "axios";
import { NoInternetView } from "./NoInternetView";
import { ErrorView } from "./ErrorView";
import React, { ReactElement } from "react";
import { logError } from "../utilities";

interface Props {
  isLoading: boolean;
  error: Error | null;
  content: ReactElement;
}

export function GenericScreen({ isLoading, error, content }: Props) {
  if (isLoading) {
    return <LoadingView />;
  } else {
    if (error) {
      logError(error);
      return axios.isAxiosError(error) ? <NoInternetView /> : <ErrorView />;
    } else {
      return content;
    }
  }
}
