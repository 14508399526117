import React from "react";
import { ExplanatoryRow } from "../../components/ExplanatoryRow";
import { Card } from "../../components/Card";
import { GenericViewContainer } from "../../components/GenericViewContainer";
import { Spacer } from "../../components/Spacer";

export function AboutView() {
  return (
    <GenericViewContainer>
      <Card>
        <ExplanatoryRow
          header={"What is this app?"}
          title={
            "The goal of this app is to hold the symptoms and numbers that a brain just can't. Who can be expected to remember all the potential symptoms of the thousands of diseases that exist? Furthermore, it is equally impossible to memorize the utility of the tests designed to diagnose these diseases.\n" +
            "\n" +
            "This app was designed and its data collected by a Canadian internist/nephrologist. Data is from an adult population unless otherwise noted."
          }
        />
      </Card>
      <Spacer spacing={"base"} />
      <Card>
        <ExplanatoryRow
          header={"Qualitative Probabilities Explained"}
          title={
            "very common / likely: ≥ 50%" +
            "; " +
            "common / likely: 10 - 49%" +
            "; " +
            "uncommon / somewhat unlikely: 1 - 9%" +
            "; " +
            "rare: < 1%" +
            "; " +
            "somewhat rare / unlikely: 0.1 - 0.9%" +
            "; " +
            "very rare / very unlikely: 0.01 - 0.09%" +
            "; " +
            "extremely rare / extremely unlikely: < 0.01%"
          }
        />
      </Card>
      <Spacer spacing={"base"} />
      <Card>
        <ExplanatoryRow
          header={"Diseases"}
          title={
            "Symptom data was inputted manually from the primary literature and so mistakes may exist. When possible, sources that mentioned symptoms from primary case series were utilized." +
            "\n\n" +
            "There was some interpretation done of the primary literature. Sometimes, acuity was not mentioned and so an educated guess was made, based on clinical experience, on how the disease would be expected to present. Furthermore, some symptoms mentioned in references were not included when they were thought not to be causatively associated with the disease process. Attempts were made to present symptom frequencies as would be expected at presentation for any given disease. Therefore, some literature frequencies reported in the literature were sometimes reduced to account for expected prevalence at disease presentation. Chronic-type symptoms (ex: coronary artery disease) had their frequencies unchanged. Acute-type symptoms occurring in chronic diseases (ex: VTE in nephrotic syndrome) had their frequencies adjusted to what would be expected for an annual timespan." +
            "\n\n" +
            "An emergent disease is one where, if not treated properly, death or irreversible organ damage is expected to have a greater than 10% chance of occurring within 1 week of presentation." +
            "\n\n" +
            "For incidence data, values for a general US population were preferred. Data from other locations was utilized when quality US data could not be found. When data was presented for multiple different time periods, the most recent time period was chosen. When only prevalences could be found, incidences were calculated by roughly estimating the average disease duration. Diseases with onset in childhood or early adulthood without incidence data but with birth frequency data had incidence data estimated by taking the average US birth rate and multiplying by the birth prevalence. When possible, age and sex adjusted incidences were reported. When epidemiologic data could not be found, an educated guess, based on personal clinical experience, was used to roughly estimate the incidence."
          }
        />
      </Card>
      <Spacer spacing={"base"} />
      <Card>
        <ExplanatoryRow
          header={"Differential Diagnosis"}
          title={
            'Results are arranged by probability which is calculated from the ddx disease result estimated population incidence divided by the sum of all potential ddx disease result incidences. To calculate these incidences, the root disease incidence (before any complications) is taken and then adjusted for the estimated frequency of any complicating diseases and/or symptoms arising from the root disease. These additional probability modifiers are assumed to be independent. Very common and common symptoms/complicating diseases do not change the probability. Uncommon ones change the probability by 1/10 and rare ones change the probability by 1/100. Some entities, especially procedures, have more gradations in the "rare" range: "somewhat rare" decreases the probability by 1/100, "very rare" decreases by 1/1000 and "exceedingly rare" decreases by 1/10,000. If the incidence of a symptom or complicating disease has not yet been categorized, it is assumed have a frequency of 1/10. Diseases with rough estimates of > 1/1000 per 100,000,000 person-years have the incidences set to 5,000. Likewise, diseases with estimated incidence of < 1,000 have their incidence set to 10 and diseases whose incidence has yet to be categorized have their incidence set to 100. Incidences which were 0 in relatively small scale studies had their values set to 0.01 as these may technically still be possible.\n\nIf two or more solutions exist with the same root disease (but different complicating diseases), only one is chosen at random for presentation.' //Any results arising from comorbid diseases that have been added have their root incidence set to 1 per person-year.'
          }
        />
      </Card>
      <Spacer spacing={"base"} />
      <Card>
        <ExplanatoryRow
          header={"Tests"}
          title={
            "The entire literature was not culled, although meta-analyses, when found, were preferred over individual studies. Attempts were made to find the most recent studies and to include data from those that were strong. If a study did not report likelihood ratios specifically, they were calculated manually. If specificity or sensitivity was 100% in these studies (meaning one subgroup of the 2x2 table had zero patients), all cells had a value of 0.5 added to prevent a zero or infinite LR without a confidence interval. Confidence intervals are reported to reinforce the fact that an average value only represents a range of values that, in some cases, can vary over a very large range."
          }
        />
      </Card>
    </GenericViewContainer>
  );
}
