import { useEffect, useState } from "react";
import Purchases, { CustomerInfo } from "react-native-purchases";

export function useRestorePurchases({
  onCompletion,
  onError,
}: {
  onCompletion: (customerInfo: CustomerInfo) => void;
  onError: (error: Error) => void;
}) {
  const [executeRestore, setExecuteRestore] = useState(false);
  useEffect(() => {
    const asyncRestore = async () => {
      Purchases.restorePurchases()
        .then((customerInfo) => {
          if (onCompletion) {
            onCompletion(customerInfo);
          }
          setExecuteRestore(false);
        })
        .catch((err) => {
          onError(err);
          setExecuteRestore(false);
        });
    };
    if (executeRestore) {
      asyncRestore();
    }
  }, [executeRestore]);
  return { execute: () => setExecuteRestore(true) };
}
