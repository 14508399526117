import React, { useContext } from "react";
import LogOutIcon from "../../resources/icons/log-out-outline.svg";
import DeleteIcon from "../../resources/icons/trash-outline.svg";
import MailIcon from "../../resources/icons/mail-outline.svg";
import ReloadIcon from "../../resources/icons/reload-outline.svg";
import InfoIcon from "../../resources/icons/information-circle-outline.svg";
import ProfileIcon from "../../resources/icons/person-circle-outline.svg";
import { Alert, Platform, Text, View } from "react-native";
import theme from "../../utilities/theme";
import { useTranslation } from "react-i18next";
import { SessionContext } from "../../context/Session/Session";
import { GenericViewContainer } from "../../components/GenericViewContainer";
import { Card } from "../../components/Card";
import { Spacer } from "../../components/Spacer";
import { ExplanatoryRow } from "../../components/ExplanatoryRow";
import { SubscriptionButton } from "./components/SubscriptionButton";
import { GenericButton } from "../../components/GenericButton";
import { Actions } from "./types";
import { SubscriptionState } from "./hooks/useGetSubscription/types";
import { PlatformSpecificIcon } from "../../components/PlatformSpecificIcon";

interface Props {
  subscription: SubscriptionState | null;
  actions: Actions;
  showPurchaseSubscription: boolean;
}

export function AccountView({
  subscription,
  actions,
  showPurchaseSubscription,
}: Props) {
  const { t } = useTranslation();
  const { userInfo, access } = useContext(SessionContext);

  const devButtons = __DEV__ ? (
    <>
      <Spacer spacing="base" />
      <GenericButton
        type="outlined"
        showBorder={false}
        width={"100%"}
        color="buttonSecondary"
        title="Delete Normal Token and Refresh"
        onPress={actions.deleteNormalTokenAndThenRefresh}
      />
    </>
  ) : null;

  return (
    <GenericViewContainer
      style={{ backgroundColor: theme.colors.surfaceDefault }}
    >
      <Card>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <PlatformSpecificIcon
            icon={ProfileIcon}
            size={32}
            color={theme.colors.textPrimary}
          />

          <Text
            style={{
              marginLeft: theme.spacing.tight,
              color: theme.colors.textPrimary,
            }}
          >
            {userInfo.username}
          </Text>
        </View>
      </Card>
      {access === "none" && Platform.OS === "web" ? (
        <>
          <Spacer spacing={"base"} />
          <Card backgroundColor={theme.colors.buttonSuccess}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontSize: theme.fontSize.normal,
                  marginLeft: theme.spacing.tight,
                  color: "white",
                }}
              >
                Purchase a subscription via the Android or iOS app
              </Text>
            </View>
          </Card>
        </>
      ) : null}
      {showPurchaseSubscription ? (
        <>
          <Spacer spacing="base" />
          <Card>
            <ExplanatoryRow header={t("Account.subscriptionsHeader")}>
              <Spacer spacing="tight" />
              <SubscriptionButton
                type={"yearly"}
                isSubscribed={
                  subscription?.activeSubscriptionType
                    ? subscription?.activeSubscriptionType === "yearly"
                    : false
                }
                onPress={() => actions.purchaseAnnualSubscription()}
                cost={subscription?.annualPrice ?? "error"}
              />
              <Spacer spacing="base" />
              <SubscriptionButton
                type={"monthly"}
                isSubscribed={
                  subscription?.activeSubscriptionType
                    ? subscription.activeSubscriptionType == "monthly"
                    : false
                }
                onPress={() => actions.purchaseMonthlySubscription()}
                cost={subscription?.monthlyPrice ?? "error"}
              />
              <Spacer spacing="base" />
              <Text style={{ color: theme.colors.textSecondary }}>
                {t("Account.annualDescription")}
              </Text>
              <Spacer spacing="base" />
              <GenericButton
                type="outlined"
                title="Restore Purchases"
                icon={ReloadIcon}
                onPress={() => actions.restorePurchases()}
              />
            </ExplanatoryRow>
          </Card>
        </>
      ) : null}
      <Spacer spacing="base" />
      <GenericButton
        icon={LogOutIcon}
        color="buttonSecondary"
        type="outlined"
        showBorder={false}
        width={"100%"}
        title="Logout"
        onPress={() => {
          actions.logout();
        }}
      />
      <Spacer spacing={"base"} />
      <GenericButton
        icon={MailIcon}
        color="buttonSecondary"
        type="outlined"
        showBorder={false}
        width={"100%"}
        title="Contact Us"
        onPress={() => {
          actions.contactUs();
        }}
      />
      <Spacer spacing="base" />
      <GenericButton
        type="outlined"
        showBorder={false}
        width={"100%"}
        color="buttonSecondary"
        title="About"
        icon={InfoIcon}
        onPress={() => {
          actions.showInfo();
        }}
      />
      <Spacer spacing="base" />
      <GenericButton
        type="outlined"
        showBorder={false}
        width={"100%"}
        color="buttonCritical"
        title="Delete Account"
        icon={DeleteIcon}
        onPress={() => {
          deleteAlert(() => {
            actions.deleteAccount();
          });
        }}
      />
      {devButtons}
    </GenericViewContainer>
  );
}

function deleteAlert(onOK: () => void) {
  if (Platform.OS === "web") {
    if (confirm("Are you sure you want to delete your docLogica account?")) {
      onOK();
    }
  } else {
    Alert.alert(
      "Delete Account",
      "Are you sure you would like to delete your docLogica account?",
      [
        {
          text: "Cancel",
          style: "cancel",
        },
        { text: "Delete", style: "destructive", onPress: () => onOK() },
      ]
    );
  }
}
