import React from "react";
import { StyleSheet, Text } from "react-native";
import theme from "../../../../utilities/theme";
import { GenericViewContainer } from "../../../../components/GenericViewContainer";

export function EmptySearchView() {
  return (
    <GenericViewContainer style={styles.container}>
      <Text style={styles.text}>Enter a Query</Text>
    </GenericViewContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: "center",
  },
  text: {
    fontSize: theme.fontSize.normal,
    fontWeight: "bold",
    marginTop: theme.spacing.tight,
    color: theme.colors.textSubdued,
  },
});
