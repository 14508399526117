import React from "react";
import { LRCalculatorView } from "./LRCalculatorView";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useTestQuery } from "../../../../api/hooks/useTestQuery/useTestQuery";
import {
  Actions,
  LRCalculatorScreenNavigationProp,
  LRCalculatorScreenRouteProp,
} from "./types";
import { Linking } from "react-native";
import { TestId } from "../../../../api/hooks/useTestQuery/types";
import { GenericScreen } from "../../../../components/GenericScreen";

export function LRCalculatorScreen() {
  const route = useRoute<LRCalculatorScreenRouteProp>();
  const navigation = useNavigation<LRCalculatorScreenNavigationProp>();
  const id: TestId =
    route.params.id.type == "test"
      ? { id: route.params.id.id, type: "test" }
      : { id: route.params.id.id, type: "lr" };
  const { test, isLoading, error } = useTestQuery(id);
  const actions: Actions = {
    onReferencePress: (link) => Linking.openURL(link!),
    onProbabilitySuggestionPress: (lrIndex) => {
      navigation.navigate("ProbabilityModal", {
        testDiseaseId: test!.diseaseId,
        lrId: test!.lrs[lrIndex].id,
      });
    },
  };

  return (
    <GenericScreen
      content={
        <LRCalculatorView
          test={test!}
          preTestProbability={route.params.preTestProbability ?? 50}
          actions={actions}
        />
      }
      error={error}
      isLoading={isLoading}
    />
  );
}
