import { View } from "react-native";
import theme from "../../../../utilities/theme";
import { Actions } from "../types";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import {
  Disease,
  Finding,
  Frequency,
} from "../../../../api/hooks/useDiseaseQuery/types";
import { Card } from "../../../../components/Card";
import { ExplanatoryRow } from "../../../../components/ExplanatoryRow";
import { GenericRow } from "../../../../components/GenericRow";
import { Badge } from "../../../../components/Badge";
import { useIsIdBlocked } from "../../../../api/hooks/useIsIdBlocked/useIsIdBlocked";
import { obscureString } from "../../../../utilities";
interface Props {
  disease: Disease;
  actions: Actions;
}

export function FindingsCard({ disease, actions }: Props) {
  const { t } = useTranslation();
  const isBlocked = useIsIdBlocked();
  const findings = sortFindings(
    disease.findings,
    hasFrequencyData(disease.findings) ? "frequency" : "abc"
  );
  return (
    <View
      style={{
        width: "100%",
        marginHorizontal: theme.spacing.defaultHorizontalGutter,
      }}
    >
      <Card>
        <ExplanatoryRow header={"Findings"} />
        <View style={{ marginTop: -theme.spacing.tight }} />
        {findings.map((finding, index) => {
          return (
            <GenericRow
              key={index}
              primaryTitle={
                !isBlocked(finding.id)
                  ? finding.name
                  : obscureString(finding.name)
              }
              secondaryTitle={
                !isBlocked(finding.id) ? undefined : "subscribe to view"
              }
              showLowerBorder={index !== disease.findings.length - 1}
              showCaret={finding.type === "disease"}
              onPress={
                finding.type === "disease"
                  ? () => actions.onFindingRowPress(finding.id)
                  : undefined
              }
              rightView={
                <View style={{ flexDirection: "row" }}>
                  {hasFrequencyData(disease.findings) ? (
                    <>
                      <View style={{ marginLeft: theme.spacing.tight }} />
                      <Badge
                        color={frequencyColor[finding.frequency]}
                        title={getDisplayFrequencyText(finding.frequency, t)}
                      />
                    </>
                  ) : null}
                </View>
              }
            />
          );
        })}
      </Card>
    </View>
  );
}

function getDisplayFrequencyText(frequency: Frequency, t: TFunction) {
  return t(`Database.${frequency}`);
}

type SortType = "abc" | "frequency";

const frequencySortOrder = [
  "very_common",
  "common",
  "uncommon",
  "rare",
  "somewhat_rare",
  "very_rare",
  "extremely_rare",
  undefined,
];

const frequencyColor: Record<Frequency, keyof typeof theme.colors> = {
  very_common: "buttonDefault",
  common: "buttonDefault",
  uncommon: "buttonSuccess",
  rare: "buttonWarning",
  somewhat_rare: "buttonWarning",
  very_rare: "buttonWarning",
  extremely_rare: "buttonWarning",
  unknown: "buttonSecondary",
};

function sortFindings(findings: Finding[], type: SortType) {
  switch (type) {
    case "abc":
      return findings.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    case "frequency":
      return findings.sort((a, b) => {
        if (a.frequency !== b.frequency) {
          return (
            frequencySortOrder.indexOf(a.frequency) -
            frequencySortOrder.indexOf(b.frequency)
          );
        } else {
          return a.name > b.name ? 1 : -1;
        }
      });
  }
}

function hasFrequencyData(findings: Finding[]) {
  return (
    findings.filter((finding) => finding.frequency !== "unknown").length > 0
  );
}
