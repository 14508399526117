import AsyncStorage from "@react-native-async-storage/async-storage";

export const setNormalToken = async (token: string | null) => {
  console.log(`set normal token ${token}`);
  if (token === null) {
    await AsyncStorage.removeItem("normal_token");
  } else {
    await AsyncStorage.setItem("normal_token", token);
  }
};
export const setRefreshToken = async (token: string | null) => {
  console.log(`set refresh token ${token}`);
  if (token === null) {
    await AsyncStorage.removeItem("refresh_token");
  } else {
    await AsyncStorage.setItem("refresh_token", token);
  }
};

export const getNormalToken = async () => {
  const token = await AsyncStorage.getItem("normal_token");
  console.log(`got normal token ${token}`);
  return token;
};

export const getRefreshToken = async () => {
  const token = await AsyncStorage.getItem("refresh_token");
  console.log(`got refresh token ${token}`);
  return token;
};
