import React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import CheckmarkIcon from "../resources/icons/checkmark-outline.svg";
import theme from "../utilities/theme";
import { GenericRow } from "./GenericRow";
import { PlatformSpecificIcon } from "./PlatformSpecificIcon";
import { FilterState } from "./types";

interface Props {
  filters: FilterState[];
  toggleFilter: (title: string) => void;
}

export function FilterList({ filters, toggleFilter }: Props) {
  return (
    <FlatList
      keyboardShouldPersistTaps={"always"}
      data={filters}
      renderItem={({ item }) => {
        return (
          <GenericRow
            primaryTitle={item.title}
            rightView={
              item.selected ? (
                <PlatformSpecificIcon
                  color={theme.colors.buttonDefault}
                  icon={CheckmarkIcon}
                  size={theme.dimensions.iconDefault}
                />
              ) : (
                <View style={{ height: theme.dimensions.iconDefault }} />
              )
            }
            onPress={() => toggleFilter(item.title)}
          />
        );
      }}
    />
  );
}
