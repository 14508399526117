import { Actions, BrowseTestSpecialtyScreenNavigationProp } from "./types";
import { GenericScreen } from "../../../components/GenericScreen";
import React from "react";
import { GenericList } from "../../../components/GenericList";
import { useNavigation } from "@react-navigation/native";
import { useBrowseTestSpecialtiesQuery } from "../../../api/hooks/useBrowseTestSpecialtiesQuery/useBrowseTestSpecialtiesQuery";
import { Platform } from "react-native";
import { useAnalytics } from "../../../api/hooks/useAnalytics/useAnalytics";

export function BrowseTestSpecialtyScreen() {
  const navigation = useNavigation<BrowseTestSpecialtyScreenNavigationProp>();
  const { isLoading, error, specialties } = useBrowseTestSpecialtiesQuery();
  const sendAnalyticEvent = useAnalytics();

  const actions: Actions = {
    onRowPress: (name) => {
      sendAnalyticEvent({
        name: "browse_test_specialty_pressed",
        data: JSON.stringify({ specialty: name }),
      });
      navigation.navigate("BrowseTestDiseases", {
        specialty: name,
      });
    },
  };

  return (
    <GenericScreen
      content={
        <GenericList
          names={specialties}
          onRowPress={actions.onRowPress}
          showCaret={Platform.OS === "ios"}
        />
      }
      error={error}
      isLoading={isLoading}
    />
  );
}
