import * as AppleAuthentication from "expo-apple-authentication";
import { useEffect, useState } from "react";
import { useQuery } from "../../../api/hooks/useQuery/useQuery";
import { LoginResponse } from "./types";

export function useAppleLogin({
  onCompletion,
  onError,
}: {
  onCompletion: (username: string, userId: string) => void;
  onError: (error: Error) => void;
}): {
  execute: () => void;
} {
  const [shouldExecute, setShouldExecute] = useState(false);
  const { execute: serverAppleLogin } = useQuery<LoginResponse>({
    server: "auth",
    path: `auth/apple-login`,
    type: "POST",
    onError: (error) => onError(error),
    onCompletion: (response) => {
      onCompletion(response.data.username, response.data.userId);
    },
  });
  useEffect(() => {
    async function appleAuth() {
      return await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
      });
    }
    if (shouldExecute) {
      appleAuth()
        .then((response) => {
          const json = JSON.stringify({
            token: response.identityToken,
            source: "ios",
          });

          serverAppleLogin(json);
        })
        .catch((err) => {
          if (err.code !== "ERR_CANCELED") {
            onError(err);
          }
        });
      setShouldExecute(false);
    }
  }, [shouldExecute]);

  return { execute: () => setShouldExecute(true) };
}
