import React, { Children, ReactNode } from "react";
import { View } from "react-native";
import Theme from "../utilities/theme";
import theme from "../utilities/theme";

interface Props {
  children: ReactNode;
  spacing: keyof typeof Theme["spacing"];
}

export function SpaceBetween({ children, spacing }: Props) {
  const arrayChildren = Children.toArray(children);
  return (
    <>
      {arrayChildren.map((child, index) => {
        return index === 0 ? (
          child
        ) : (
          <View style={{ marginLeft: theme.spacing[spacing] }}>{child}</View>
        );
      })}
    </>
  );
}
