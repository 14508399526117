import { FilterList } from "../../../components/FilterList";
import React from "react";
import { FilterState } from "../../../components/types";

interface Props {
  filters: FilterState[];
  toggleFilter: (title: string) => void;
}

export function DDxFilterView({ filters, toggleFilter }: Props) {
  return <FilterList filters={filters} toggleFilter={toggleFilter} />;
}
