import React, { ReactNode } from "react";
import { ScrollView, StyleSheet, View, ViewStyle } from "react-native";
import theme from "../utilities/theme";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

interface Props {
  notCentered?: boolean;
  noHorizontalPadding?: boolean;
  noKeyboardAwareScrollView?: boolean;
  children: ReactNode;
  keyboardShouldPersistTaps?: boolean | "always" | "never" | "handled";
  style?: ViewStyle;
}

export function GenericViewContainer({
  children,
  notCentered = false,
  noHorizontalPadding = false,
  noKeyboardAwareScrollView = false,
  keyboardShouldPersistTaps = "always",
  style,
}: Props) {
  const styles = getStyles(noHorizontalPadding, notCentered, style);
  if (noKeyboardAwareScrollView) {
    return (
      <ScrollView
        keyboardShouldPersistTaps={keyboardShouldPersistTaps}
        style={styles.scrollContainer}
        contentContainerStyle={style}
      >
        <View style={styles.viewContainer} children={children} />
      </ScrollView>
    );
  } else {
    return (
      <KeyboardAwareScrollView
        enableOnAndroid={true}
        keyboardOpeningTime={0}
        keyboardShouldPersistTaps={keyboardShouldPersistTaps}
        style={styles.scrollContainer}
        contentContainerStyle={style}
      >
        <View style={styles.viewContainer} children={children} />
      </KeyboardAwareScrollView>
    );
  }
}

function getStyles(
  noHorizontalPadding: boolean,
  notCentered: boolean,
  style?: ViewStyle
) {
  const horizontalPadding = noHorizontalPadding
    ? 0
    : theme.spacing.defaultHorizontalGutter;
  const alignItems = notCentered ? "flex-start" : "center";
  return StyleSheet.create({
    scrollContainer: {
      backgroundColor:
        style?.backgroundColor != null
          ? style.backgroundColor
          : theme.colors.surfaceDefault,
      flex: 1,
    },
    viewContainer: {
      paddingHorizontal: horizontalPadding,
      paddingVertical: theme.spacing.defaultVerticalGutter,
      alignItems: alignItems,
    },
  });
}
