import { GenericListViewContainer } from "./GenericListViewContainer";
import { GenericRow } from "./GenericRow";
import { ListRenderItemInfo } from "react-native";
import { obscureString } from "../utilities";
import { KeyboardAwareFlatList } from "react-native-keyboard-aware-scroll-view";

interface Props {
  names: string[];
  onRowPress: (name: string, index: number) => void;
  onBlockedRowPress?: () => void;
  blockedIndices?: number[];
  showCaret?: boolean;
}

export function GenericList({
  names,
  onRowPress,
  onBlockedRowPress,
  blockedIndices,
  showCaret = false,
}: Props) {
  return (
    <GenericListViewContainer>
      <KeyboardAwareFlatList
        enableOnAndroid={true}
        keyboardOpeningTime={0}
        keyboardShouldPersistTaps="always"
        data={names}
        renderItem={({ item, index }: ListRenderItemInfo<string>) => {
          return !blockedIndices?.includes(index) ? (
            <GenericRow
              primaryTitle={item}
              onPress={() => onRowPress(item, index)}
              showCaret={showCaret}
            />
          ) : (
            <GenericRow
              primaryTitle={obscureString(item)}
              secondaryTitle={"subscribe to view"}
              onPress={onBlockedRowPress}
              showCaret={showCaret}
            />
          );
        }}
      />
    </GenericListViewContainer>
  );
}
