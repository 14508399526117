import Purchases, { PurchasesError } from "react-native-purchases";
import { logError } from "../../../../utilities";
import { showErrorToast } from "../../../../utilities/toast";
import { useServerDeleteNormalToken } from "../useServerDeleteNormalToken/useServerDeleteNormalToken";

export function usePurchaseSubscription(
  subscriptionId: string | null,
  onCompletion: () => void
) {
  const { execute } = useServerDeleteNormalToken({
    onCompletion: () => {
      if (subscriptionId) {
        Purchases.purchaseProduct(subscriptionId)
          .then(() => {
            onCompletion();
          })
          .catch((error) => {
            if (isRevenueCatError(error)) {
              error.underlyingErrorMessage;
              if (!error.userCancelled) {
                logError(new Error(error.underlyingErrorMessage));
                showErrorToast();
              }
            } else {
              logError(error);
              showErrorToast();
            }
          });
      } else {
        logError(new Error("no subscription state"));
        showErrorToast();
      }
    },
    onError: (error) => {
      logError(error);
      showErrorToast("A connection error occurred.");
    },
  });

  return { execute };
}

function isRevenueCatError(
  error: Error | PurchasesError
): error is PurchasesError {
  return "userCancelled" in error;
}
