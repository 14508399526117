import React, { ReactNode } from "react";
import { StyleSheet, Text, View } from "react-native";
import theme from "../../../../utilities/theme";
import { useTranslation } from "react-i18next";

export function EmergentCard() {
  const { t } = useTranslation();
  return (
    <View style={styles.container}>
      <Text
        style={{
          alignSelf: "center",
          color: theme.colors.textOnFilledButton,
          fontSize: theme.fontSize.normal,
        }}
      >
        {t("Database.emergent")}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.buttonCritical,
    width: "100%",
    padding: theme.spacing.base,
    borderRadius: 10,
    borderWidth: 0,
  },
});
