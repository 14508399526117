export type DiseaseId = {
  id: number;
  type: "test" | "database";
};

export type APILR = {
  avg: number;
  min: number;
  max: number;
  id: number;
  title: string;
  disease: string;
  type: string;
};

export interface LR {
  id: number;
  title: string;
  disease: string;
  type: TestType;
  avg: number;
  min: number;
  max: number;
}

export enum TestType {
  history,
  physicalExam,
  EKG,
  lab,
  radiology,
  decisionRule,
}
