import { TFunction } from "react-i18next";
import { ResultType, SearchFilter } from "./types";

export function getSearchResultTypeStringFromEnum(
  type: ResultType,
  t: TFunction
) {
  switch (type) {
    case ResultType.disease:
      return t("Database.diseaseBadgeTitle");
    case ResultType.lrList:
      return t("Database.lrsBadgeTitle");
    case ResultType.lrOne:
      return t("Database.lrBadgeTitle");
    case ResultType.probabilities:
      return t("Database.probabilitiesBadgeTitle");
  }
}

export function getResultTypeFromFilter(filter: SearchFilter): ResultType[] {
  switch (filter) {
    case "tests":
      return [ResultType.lrList, ResultType.lrOne];
    case "probabilities":
      return [ResultType.probabilities];
    case "diseases":
      return [ResultType.disease];
  }
}

export function getFilterFromResultType(type: ResultType): SearchFilter {
  switch (type) {
    case ResultType.lrList:
    case ResultType.lrOne:
      return "tests";
    case ResultType.probabilities:
      return "probabilities";
    case ResultType.disease:
      return "diseases";
  }
}
