import React from "react";
import { FlatList, ListRenderItemInfo } from "react-native";
import { GenericListViewContainer } from "../../../components/GenericListViewContainer";
import { Finding, SearchFindingsActions } from "./types";
import { GenericRow } from "../../../components/GenericRow";

interface Props {
  findings: Finding[];
  actions: SearchFindingsActions;
  keyboardHeight: number;
  keyboardShown: boolean;
}

export function SearchFindingView({
  findings,
  actions,
  keyboardHeight,
  keyboardShown,
}: Props) {
  return (
    <GenericListViewContainer>
      <FlatList
        keyboardShouldPersistTaps={"always"}
        contentContainerStyle={{
          paddingBottom: keyboardShown ? keyboardHeight : 0,
        }}
        data={findings}
        keyExtractor={(item, index) => `${index}`}
        renderItem={({ item }: ListRenderItemInfo<Finding>) => (
          <GenericRow
            primaryTitle={item.name}
            onPress={() => {
              actions.onSearchRowPress({
                id: item.id,
                name: item.name,
              });
            }}
          />
        )}
      />
    </GenericListViewContainer>
  );
}
