import { View } from "react-native";
import theme from "../../../../utilities/theme";
import { useTranslation } from "react-i18next";
import { SpacerAndDivider } from "../../../../components/SpacerAndDivider";
import { ExplanatoryRow } from "../../../../components/ExplanatoryRow";
import {
  Acuity,
  Disease,
  Incidence,
} from "../../../../api/hooks/useDiseaseQuery/types";
import { Card } from "../../../../components/Card";

interface Props {
  disease: Disease;
}

export function InfoCard({ disease }: Props) {
  const { t } = useTranslation();

  const commentContent = disease.comment ? (
    <>
      <SpacerAndDivider />
      <ExplanatoryRow header={t("Database.comment")} title={disease.comment} />
    </>
  ) : null;

  const synonymContent =
    disease.synonyms.length > 0 ? (
      <>
        <SpacerAndDivider />
        <ExplanatoryRow
          header={t("Database.synonyms")}
          title={disease.synonyms.join(", ")}
        />
      </>
    ) : null;

  const incidenceString = (incidence: Incidence) => {
    if (typeof incidence === "number") {
      const sciRep = getScientificRepresentation(incidence);
      const magnitude = parseFloat(
        String(10 ** sciRep.magnitude)
      ).toLocaleString("en");
      return sciRep.number > 1
        ? `${sciRep.number} cases per ${magnitude} person-years`
        : `${sciRep.number} case per ${magnitude} person-years`;
    } else {
      switch (incidence) {
        case null:
          return t("Database.unknown");
        case "impossible":
          return t("Database.impossible");
        case "assumed_common":
          return t("Database.assumedCommon");
        case "assumed_rare":
          return t("Database.assumedRare");
      }
    }
  };

  const acuityString = (acuity: Acuity) => {
    switch (acuity) {
      case "acute":
        return t("Database.acute");
      case "chronic":
        return t("Database.chronic");
      case "any":
        return t("Database.anyAcuity");
    }
  };
  return (
    <View
      style={{
        width: "100%",
        marginHorizontal: theme.spacing.defaultHorizontalGutter,
      }}
    >
      <Card>
        <ExplanatoryRow header={t("Database.name")} title={disease.name} />
        {synonymContent}
        <SpacerAndDivider />
        <ExplanatoryRow
          header={t("Database.acuity")}
          title={acuityString(disease.acuity)}
        />
        <SpacerAndDivider />
        <ExplanatoryRow
          header={t("Database.incidence")}
          title={incidenceString(disease.incidence)}
        />
        {commentContent}
      </Card>
    </View>
  );
}

type ScientificRepresentation = {
  number: number;
  magnitude: number;
};

function getScientificRepresentation(number: number): ScientificRepresentation {
  const jsRepresentation = number.toExponential(0);
  const split = jsRepresentation.split("e");
  return { number: Number(split[0]), magnitude: -Number(split[1]) + 5 };
}
