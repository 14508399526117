import { useEffect, useState } from "react";
import { useQuery } from "../useQuery/useQuery";
import {
  APIDisease,
  APIFinding,
  APIFrequency,
  Disease,
  Finding,
  FindingType,
  Frequency,
  Incidence,
} from "./types";

export function useDiseaseQuery(id: number) {
  const [disease, setDisease] = useState<Disease | null>(null);
  const { execute, isLoading, error } = useQuery<APIDisease>({
    server: "database",
    path: `diseases/D${id.toString().padStart(4, "0")}`,
    type: "GET",
    onCompletion: (response) => {
      const json = response.data;
      const mappedResponse: Disease = {
        acuity: json.acuity,
        id: id,
        female_incidence: incidenceMapper(json.epidemiology.femaleIncidence),
        incidence: incidenceMapper(json.epidemiology.totalIncidence),
        male_incidence: incidenceMapper(json.epidemiology.maleIncidence),
        name: json.name,
        findings: json.findings.map((finding) => findingMapper(finding)),
        synonyms: json.synonyms,
        emergent: json.emergent,
        testDiseaseId: json.testDiseaseId,
        wiki: json.wiki ? `https://en.wikipedia.org/wiki/${json.wiki}` : null,
        sources: json.sources,
        comment: json.comment,
      };
      setDisease(mappedResponse);
    },
  });
  useEffect(() => {
    execute();
  }, []);
  return { disease, isLoading, error };
}

function findingMapper(finding: APIFinding): Finding {
  return {
    id: apiFindingIdToNumber(finding.id),
    category: finding.category,
    frequency: frequencyMapper(finding.frequency),
    name: finding.name,
    type: apiFindingIdToType(finding.id),
  };
}

function apiFindingIdToType(id: string): FindingType {
  if (id[0] === "S") {
    return "symptom";
  }
  if (id[0] === "D") {
    return "disease";
  }
  throw `invalid finding Id of ${id}`;
}

function apiFindingIdToNumber(id: string): number {
  return Number(id.slice(-4));
}

function frequencyMapper(frequency: APIFrequency): Frequency {
  switch (frequency) {
    case "common":
      return "common";
    case "veryCommon":
      return "very_common";
    case "uncommon":
      return "uncommon";
    case "rare":
      return "rare";
    case "somewhatRare":
      return "somewhat_rare";
    case "extremelyRare":
      return "extremely_rare";
    case "veryRare":
      return "very_rare";
    case "unknown":
      return "unknown";
  }
}

function incidenceMapper(incidence: string | null): Incidence {
  if (incidence === null) {
    return null;
  }
  const incidenceNumber = Number(incidence);
  if (incidenceNumber === -3) {
    return "impossible";
  }
  if (incidenceNumber === -2) {
    return "assumed_rare";
  }
  if (incidenceNumber === -1) {
    return "assumed_common";
  }
  return incidenceNumber;
}
