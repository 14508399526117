import { useQuery } from "../useQuery/useQuery";
import { useEffect, useState } from "react";
import { APISemantic } from "./types";
import { Finding } from "../../../sections/DDx/Home/types";

export function useFindingsQuery(query: string) {
  const [findings, setFindings] = useState<Finding[]>([]);
  const { execute, isLoading, error } = useQuery<APISemantic[]>({
    server: "database",
    path: `semantics?name(like)=${query}`,
    type: "GET",
    onCompletion: (response) => {
      const array = response.data;
      const mappedResponse: Finding[] = array.map((element) => {
        return {
          id: element.id,
          name: element.name,
        };
      });
      setFindings(mappedResponse);
    },
  });
  useEffect(() => {
    if (query.length > 0) execute();
  }, [query]);
  return { findings, isLoading, error };
}
