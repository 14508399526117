import React, { ReactNode } from "react";
import { StyleSheet, View } from "react-native";
import theme from "../utilities/theme";

interface Props {
  children: ReactNode;
}

export function GenericListViewContainer({ children }: Props) {
  return <View style={styles.container} children={children} />;
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.surfaceDefault,
    flex: 1,
  },
});
