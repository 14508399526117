import { useQuery } from "../useQuery/useQuery";
import { useEffect, useState } from "react";
import { Probability, ProbabilityId } from "./types";

export function useProbabilitiesQuery({ id, type }: ProbabilityId) {
  const [probabilities, setProbabilities] = useState<Probability[]>([]);
  const { execute, isLoading, error } = useQuery<Probability[]>({
    server: "database",
    path:
      type === "complaint"
        ? `probabilities/from-presenting-complaint-id/${id}`
        : `probabilities/from-test-id/${id}`,
    type: "GET",
    onCompletion: (response) => {
      setProbabilities(response.data);
    },
  });
  useEffect(() => {
    execute();
  }, []);
  return { probabilities, isLoading, error };
}
