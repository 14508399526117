import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import theme from "../../../utilities/theme";
import Checkmark from "../../../resources/icons/checkmark-outline.svg";
import { useTranslation } from "react-i18next";
import { ActiveSubscriptionType } from "../../../api/hooks/useUserInfo/types";
import { PlatformSpecificIcon } from "../../../components/PlatformSpecificIcon";

interface Props {
  type: ActiveSubscriptionType;
  cost: string;
  onPress: () => void;
  isSubscribed?: boolean;
}

export function SubscriptionButton({
  type,
  cost,
  isSubscribed = false,
  onPress,
}: Props) {
  const textStyle = {
    fontSize: theme.fontSize.normal,
    color: theme.colors.buttonDefault,
  };
  const title = type === "yearly" ? "Annual" : "Monthly";

  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={{
          backgroundColor: theme.colors.surfaceSecondary,
          borderColor: theme.colors.buttonDefault,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 10,
          paddingHorizontal: theme.spacing.base,
          borderWidth: 1,
          height: theme.dimensions.rowHeight,
        }}
      >
        <Text
          style={{
            fontSize: theme.fontSize.normal,
            color: theme.colors.buttonDefault,
            marginRight: theme.spacing.base,
          }}
        >
          {title}
        </Text>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text style={textStyle}>{cost}</Text>
          {isSubscribed ? (
            <>
              <View style={{ marginRight: theme.spacing.tight }} />
              <PlatformSpecificIcon
                icon={Checkmark}
                color={theme.colors.buttonDefault}
                size={theme.dimensions.iconDefault}
              />
            </>
          ) : null}
        </View>
      </View>
    </TouchableOpacity>
  );
}
