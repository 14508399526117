import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en.json";

const resources = {
  en: { translation: en },
};

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react
  },
});

export default i18n;

declare module "react-i18next" {
  interface CustomTypeOptions {
    resources: typeof resources["en"];
  }
}
