import { useEffect, useState } from "react";
import { useQuery } from "../useQuery/useQuery";
import { DiseaseReference } from "./types";

export function useBrowseDiseasesQuery(specialty: string, subcategory: string) {
  const [diseases, setDiseases] = useState<DiseaseReference[]>([]);
  const { execute, isLoading, error } = useQuery<DiseaseReference[]>({
    server: "database",
    path: `browse/diseases/${specialty}/${subcategory}`,
    type: "GET",
    onCompletion: (response) => {
      setDiseases(
        response.data.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        )
      );
    },
  });
  useEffect(() => {
    execute();
  }, []);
  return { diseases, isLoading, error };
}
