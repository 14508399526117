import { Actions, BrowseDiseaseSpecialtyScreenNavigationProp } from "./types";
import { useBrowseDiseaseSpecialtiesQuery } from "../../../api/hooks/useBrowseDiseaseSpecialtiesQuery/useBrowseDiseaseSpecialtiesQuery";
import { GenericScreen } from "../../../components/GenericScreen";
import React from "react";
import { GenericList } from "../../../components/GenericList";
import { useNavigation } from "@react-navigation/native";
import { Platform } from "react-native";
import { useAnalytics } from "../../../api/hooks/useAnalytics/useAnalytics";

export function BrowseDiseaseSpecialtyScreen() {
  const navigation =
    useNavigation<BrowseDiseaseSpecialtyScreenNavigationProp>();
  const { isLoading, error, specialties } = useBrowseDiseaseSpecialtiesQuery();
  const sendAnalyticEvent = useAnalytics();

  const actions: Actions = {
    onRowPress: (name) => {
      sendAnalyticEvent({
        name: "browse_disease_speciality_pressed",
        data: JSON.stringify({
          specialty: name,
        }),
      });
      navigation.navigate("BrowseDiseaseSubcategory", {
        specialty: name,
      });
    },
  };

  return (
    <GenericScreen
      content={
        <GenericList
          showCaret={Platform.OS === "ios"}
          names={specialties}
          onRowPress={actions.onRowPress}
        />
      }
      error={error}
      isLoading={isLoading}
    />
  );
}
