import MoveToTopAndExpandAnimation from "../../../animations/MoveToTopAndExpand";
import React, { useState } from "react";
import SearchIcon from "../../../resources/icons/search-outline.svg";
import ListIcon from "../../../resources/icons/list-outline.svg";
import { useTranslation } from "react-i18next";
import { useWindowDimensions } from "react-native";
import theme from "../../../utilities/theme";
import { Actions } from "./types";
import { GenericViewContainer } from "../../../components/GenericViewContainer";
import { Spacer } from "../../../components/Spacer";
import { GenericButton } from "../../../components/GenericButton";

interface Props {
  actions: Actions;
}

export function HomeView({ actions }: Props) {
  const [dummySearchBoxClicked, setDummySearchBoxClicked] = useState(false);
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  return (
    <GenericViewContainer
      noHorizontalPadding={true}
      style={{
        height: "100%",
        justifyContent: "center",
      }}
    >
      <MoveToTopAndExpandAnimation
        onCompletion={() => {
          setDummySearchBoxClicked(false);
          actions.onSearchBarAnimationEnd();
        }}
        initiateAnimation={dummySearchBoxClicked}
        initialWidth={width - 2 * theme.spacing.loose}
        finalWidth={width}
        verticalTranslation={height / 2}
      >
        <GenericButton
          type="outlined"
          width={width - 2 * theme.spacing.loose}
          showBorder={false}
          paddingVertical={"base"}
          icon={SearchIcon}
          color={"buttonSecondary"}
          title={t("Database.searchPlaceholder")}
          onPress={() => {
            setDummySearchBoxClicked(true);
          }}
        />
      </MoveToTopAndExpandAnimation>
      <Spacer spacing="extraLoose" />
      <GenericButton
        type="outlined"
        showBorder={false}
        paddingVertical={"base"}
        title={t("Database.browseButtonTitle")}
        color={"buttonSecondary"}
        icon={ListIcon}
        width={width - 2 * theme.spacing.loose}
        onPress={() => {
          actions.onBrowseButtonPress();
        }}
      />
    </GenericViewContainer>
  );
}
