import React, { useContext, useState } from "react";
import { LoginView } from "./LoginView";
import * as WebBrowser from "expo-web-browser";
import { useGoogleLogin } from "./hooks/useGoogleLogin";
import { useAppleLogin } from "./hooks/useAppleLogin";
import { SessionContext } from "../../context/Session/Session";
import { PasswordLoginInfo, Source } from "./hooks/types";
import { Platform, PlatformOSType } from "react-native";
import { showErrorToast, showInfoToast } from "../../utilities/toast";
import { logError } from "../../utilities";
import { useServerPasswordLogin } from "./hooks/useServerPasswordLogin";
import { useServerForgotPassword } from "./hooks/useServerForgotPassword";
import { showSuccessToast } from "../../utilities/toast/toasts";
import { useUserInfo } from "../../api/hooks/useUserInfo/useUserInfo";

WebBrowser.maybeCompleteAuthSession();

export function LoginScreen() {
  const { setAccess, setUsername, setUserId, setIsLoggedIn } =
    useContext(SessionContext);
  const { execute: getAndSetUserAccess } = useUserInfo(
    (userInfo) => {
      console.log(
        `${userInfo.username} logged in and got access ${userInfo.subscription.databaseAccessLevel}`
      );
      setAccess(userInfo.subscription.databaseAccessLevel);
    },
    (error) => {
      logError(error);
      showErrorToast("There was an error logging in.");
    }
  );
  const [passwordLoginInfo, setPasswordLoginInfo] = useState<PasswordLoginInfo>(
    { password: null, email: null }
  );
  const onSuccessfulLogin = (username, userId) => {
    setUsername(username);
    setUserId(userId);
    getAndSetUserAccess();
    setIsLoggedIn(true);
  };

  const { execute: googleLogin } = useGoogleLogin({
    onCompletion: (userName, userId) => onSuccessfulLogin(userName, userId),
    onError: (error) => {
      logError(error);
      showErrorToast("There was an error logging in.");
    },
  });
  const { execute: appleLogin } = useAppleLogin({
    onCompletion: (username, userId) => onSuccessfulLogin(username, userId),
    onError: (error) => {
      logError(error);
      showErrorToast("There was an error logging in.");
    },
  });
  const { execute: passwordLogin } = useServerPasswordLogin({
    passwordLoginInfo: passwordLoginInfo,
    source: getSourceFromPlatformType(Platform.OS),
    onCompletion: (response, username, userId) => {
      switch (response) {
        case "EmailIsValidated":
          onSuccessfulLogin(username, userId);
          break;
        case "EmailNeedsValidation":
          showInfoToast(
            "Check Email",
            false,
            "You must validate your email address to continue."
          );
          break;
        case "BadPassword":
          showErrorToast("Incorrect password");
          break;
      }
    },
    onError: (error) => {
      logError(error);
      showErrorToast("There was an error logging in.");
    },
  });
  const { execute: forgotPassword } = useServerForgotPassword({
    email: passwordLoginInfo.email!,
    onCompletion: (response) => {
      switch (response) {
        case "EmailSent":
          showSuccessToast("Check Your Email");
          break;
        case "NoUser":
          showInfoToast("Incorrect Login Information");
          break;
      }
    },
    onError: (error) => {
      logError(error);
      showErrorToast("There was an error.");
    },
  });

  const validateLogin = () => {
    if (
      passwordLoginInfo.email === null ||
      passwordLoginInfo.email === "" ||
      passwordLoginInfo.password === null ||
      passwordLoginInfo.password === ""
    ) {
      return false;
    } else {
      return true;
    }
  };
  const validateForgotPassword = () => {
    if (passwordLoginInfo.email === "" || passwordLoginInfo.email === null) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <LoginView
      googleLoginClicked={() => googleLogin()}
      appleLoginClicked={() => appleLogin()}
      passwordLoginClicked={() => {
        if (validateLogin()) {
          passwordLogin();
        } else {
          showErrorToast("input email and password");
        }
      }}
      setEmail={(email) =>
        setPasswordLoginInfo({
          email: email,
          password: passwordLoginInfo.password,
        })
      }
      setPassword={(password) =>
        setPasswordLoginInfo({
          email: passwordLoginInfo.email,
          password: password,
        })
      }
      forgotPasswordClicked={() => {
        if (validateForgotPassword()) {
          forgotPassword();
        } else {
          showErrorToast("please enter an email address");
        }
      }}
    />
  );
}

function getSourceFromPlatformType(platform: PlatformOSType): Source {
  switch (platform) {
    case "android":
      return "android";
    case "ios":
      return "ios";
    case "web":
      return "web";
  }
  throw new Error("unsupported platform type");
}
