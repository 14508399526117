import { useEffect, useState } from "react";
import { useQuery } from "../useQuery/useQuery";

export function useBrowseDiseaseSubcategoryQuery(specialty: string) {
  const [subcategories, setSpecialties] = useState<string[]>([]);
  const { execute, isLoading, error } = useQuery<string[]>({
    server: "database",
    path: `browse/diseases/${specialty}`,
    type: "GET",
    onCompletion: (response) => {
      setSpecialties(response.data);
    },
  });
  useEffect(() => {
    execute();
  }, []);
  return { subcategories, isLoading, error };
}
