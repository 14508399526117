import React, { useEffect, useState } from "react";
import { Actions, SearchResult } from "./types";
import SearchResultRow from "./components/SearchResultRow";
import { FlatList, Keyboard, ListRenderItemInfo } from "react-native";
import { GenericListViewContainer } from "../../../components/GenericListViewContainer";
import { useIsIdBlocked } from "../../../api/hooks/useIsIdBlocked/useIsIdBlocked";
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";

interface Props {
  data: SearchResult[];
  actions: Actions;
  keyboardShown: boolean;
  keyboardHeight: number;
}

export function SearchView({
  data,
  actions,
  keyboardShown,
  keyboardHeight,
}: Props) {
  const isIdBlocked = useIsIdBlocked();
  const tabBarHeight = useBottomTabBarHeight();

  return (
    <GenericListViewContainer>
      <FlatList
        keyboardShouldPersistTaps={"always"}
        contentContainerStyle={{
          paddingBottom: keyboardShown ? keyboardHeight - tabBarHeight : 0,
        }}
        data={data}
        keyExtractor={(item, index) => `${index}`}
        renderItem={({ item }: ListRenderItemInfo<SearchResult>) => (
          <SearchResultRow
            item={item}
            onPress={() => {
              actions.onSearchRowPress({
                id: item.id,
                name: item.title,
                type: item.type,
              });
            }}
            isBlocked={isIdBlocked(item.id)}
            onBlockedPress={actions.onBlockedSearchRowPress}
          />
        )}
      />
    </GenericListViewContainer>
  );
}
