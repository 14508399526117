import Bugsnag from "@bugsnag/expo";

export async function logError(error: Error) {
  Bugsnag.notify(error.message);
  console.error(error.message);
}

export function obscureString(string: string) {
  return string.replace(/\S/g, "?");
}
