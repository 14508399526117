import React, { useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import theme from "../utilities/theme";

interface Props {
  titles: string[];
  onPress: (index: number) => void;
  activeIndex: number;
}

export function GenericRadioButtons({ titles, onPress, activeIndex }: Props) {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        borderRadius: 10,
        overflow: "hidden",
        borderColor: theme.colors.radioButtonBorder,
        borderWidth: 0.5,
        alignItems: "center",
      }}
    >
      {titles.map((title, index) => (
        <View key={index} style={{ flexDirection: "row", flex: 1 }}>
          <TouchableOpacity
            style={getButtonStyle(index, activeIndex)}
            key={index}
            onPress={() => {
              onPress(index);
            }}
          >
            <Text style={getButtonTextStyle(index, activeIndex)}>{title}</Text>
          </TouchableOpacity>
          {index !== titles.length - 1 && (
            <View
              style={{
                width: 1,
                backgroundColor: theme.colors.radioButtonBorder,
              }}
            />
          )}
        </View>
      ))}
    </View>
  );
}

function getButtonTextStyle(index: number, activeIndex: number) {
  let color =
    activeIndex === index
      ? theme.colors.textOnActiveRadioButton
      : theme.colors.textOnInactiveRadioButton;
  return StyleSheet.create({
    buttonText: {
      flex: 1,
      color: color,
    },
  }).buttonText;
}

function getButtonStyle(index: number, activeIndex: number) {
  let backgroundColor =
    activeIndex === index
      ? theme.colors.activeRadioButton
      : theme.colors.inactiveRadioButton;
  return StyleSheet.create({
    button: {
      flex: 1,
      paddingHorizontal: 12,
      paddingVertical: 8,
      alignItems: "center",
      backgroundColor: backgroundColor,
    },
  }).button;
}
