import React from "react";
import { StyleSheet, Text, View } from "react-native";
import theme from "../utilities/theme";

interface Props {
  title: string;
  color?: keyof typeof theme.colors;
}

export function Badge({ title, color }: Props) {
  return (
    <View
      style={{
        ...styles.badgeContainer,
        borderColor: color
          ? theme.colors[color]
          : theme.colors["textSecondary"],
      }}
    >
      <Text
        style={{
          ...styles.badgeText,
          color: color ? theme.colors[color] : theme.colors["textSecondary"],
        }}
      >
        {title}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  badgeContainer: {
    flexShrink: 0,
    borderWidth: 1,
    borderRadius: 7,
    backgroundColor: theme.colors.surfaceSecondary,
  },
  badgeText: {
    fontSize: 8,
    paddingStart: 4,
    paddingEnd: 4,
    paddingTop: 2,
    paddingBottom: 2,
  },
});
