import { GenericViewContainer } from "../../components/GenericViewContainer";
import { Spacer } from "../../components/Spacer";
import { Text } from "react-native";
import { GenericButton } from "../../components/GenericButton";
import theme from "../../utilities/theme";
import { Card } from "../../components/Card";

interface Props {
  onExternalTermsAndConditionsPress: () => void;
  onAcceptPress: () => void;
  onDeclinePress: () => void;
}

export function TermsAndConditionsView({
  onExternalTermsAndConditionsPress,
  onAcceptPress,
  onDeclinePress,
}: Props) {
  return (
    <GenericViewContainer>
      <Spacer spacing={"loose"} />
      <Card>
        <Text
          style={{
            color: theme.colors.textPrimary,
            fontSize: 22,
            fontWeight: "bold",
          }}
        >
          Terms and Conditions
        </Text>
        <Spacer spacing={"base"} />
        <Text
          style={{
            fontSize: theme.fontSize.normal,
            color: theme.colors.textPrimary,
          }}
        >
          While attempts were made to ensure the accurancy of information found
          herein, no guarantee is made to its valididy, and errors will
          inevitably exist.
        </Text>
        <Spacer spacing={"base"} />
        <GenericButton
          color={"buttonSecondary"}
          width={"100%"}
          type={"outlined"}
          title={"Read full Terms and Conditions"}
          onPress={onExternalTermsAndConditionsPress}
        />
      </Card>
      <Spacer spacing={"extraLoose"} />
      <GenericButton
        width={"100%"}
        type={"filled"}
        color={"buttonDefault"}
        title={
          "I acknoweledge the potential inaccuracy of this data and accept the terms and conditions."
        }
        onPress={onAcceptPress}
      />
      <Spacer spacing={"loose"} />
      <GenericButton
        width={"100%"}
        type={"filled"}
        color={"buttonCritical"}
        title={"Decline"}
        onPress={onDeclinePress}
      />
    </GenericViewContainer>
  );
}
