import React, { ReactNode } from "react";
import { StyleSheet, View } from "react-native";
import theme from "../utilities/theme";

interface Props {
  backgroundColor?: string;
  children: ReactNode;
}

export function Card({ children, backgroundColor }: Props) {
  return (
    <View
      style={{
        backgroundColor: backgroundColor
          ? backgroundColor
          : theme.colors.surfaceSecondary,
        width: "100%",
        padding: theme.spacing.loose,
        borderRadius: 10,
        borderWidth: 0,
        borderColor: "transparent",
      }}
      children={children}
    />
  );
}
