import React from "react";
import { FlatList, Text } from "react-native";
import { Probability } from "../../../api/hooks/useProbabilitiesQuery/types";
import { GenericRow } from "../../../components/GenericRow";
import { Actions, ProbabilityViewType } from "./types";
import { GenericListViewContainer } from "../../../components/GenericListViewContainer";

export interface Props {
  probabilities: Probability[];
  type: ProbabilityViewType;
  actions: Actions;
}

export function ProbabilityView({ probabilities, type, actions }: Props) {
  const sortedProbabilities = sortProbabilities(probabilities, type);
  return (
    <GenericListViewContainer>
      <FlatList
        data={sortedProbabilities}
        renderItem={({ item }) => {
          const primaryTitle =
            type === "PresentingComplaint"
              ? item.disease
              : item.presentingComplaint.name;
          return (
            <GenericRow
              onPress={
                actions.onProbabilityRowPress !== undefined
                  ? () => actions.onProbabilityRowPress!(item.probability, type)
                  : undefined
              }
              primaryTitle={primaryTitle}
              secondaryTitle={item.population}
              rightView={<Text>{`${item.probability}%`}</Text>}
            />
          );
        }}
      />
    </GenericListViewContainer>
  );
}

function sortProbabilities(
  probabilities: Probability[],
  type: ProbabilityViewType
) {
  const sortedProbabilities = probabilities.sort((a, b) => {
    if (type === "PresentingComplaint") {
      if (b.population < a.population) {
        return 1;
      }
      if (b.population > a.population) {
        return -1;
      }
      if (b.probability > a.probability) {
        return 1;
      }
      if (b.probability < a.probability) {
        return -1;
      }
      if (b.disease < a.disease) {
        return 1;
      }
      if (b.disease > a.disease) {
        return -1;
      }
    } else {
      if (b.presentingComplaint < a.presentingComplaint) {
        return 1;
      }
      if (b.presentingComplaint > a.presentingComplaint) {
        return -1;
      }
      if (b.probability > a.probability) {
        return 1;
      }
      if (b.probability < a.probability) {
        return -1;
      }
      if (b.population < a.population) {
        return 1;
      }
      if (b.population > a.population) {
        return -1;
      }
    }
    return 0;
  });
  return sortedProbabilities;
}
