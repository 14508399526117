import React, { useLayoutEffect, useState } from "react";
import axios from "axios";
import { ErrorView } from "../../../components/ErrorView";
import { LoadingView } from "../../../components/LoadingView";
import { useNavigation } from "@react-navigation/native";
import { SearchBox } from "../../../components/SearchBox";
import { NoInternetView } from "../../../components/NoInternetView";
import {
  Finding,
  SearchFindingsActions,
  SearchFindingsScreenNavigationProp,
} from "./types";
import { useFindingsQuery } from "../../../api/hooks/useFindingsQuery/useFindingsQuery";
import { EmptySearchView } from "../../Database/Search/components/EmptySearchView";
import useDebounce from "../../Database/Search/hooks/useDebounce/useDebounce";
import { SearchFindingView } from "./SearchFindingView";
import { NoResultsView } from "../../Database/Search/components/NoResultsView";
import { useKeyboard } from "../../../utilities/hooks/useKeyboard/useKeyboard";
import { HeaderButton } from "../../../components/HeaderButton";

export function SearchFindingsScreen() {
  const navigation = useNavigation<SearchFindingsScreenNavigationProp>();
  const [_searchFieldText, setSearchFieldText] = useState("");
  const searchFieldText = useDebounce(_searchFieldText, 300);
  const { findings, isLoading, error } = useFindingsQuery(searchFieldText);
  const { keyboardHeight, keyboardShown } = useKeyboard();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleAlign: "center",
      headerTitle: () => {
        return (
          <SearchBox
            placeholder="Search Findings"
            onExternalSearchFieldTextChange={setSearchFieldText}
          />
        );
      },
      headerRight: () => <HeaderButton content={"Close"} />,
    });
  });

  const actions: SearchFindingsActions = {
    onSearchRowPress: (finding: Finding) => {
      navigation.navigate("Home", { findingToAdd: finding });
    },
  };

  if (error) {
    return axios.isAxiosError(error) ? <NoInternetView /> : <ErrorView />;
  }

  if (isLoading) {
    return findings.length === 0 && searchFieldText !== "" ? (
      <LoadingView />
    ) : (
      <SearchFindingView
        findings={findings}
        actions={actions}
        keyboardHeight={keyboardHeight}
        keyboardShown={keyboardShown}
      />
    );
  }

  if (searchFieldText == "") {
    return <EmptySearchView />;
  }

  return findings.length > 0 ? (
    <SearchFindingView
      keyboardHeight={keyboardHeight}
      keyboardShown={keyboardShown}
      findings={findings}
      actions={actions}
    />
  ) : (
    <NoResultsView />
  );
}
