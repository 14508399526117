import { AxiosResponse } from "axios";
import { useQuery } from "../../../../api/hooks/useQuery/useQuery";

export function useServerLogout(
  onCompletion: (response: AxiosResponse) => void,
  onError: (error: Error) => void
) {
  const { execute } = useQuery({
    server: "auth",
    path: `auth/logout`,
    type: "POST",
    onCompletion: (response) => onCompletion(response),
    onError: (error) => onError(error),
  });
  return { execute };
}
