import React, { ReactElement } from "react";
import { Platform, StyleSheet, View } from "react-native";
import theme from "../utilities/theme";
import { GenericTextButton } from "./GenericTextButton";

interface Props {
  size: number;
  index: number;
  prevPress: () => void;
  nextPress: () => void;
}

export function Paginator({ size, index, prevPress, nextPress }: Props) {
  let dots: ReactElement[] = [];
  for (let i = 0; i < size; i++) {
    dots.push(
      <View
        style={index === i ? styles.activeDot : styles.inactiveDot}
        key={i}
      />
    );
  }

  const prevButton = (
    <GenericTextButton title={"previous"} onPress={prevPress} />
  );
  const nextButton = <GenericTextButton title={"next"} onPress={nextPress} />;

  const controller =
    Platform.OS === "web" ? (
      <>
        {prevButton}
        <View style={{ marginRight: theme.spacing.tight }} />
        {dots}
        <View style={{ marginRight: theme.spacing.tight }} />
        {nextButton}
      </>
    ) : (
      dots
    );

  return <View style={styles.container}>{controller}</View>;
}
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    height: 10,
  },
  activeDot: {
    backgroundColor: theme.colors.paginatorActive,
    borderRadius: 100,
    height: 10,
    width: 10,
    margin: 3,
  },
  inactiveDot: {
    backgroundColor: theme.colors.paginatorInactive,
    borderRadius: 100,
    height: 8,
    width: 8,
    margin: 3,
  },
});
