import {
  Actions,
  BrowseDiseaseSubcategoryScreenNavigationProp,
  BrowseDiseaseSubcategoryScreenRouteProp,
} from "./types";
import { GenericScreen } from "../../../components/GenericScreen";
import React from "react";
import { GenericList } from "../../../components/GenericList";
import { useBrowseDiseaseSubcategoryQuery } from "../../../api/hooks/useBrowseDiseaseSubcategoryQuery/useBrowseDiseaseSubcategoryQuery";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Platform } from "react-native";
import { useAnalytics } from "../../../api/hooks/useAnalytics/useAnalytics";

export function BrowseDiseaseSubcategoryScreen() {
  const navigation =
    useNavigation<BrowseDiseaseSubcategoryScreenNavigationProp>();
  const route = useRoute<BrowseDiseaseSubcategoryScreenRouteProp>();
  const { isLoading, error, subcategories } = useBrowseDiseaseSubcategoryQuery(
    route.params.specialty
  );
  const sendAnalyticEvent = useAnalytics();

  const actions: Actions = {
    onRowPress: (name) => {
      sendAnalyticEvent({
        name: "browse_disease_subcategory_pressed",
        data: JSON.stringify({
          specialty: route.params.specialty,
          subcategory: name,
        }),
      });
      navigation.navigate("BrowseDiseases", {
        specialty: route.params.specialty,
        subcategory: name,
      });
    },
  };
  return (
    <GenericScreen
      content={
        <GenericList
          showCaret={Platform.OS === "ios"}
          names={subcategories}
          onRowPress={actions.onRowPress}
        />
      }
      error={error}
      isLoading={isLoading}
    />
  );
}
