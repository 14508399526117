import React from "react";
import { ReferenceView } from "./ReferenceView";
import { useRoute } from "@react-navigation/native";
import { ReferenceScreenRouteProp } from "./types";
import _ from "lodash";

export function ReferenceScreen() {
  const route = useRoute<ReferenceScreenRouteProp>();
  const sortedAndDistinctReferences = _.uniqBy(
    route.params.references.sort(),
    "string"
  );
  return <ReferenceView references={sortedAndDistinctReferences} />;
}
