import { Actions } from "./types";
import { InfoCard } from "./components/InfoCard";
import React from "react";
import { AdditionalInfoLinksCard } from "./components/AdditionalInfoLinksCard";
import { FindingsCard } from "./components/FindingsCard";
import { EmergentCard } from "./components/EmergentCard";
import { Disease } from "../../../api/hooks/useDiseaseQuery/types";
import { Spacer } from "../../../components/Spacer";
import { GenericViewContainer } from "../../../components/GenericViewContainer";
import { GenericScreen } from "../../../components/GenericScreen";
import { Text, View } from "react-native";
import theme from "../../../utilities/theme";

interface Props {
  disease: Disease | null;
  actions: Actions;
}

export function DiseaseView({ disease, actions }: Props) {
  if (!disease) {
    return (
      <GenericScreen
        content={
          <View style={{ margin: theme.spacing.base }}>
            <Text>
              No content. This disease is a stub and will be updated at a later
              time.
            </Text>
          </View>
        }
        isLoading={false}
        error={null}
      />
    );
  }

  const emergentContent = disease.emergent ? (
    <>
      <EmergentCard />
      <Spacer spacing={"loose"} />
    </>
  ) : null;

  return (
    <GenericViewContainer>
      {emergentContent}
      <InfoCard disease={disease} />
      <Spacer spacing={"loose"} />
      {disease.testDiseaseId.length > 0 ||
      disease.wiki ||
      disease.sources.length > 0 ? (
        <>
          <AdditionalInfoLinksCard disease={disease} actions={actions} />
          <Spacer spacing={"loose"} />
        </>
      ) : null}
      {disease.findings.length > 0 ? (
        <FindingsCard disease={disease} actions={actions} />
      ) : null}
    </GenericViewContainer>
  );
}
