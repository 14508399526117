import React, { useLayoutEffect } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import { ProbabilityView } from "./ProbabilityView";
import { useProbabilitiesQuery } from "../../../api/hooks/useProbabilitiesQuery/useProbabilitiesQuery";
import ReferenceIcon from "../../../resources/icons/book-outline.svg";
import _ from "lodash";
import { IconButton, IconButtonType } from "../../../components/IconButton";
import { Probability } from "../../../api/hooks/useProbabilitiesQuery/types";
import {
  Actions,
  ProbabilityScreenNavigationProp,
  ProbabilityScreenRouteProp,
} from "./types";
import { GenericScreen } from "../../../components/GenericScreen";
import { HeaderButton } from "../../../components/HeaderButton";

export function ProbabilityScreen() {
  const route = useRoute<ProbabilityScreenRouteProp>();
  const navigation = useNavigation<ProbabilityScreenNavigationProp>();
  const { probabilities, isLoading, error } = useProbabilitiesQuery({
    id: route.params.id,
    type: "complaint",
  });
  const references = probabilities ? getUniqueReferences(probabilities) : [];
  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButton
          onPress={() =>
            navigation.navigate("References", {
              references: references,
            })
          }
          content={ReferenceIcon}
        />
      ),
    });
  });

  const actions: Actions = {
    onProbabilityRowPress: undefined,
  };

  return (
    <GenericScreen
      content={
        <ProbabilityView
          actions={actions}
          probabilities={probabilities}
          type={"PresentingComplaint"}
        />
      }
      error={error}
      isLoading={isLoading}
    />
  );
}

function getUniqueReferences(probabilities: Probability[]) {
  const references = probabilities.map((probability) => probability.source);
  return _.uniqBy(references, function (e) {
    return e.string;
  });
}
