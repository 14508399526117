import {
  Actions,
  BrowseDiseasesScreenNavigationProp,
  BrowseDiseasesScreenRouteProp,
} from "./types";
import { GenericScreen } from "../../../components/GenericScreen";
import React from "react";
import { GenericList } from "../../../components/GenericList";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useBrowseDiseasesQuery } from "../../../api/hooks/useBrowseDiseasesQuery/useBrowseDiseasesQuery";
import _ from "lodash";
import { useIsIdBlocked } from "../../../api/hooks/useIsIdBlocked/useIsIdBlocked";
import { Platform } from "react-native";
import { useAnalytics } from "../../../api/hooks/useAnalytics/useAnalytics";

export function BrowseDiseasesScreen() {
  const navigation = useNavigation<BrowseDiseasesScreenNavigationProp>();
  const route = useRoute<BrowseDiseasesScreenRouteProp>();
  const { isLoading, error, diseases } = useBrowseDiseasesQuery(
    route.params.specialty,
    route.params.subcategory
  );
  const isIdBlocked = useIsIdBlocked();
  const sendAnalyticEvent = useAnalytics();

  const actions: Actions = {
    onRowPress: (name, index) => {
      sendAnalyticEvent({
        name: "browse_disease_pressed",
        data: JSON.stringify({
          specialty: route.params.specialty,
          subcategory: route.params.subcategory,
          disease: name,
        }),
      });
      navigation.navigate("Disease", {
        diseaseId: diseases[index].id,
      });
    },
  };

  const names: string[] = diseases
    ? diseases.map((disease) => disease.name)
    : [];

  return (
    <GenericScreen
      content={
        <GenericList
          names={names}
          onRowPress={actions.onRowPress}
          blockedIndices={_.compact(
            diseases?.map((disease, index) => {
              if (isIdBlocked(disease.id)) {
                return index;
              } else {
                return null;
              }
            })
          )}
          onBlockedRowPress={() => navigation.navigate("AccountModal")}
          showCaret={Platform.OS === "ios"}
        />
      }
      error={error}
      isLoading={isLoading}
    />
  );
}
