import React from "react";
import { FlatList, Linking } from "react-native";
import LinkIcon from "../../resources/icons/link-outline.svg";
import theme from "../../utilities/theme";
import { Reference } from "../../api/types";
import { GenericRow } from "../../components/GenericRow";
import { GenericListViewContainer } from "../../components/GenericListViewContainer";
import { PlatformSpecificIcon } from "../../components/PlatformSpecificIcon";

export interface Props {
  references: Reference[];
}

export function ReferenceView({ references }: Props) {
  return (
    <GenericListViewContainer>
      <FlatList
        data={references}
        renderItem={({ item }) => (
          <ReferenceRow string={item.string} link={item.link} />
        )}
      />
    </GenericListViewContainer>
  );
}

export function ReferenceRow({
  string,
  link,
}: {
  string: string;
  link: string | null;
}) {
  return (
    <GenericRow
      primaryTitle={string}
      onPress={link ? () => Linking.openURL(link) : undefined}
      rightView={
        link ? (
          <PlatformSpecificIcon
            icon={LinkIcon}
            size={theme.dimensions.iconDefault}
            color={theme.colors.buttonDefault}
          />
        ) : null
      }
    />
  );
}
