import { TestType } from "../../../../api/hooks/useLRsQuery/types";

export function getTestTypeStringFromEnum(type: TestType) {
  switch (type) {
    case TestType.EKG:
      return "EKG";
    case TestType.history:
      return "history";
    case TestType.lab:
      return "lab";
    case TestType.physicalExam:
      return "physical exam";
    case TestType.radiology:
      return "radiology";
    case TestType.decisionRule:
      return "decision rule";
  }
}
