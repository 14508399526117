import React, { ReactNode } from "react";
import { StyleSheet, Text, View } from "react-native";
import theme from "../utilities/theme";

interface Props {
  header: string;
  title?: string;
  children?: ReactNode;
}

export function ExplanatoryRow({ header, title, children }: Props) {
  return (
    <View style={{ width: "100%" }}>
      <Text style={styles.rowHeader}>{header}</Text>
      {children ? (
        <View style={styles.rowTitle}>{children}</View>
      ) : (
        <Text style={styles.rowTitle}>{title}</Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  rowHeader: {
    color: theme.colors.textSecondary,
    fontSize: theme.fontSize.small,
  },
  rowTitle: {
    color: theme.colors.textPrimary,
    fontSize: theme.fontSize.normal,
    paddingTop: theme.spacing.tight,
    paddingHorizontal: theme.spacing.subtitleIndent,
  },
});
