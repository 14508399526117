import React from "react";
import { Image, Platform, StyleSheet, Text, View } from "react-native";
import theme from "../../utilities/theme";
import { useTranslation } from "react-i18next";
import GoogleIcon from "../../resources/icons/logo-google.svg";
import AppleIcon from "../../resources/icons/logo-apple.svg";
import {
  GenericButton,
  GenericButtonType,
} from "../../components/GenericButton";
import { GenericViewContainer } from "../../components/GenericViewContainer";
import { GenericTextInput } from "../../components/GenericTextInput";
import { Spacer } from "../../components/Spacer";

interface Props {
  googleLoginClicked: () => void;
  appleLoginClicked: () => void;
  passwordLoginClicked: () => void;
  forgotPasswordClicked: () => void;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
}

const BUTTON_WIDTH = "66%";

export function LoginView({
  appleLoginClicked,
  googleLoginClicked,
  passwordLoginClicked,
  forgotPasswordClicked,
  setEmail,
  setPassword,
}: Props) {
  const { t } = useTranslation();
  const appleLogin =
    Platform.OS === "ios" ? (
      <>
        <Spacer spacing={"base"} />
        <GenericButton
          type={"outlined"}
          title={"Login with Apple   "}
          color={"buttonSecondary"}
          width={BUTTON_WIDTH}
          icon={AppleIcon}
          onPress={appleLoginClicked}
        />
      </>
    ) : null;
  return (
    <GenericViewContainer style={styles.container}>
      <Image
        style={styles.logo}
        source={require("../../resources/icons/logo.png")}
      />
      <Text style={styles.header}>docLogica</Text>
      <Text style={styles.subtitle}>{t("Login.subtitle")}</Text>
      <GenericButton
        type={"outlined"}
        title={"Login with Google"}
        color={"buttonSecondary"}
        width={BUTTON_WIDTH}
        icon={GoogleIcon}
        onPress={googleLoginClicked}
      />
      {appleLogin}
      <Divider />

      <GenericTextInput
        onChangeText={setEmail}
        placeholder={t("Login.emailPlaceholder")}
        style={styles.textInput1}
      />
      <GenericTextInput
        secureTextEntry={true}
        onChangeText={setPassword}
        placeholder={t("Login.passwordPlaceholder")}
        style={styles.textInput2}
      />

      <GenericButton
        width={BUTTON_WIDTH}
        type={"outlined"}
        title={t("Login.emailLoginButton")}
        color={"buttonSecondary"}
        onPress={passwordLoginClicked}
      />
      <Spacer spacing="tight" />
      <GenericButton
        type="clear"
        title={"Forgot Password"}
        color={"buttonDefault"}
        onPress={forgotPasswordClicked}
      />
    </GenericViewContainer>
  );
}

function Divider() {
  return (
    <View
      style={{
        flexDirection: "row",
        width: BUTTON_WIDTH,
        justifyContent: "center",
        alignItems: "center",
        marginVertical: theme.spacing.base,
      }}
    >
      <View
        style={{
          flex: 1,
          height: 1,
          backgroundColor: theme.colors.dividerDefault,
          width: "100%",
          marginHorizontal: theme.spacing.tight,
        }}
      ></View>
      <Text style={{ color: theme.colors.dividerDefault }}>or</Text>
      <View
        style={{
          flex: 1,
          height: 1,
          backgroundColor: theme.colors.dividerDefault,
          width: "100%",
          marginHorizontal: theme.spacing.tight,
        }}
      ></View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.surfaceSecondary,
    height: "100%",
    justifyContent: "center",
  },
  header: {
    fontSize: 40,
    color: theme.colors.textSecondary,
    marginBottom: 8,
  },
  subtitle: {
    fontSize: 22,
    color: theme.colors.textSecondary,
    textAlign: "center",
    marginBottom: 50,
  },
  textInput1: {
    width: BUTTON_WIDTH,
    marginBottom: theme.spacing.base,
  },
  textInput2: {
    width: BUTTON_WIDTH,
    marginBottom: theme.spacing.base,
  },
  logo: {
    width: 100,
    height: 100,
    borderRadius: 50,
    marginBottom: 22,
  },
});
