import React, { useRef, useEffect, ReactElement } from "react";
import { Animated, Easing } from "react-native";

interface Props {
  children: ReactElement;
  initiateAnimation: boolean;
  onCompletion?: () => void;
  initialWidth: number;
  finalWidth: number;
  verticalTranslation: number;
}

export default function MoveToTopAndExpandAnimation({
  initialWidth,
  finalWidth,
  verticalTranslation,
  children,
  onCompletion,
  initiateAnimation,
}: Props) {
  const translateRef = useRef(new Animated.Value(0)).current;
  const width = useRef(new Animated.Value(initialWidth)).current;
  useEffect(() => {
    if (initiateAnimation) {
      Animated.parallel([
        Animated.timing(translateRef, {
          toValue: -verticalTranslation,
          duration: 100,
          useNativeDriver: false,
        }),
        Animated.timing(width, {
          toValue: finalWidth,
          duration: 100,
          easing: Easing.linear,
          useNativeDriver: false,
        }),
      ]).start(() => {
        if (onCompletion != null) {
          onCompletion();
          //resets to original position, but need delay because completion block transitions, such as navigation push can take some time
          setTimeout(() => translateRef.setValue(0), finalWidth);
          setTimeout(() => width.setValue(initialWidth), finalWidth);
        }
      });
    }
  }, [
    finalWidth,
    initialWidth,
    initiateAnimation,
    onCompletion,
    translateRef,
    verticalTranslation,
    width,
  ]);

  return (
    <Animated.View
      style={{
        alignItems: "center",
        width: width,
        transform: [
          {
            translateY: translateRef,
          },
        ],
      }}
    >
      {children}
    </Animated.View>
  );
}
