import React from "react";
import { HomeView } from "./HomeView";
import { Actions, HomeScreenNavigationProp } from "./types";
import { useNavigation } from "@react-navigation/native";

export function HomeScreen() {
  const navigation = useNavigation<HomeScreenNavigationProp>();
  const actions: Actions = {
    onSearchBarAnimationEnd: () => {
      navigation.navigate("Search", {
        selectedFilters: ["tests", "diseases", "probabilities"],
      });
    },
    onBrowseButtonPress: () => {
      navigation.navigate("Browse");
    },
  };
  return <HomeView actions={actions} />;
}
