import { Actions, BrowseScreenNavigationProp } from "./types";
import { useNavigation } from "@react-navigation/native";
import { BrowseView } from "./BrowseView";
import { useAnalytics } from "../../../api/hooks/useAnalytics/useAnalytics";

export function BrowseScreen() {
  const navigation = useNavigation<BrowseScreenNavigationProp>();
  const sendAnalyticEvent = useAnalytics();

  const actions: Actions = {
    onRowPress: (category) => {
      switch (category) {
        case "Diseases":
          sendAnalyticEvent({
            name: "browse_top_category_pressed",
            data: JSON.stringify({ category: category }),
          });
          navigation.navigate("BrowseDiseaseSpecialty");
          break;
        case "Tests":
          sendAnalyticEvent({
            name: "browse_top_category_pressed",
            data: JSON.stringify({ category: category }),
          });
          navigation.navigate("BrowseTestSpecialty");
          break;
        case "Probabilities":
          sendAnalyticEvent({
            name: "browse_top_category_pressed",
            data: JSON.stringify({ category: category }),
          });
          navigation.navigate("BrowseProbabilitySpecialty");
          break;
      }
    },
  };

  return <BrowseView actions={actions} />;
}
