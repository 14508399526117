import { StackActions, useNavigation } from "@react-navigation/native";
import React from "react";
import { Platform, View } from "react-native";
import theme from "../utilities/theme";
import { IconButton } from "./IconButton";
import { SvgProps } from "react-native-svg";
import { GenericTextButton } from "./GenericTextButton";

interface Props {
  content: React.FC<SvgProps> | string;
  onPress?: () => void;
}

export function HeaderButton({ content, onPress }: Props) {
  const navigation = useNavigation();
  const goBack = () => {
    if (navigation.getParent()?.getParent()) {
      navigation.goBack();
    } else if (navigation.getParent()) {
      navigation.getParent()!.goBack();
    } else {
      navigation.goBack();
    }
  };
  const definedOnPress = onPress ? onPress : goBack;
  return (
    <View
      style={{
        marginRight: Platform.OS === "web" ? theme.spacing.loose : undefined,
      }}
    >
      {!isSVG(content) ? (
        <GenericTextButton onPress={definedOnPress} title={content} />
      ) : (
        <IconButton
          icon={content}
          onPress={definedOnPress}
          color={"buttonDefault"}
        />
      )}
    </View>
  );
}

function isSVG(
  content: React.FC<SvgProps> | string
): content is React.FC<SvgProps> {
  return (
    // @ts-ignore
    content.name !== undefined ||
    content.toString().startsWith("data:image/svg+xml")
  );
}
