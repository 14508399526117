import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import theme from "../utilities/theme";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  DatabaseStackParamList,
  DDxStackParamList,
  MainTabNavigationNavigationProp,
  GlobalStackParamList,
  NavigationTabParamList,
  LRModalStackParamList,
} from "./types";
import UserIcon from "../resources/icons/person-outline.svg";
import DatabaseIcon from "../resources/icons/folder-outline.svg";
import DDxIcon from "../resources/icons/share-social-outline.svg";
import { SearchFilterScreen } from "../sections/Database/Search/SearchFilterScreen";
import { LRCalculatorScreen } from "../sections/Database/LikelihoodRatios/LRCalculator/LRCalculatorScreen";
import { HomeScreen as DatabaseHomeScreen } from "../sections/Database/Home/HomeScreen";
import { HomeScreen as DDxHomeScreen } from "../sections/DDx/Home/HomeScreen";
import { SearchScreen } from "../sections/Database/Search/SearchScreen";
import { DiseaseScreen } from "../sections/Database/Diseases/DiseaseScreen";
import { LRListScreen } from "../sections/Database/LikelihoodRatios/LRList/LRListScreen";
import { ReferenceScreen } from "../sections/References";
import { ProbabilityScreen } from "../sections/Database/Probabilities/ProbabilityScreen";
import { ProbabilityModalScreen } from "../sections/Database/Probabilities/ProbabilityModalScreen";
import { HeaderButton } from "../components/HeaderButton";
import { TabBarButton } from "../components/TabBarButton";
import { AccountScreen } from "../sections/Account/AccountScreen";
import { BrowseScreen } from "../sections/Database/Browse/BrowseScreen";
import { BrowseDiseaseSpecialtyScreen } from "../sections/Database/Browse/BrowseDiseaseSpecialtyScreen";
import { BrowseDiseaseSubcategoryScreen } from "../sections/Database/Browse/BrowseDiseaseSubcategoryScreen";
import { BrowseDiseasesScreen } from "../sections/Database/Browse/BrowseDiseasesScreen";
import { BrowseTestSpecialtyScreen } from "../sections/Database/Browse/BrowseTestSpecialtyScreen";
import { BrowseTestDiseasesScreen } from "../sections/Database/Browse/BrowseTestDiseasesScreen";
import { BrowseProbabilityPresentingComplaintsScreen } from "../sections/Database/Browse/BrowseProbabilityPresentingComplaintsScreen";
import { BrowseProbabilitySpecialtyScreen } from "../sections/Database/Browse/BrowseProbabilitySpecialtyScreen";
import { SearchFindingsScreen } from "../sections/DDx/Home/SearchFindingsScreen";
import { DDxResultsScreen } from "../sections/DDx/DDxResults/DDxResultsScreen";
import { DDxFilterScreen } from "../sections/DDx/DDxResults/DDxFilterScreen";
import { AboutScreen } from "../sections/Account/AboutScreen";
import { Platform } from "react-native";

const GlobalStack = createNativeStackNavigator<GlobalStackParamList>();

export default function AppNavigator() {
  return (
    <GlobalStack.Navigator initialRouteName={"MainTabNavigation"}>
      <GlobalStack.Group>
        <GlobalStack.Screen
          options={{
            headerShown: false,
          }}
          name="MainTabNavigation"
          component={MainTabNavigation}
        />
      </GlobalStack.Group>
      <GlobalStack.Group
        screenOptions={{
          headerLeft: Platform.OS === "web" ? () => null : undefined,
          headerBackVisible: false,
          presentation: "modal",
          animation: "slide_from_bottom",
        }}
      >
        <GlobalStack.Screen
          options={{ headerShown: false }}
          name="LRModal"
          component={LRStackNavigation}
        />
        <GlobalStack.Screen
          options={{
            headerLeft: Platform.OS === "web" ? () => null : undefined,
            headerBackVisible: false,
            headerTitle: "Account",
            headerRight: () => <HeaderButton content={"Done"} />,
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="AccountModal"
          component={AccountScreen}
        />
        <GlobalStack.Screen
          name="References"
          component={ReferenceScreen}
          options={{
            headerRight: () => <HeaderButton content={"Done"} />,
            headerTitle: "References",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
        />
        <GlobalStack.Screen
          options={{
            headerRight: () => <HeaderButton content={"Done"} />,
            headerTitle: "Probabilities",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="ProbabilityModal"
          component={ProbabilityModalScreen}
        />
        <GlobalStack.Screen
          options={{
            headerTitle: "Filter",
            headerRight: () => <HeaderButton content={"Done"} />,
          }}
          name="SearchFilter"
          component={SearchFilterScreen}
        />
        <GlobalStack.Screen
          options={{
            headerTitle: "Filter",
            headerRight: () => <HeaderButton content={"Done"} />,
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="DDxFilter"
          component={DDxFilterScreen}
        />
        <GlobalStack.Screen
          options={{
            headerTitle: "About",
            headerRight: () => <HeaderButton content={"Done"} />,
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="About"
          component={AboutScreen}
        />
      </GlobalStack.Group>
    </GlobalStack.Navigator>
  );
}

const Tab = createBottomTabNavigator<NavigationTabParamList>();

function MainTabNavigation() {
  const navigation = useNavigation<MainTabNavigationNavigationProp>();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarStyle: {
          backgroundColor: theme.colors.surfaceSecondary,
        },
      }}
    >
      <Tab.Screen
        options={{
          tabBarButton: () => (
            <TabBarButton
              title={"Database"}
              icon={DatabaseIcon}
              isSelected={selectedTabIndex === 0}
              onPress={() => {
                if (selectedTabIndex !== 0) {
                  // @ts-ignore
                  navigation.navigate("MainTabNavigation", {
                    screen: "Database",
                  });
                } else {
                  navigation.navigate("MainTabNavigation", {
                    screen: "Database",
                    params: {
                      screen: "Home",
                    },
                  });
                }
                setSelectedTabIndex(0);
              }}
            />
          ),
          headerShown: false,
        }}
        name="Database"
        component={DatabaseStackNavigation}
      />
      <Tab.Screen
        options={{
          tabBarButton: () => (
            <TabBarButton
              title={"DDx"}
              icon={DDxIcon}
              isSelected={selectedTabIndex === 1}
              onPress={() => {
                if (selectedTabIndex !== 1) {
                  // @ts-ignore
                  navigation.navigate("MainTabNavigation", {
                    screen: "DDx",
                  });
                } else {
                  // @ts-ignore
                  navigation.navigate("MainTabNavigation", {
                    screen: "DDx",
                    params: {
                      screen: "Home",
                    },
                  });
                }

                setSelectedTabIndex(1);
              }}
            />
          ),
          headerShown: false,
        }}
        name="DDx"
        component={DDxStackNavigation}
      />
      <Tab.Screen
        options={{
          tabBarButton: () => (
            <TabBarButton
              title={"Account"}
              icon={UserIcon}
              isSelected={selectedTabIndex === 2}
              onPress={() => {
                navigation.navigate("MainTabNavigation", {
                  screen: "Account",
                });
                setSelectedTabIndex(2);
              }}
            />
          ),
        }}
        name="Account"
        component={AccountScreen}
      />
    </Tab.Navigator>
  );
}

const LRStack = createNativeStackNavigator<LRModalStackParamList>();

function LRStackNavigation() {
  return (
    <LRStack.Navigator
      initialRouteName="LRListModal"
      screenOptions={{
        animation: "slide_from_right",
        headerLeft: Platform.OS === "web" ? () => null : undefined,
        headerBackVisible: false,
      }}
    >
      <LRStack.Group>
        <LRStack.Screen
          options={{
            headerTitle: "Tests",
            headerRight: () => <HeaderButton content={"Done"} />,
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
            presentation: "modal",
          }}
          name="LRListModal"
          component={LRListScreen}
        />
        <LRStack.Screen
          options={{
            headerBackVisible: true,
            headerTitle: "Test Calculator",
            headerRight: () => <HeaderButton content={"Done"} />,
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
            presentation: "card",
          }}
          name="LRCalculatorModal"
          component={LRCalculatorScreen}
        />
      </LRStack.Group>
    </LRStack.Navigator>
  );
}

const DatabaseStack = createNativeStackNavigator<DatabaseStackParamList>();

function DatabaseStackNavigation() {
  return (
    <DatabaseStack.Navigator
      initialRouteName="Home"
      screenOptions={{ animation: "slide_from_right" }}
    >
      <DatabaseStack.Group>
        <DatabaseStack.Screen
          options={{
            headerTitle: "Database",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="Home"
          component={DatabaseHomeScreen}
        />
        <DatabaseStack.Screen
          options={{
            headerTitle: "Browse",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="Browse"
          component={BrowseScreen}
        ></DatabaseStack.Screen>
        <DatabaseStack.Screen
          options={{
            headerTitle: "Specialty",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="BrowseProbabilitySpecialty"
          component={BrowseProbabilitySpecialtyScreen}
        ></DatabaseStack.Screen>
        <DatabaseStack.Screen
          options={{
            headerTitle: "Presenting Complaint",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="BrowseProbabilityPresentingComplaints"
          component={BrowseProbabilityPresentingComplaintsScreen}
        ></DatabaseStack.Screen>
        <DatabaseStack.Screen
          options={{
            headerTitle: "Specialty",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="BrowseTestSpecialty"
          component={BrowseTestSpecialtyScreen}
        ></DatabaseStack.Screen>
        <DatabaseStack.Screen
          options={{
            headerTitle: "Disease",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="BrowseTestDiseases"
          component={BrowseTestDiseasesScreen}
        />
        <DatabaseStack.Screen
          options={{
            headerTitle: "Specialty",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="BrowseDiseaseSpecialty"
          component={BrowseDiseaseSpecialtyScreen}
        ></DatabaseStack.Screen>
        <DatabaseStack.Screen
          options={{
            headerTitle: "Subcategory",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="BrowseDiseaseSubcategory"
          component={BrowseDiseaseSubcategoryScreen}
        />
        <DatabaseStack.Screen
          options={{
            headerTitle: "Disease",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="BrowseDiseases"
          component={BrowseDiseasesScreen}
        />
        <DatabaseStack.Screen
          options={{
            animation: "none",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          initialParams={{
            selectedFilters: ["tests", "diseases", "probabilities"],
          }}
          name="Search"
          component={SearchScreen}
        />
        <DatabaseStack.Screen
          options={{
            headerTitle: "Test Calculator",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="LRCalculator"
          component={LRCalculatorScreen}
        />
        <DatabaseStack.Screen
          options={{
            headerTitle: "Tests",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="LRList"
          component={LRListScreen}
        />
        <DatabaseStack.Screen
          options={{
            headerTitle: "Disease Lookup",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="Disease"
          component={DiseaseScreen}
        />
        <DatabaseStack.Screen
          options={{
            headerTitle: "Probabilities",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="Probability"
          component={ProbabilityScreen}
        />
      </DatabaseStack.Group>
    </DatabaseStack.Navigator>
  );
}

const DDxStack = createNativeStackNavigator<DDxStackParamList>();

function DDxStackNavigation() {
  return (
    <DDxStack.Navigator
      initialRouteName="Home"
      screenOptions={{ animation: "slide_from_right" }}
    >
      <DatabaseStack.Group>
        <DDxStack.Screen
          options={{
            headerTitle: "DDx",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="Home"
          component={DDxHomeScreen}
        />
        <DDxStack.Screen
          options={{
            headerLeft: Platform.OS === "web" ? () => null : undefined,
            headerBackVisible: false,
            animation: "slide_from_bottom",
            headerTitle: "Search Findings",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
            presentation: "modal",
          }}
          name="SearchFindings"
          component={SearchFindingsScreen}
        />
        <DDxStack.Screen
          options={{
            headerTitle: "Results",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="DDxResults"
          component={DDxResultsScreen}
        />
        <DatabaseStack.Screen
          options={{
            headerTitle: "Disease Lookup",
            headerStyle: {
              backgroundColor: theme.colors.surfaceSecondary,
            },
          }}
          name="Disease"
          component={DiseaseScreen}
        />
      </DatabaseStack.Group>
    </DDxStack.Navigator>
  );
}
