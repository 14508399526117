import React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import theme from "../../../../utilities/theme";
import { LR } from "../../../../api/hooks/useLRsQuery/types";
import { GenericListViewContainer } from "../../../../components/GenericListViewContainer";
import { GenericRadioButtons } from "../../../../components/GenericRadioButtons";
import { GenericRow } from "../../../../components/GenericRow";
import { Badge } from "../../../../components/Badge";
import { Actions, SortType } from "./types";
import { getTestTypeStringFromEnum } from "../helpers/getTestTypeStringFromEnum";
import { getQuantitativeAndQualitativeTestAccuracy } from "../helpers/lr_formatting";
import { obscureString } from "../../../../utilities";
import { useIsIdBlocked } from "../../../../api/hooks/useIsIdBlocked/useIsIdBlocked";

interface Props {
  tests: LR[];
  actions: Actions;
  sortType: SortType;
}

export function LRListView({ tests, actions, sortType }: Props) {
  const isIdBlocked = useIsIdBlocked();

  const showDiseaseName = new Set(tests.map((test) => test.disease)).size > 1;

  return (
    <>
      <GenericListViewContainer>
        <FlatList
          keyboardDismissMode="on-drag"
          keyboardShouldPersistTaps="always"
          keyExtractor={(item) => item.id.toString()}
          ListHeaderComponent={
            <View style={styles.sortButtonsContainer}>
              <GenericRadioButtons
                titles={["abc", "best rule-in", "best rule-out"]}
                onPress={(id) => {
                  actions.onSortButtonPress(getSortTypeFromIndex(id));
                }}
                activeIndex={getIndexFromSortType(sortType)}
              />
            </View>
          }
          data={tests}
          renderItem={({ item }) =>
            !isIdBlocked(item.id) ? (
              <GenericRow
                primaryTitle={item.title}
                rightView={
                  <Badge title={getTestTypeStringFromEnum(item.type)} />
                }
                secondaryTitle={getQuantitativeAndQualitativeTestAccuracy(item)}
                tertiaryTitle={showDiseaseName ? item.disease : undefined}
                onPress={() =>
                  actions.onLRRowPress(item.id, item.title, item.disease)
                }
              />
            ) : (
              <GenericRow
                primaryTitle={obscureString(item.title)}
                rightView={
                  <Badge title={getTestTypeStringFromEnum(item.type)} />
                }
                tertiaryTitle={getQuantitativeAndQualitativeTestAccuracy(item)}
                secondaryTitle={"subscribe to view"}
                onPress={() => actions.onBlockedLRRowPress()}
              />
            )
          }
        />
      </GenericListViewContainer>
    </>
  );
}

function getIndexFromSortType(sortType: SortType): number {
  switch (sortType) {
    case "abc":
      return 0;
    case "bestRuleIn":
      return 1;
    case "bestRuleOut":
      return 2;
  }
}

function getSortTypeFromIndex(index: number): SortType {
  switch (index) {
    case 0:
      return "abc";
    case 1:
      return "bestRuleIn";
    case 2:
      return "bestRuleOut";
  }
  throw "bad index for sort type";
}

const styles = StyleSheet.create({
  sortButtonsContainer: {
    flex: 1,
    paddingHorizontal: theme.spacing.base,
    paddingBottom: theme.spacing.extraTight,
    paddingTop: theme.spacing.base,
    backgroundColor: theme.colors.surfaceSecondary,
  },
});
