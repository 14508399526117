import { AxiosResponse } from "axios";
import { useQuery } from "../../../../api/hooks/useQuery/useQuery";

export function useServerDeleteNormalToken({
  onCompletion,
  onError,
}: {
  onCompletion: (response: AxiosResponse) => void;
  onError: (error: Error) => void;
}) {
  const { execute } = useQuery({
    server: "auth",
    path: `auth/delete-normal-token`,
    type: "POST",
    onCompletion: (response) => onCompletion(response),
    onError: (error) => onError(error),
  });
  return { execute };
}
