import { useEffect, useState } from "react";
import { useQuery } from "../useQuery/useQuery";
import { APILR, DiseaseId, LR, TestType } from "./types";

export function useLRsQuery({ id, type }: DiseaseId) {
  const [lrs, setLRs] = useState<LR[] | null>(null);
  const { execute, isLoading, error } = useQuery<APILR[]>({
    server: "database",
    path:
      type === "test"
        ? `tests/lrs-from-test-disease-id/${id}`
        : `tests/lrs-from-database-disease-id/${id}`,
    type: "GET",
    onCompletion: (response) => {
      const elements = response.data;
      const mappedResponse: LR[] = elements.map((lr) => {
        return {
          avg: lr.avg,
          id: lr.id,
          max: lr.max,
          min: lr.min,
          title: lr.title,
          disease: lr.disease,
          type: testTypeMapper(lr.type),
        };
      });
      setLRs(mappedResponse);
    },
  });
  useEffect(() => {
    execute();
  }, []);
  return { lrs, isLoading, error };
}

function testTypeMapper(string: string): TestType {
  switch (string) {
    case "decisionRule":
      return TestType.decisionRule;
    case "EKG":
      return TestType.EKG;
    case "history":
      return TestType.history;
    case "lab":
      return TestType.lab;
    case "physicalExam":
      return TestType.physicalExam;
    case "radiology":
      return TestType.radiology;
    default:
      throw new Error("bad test type enum");
  }
}
