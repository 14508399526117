import React from "react";
import WifiIcon from "../resources/icons/wifi-outline.svg";
import { StyleSheet, Text } from "react-native";
import theme from "../utilities/theme";
import { useTranslation } from "react-i18next";
import { GenericViewContainer } from "./GenericViewContainer";

export function NoInternetView() {
  const { t } = useTranslation();
  return (
    <GenericViewContainer style={styles.container}>
      <WifiIcon style={styles.icon} />
      <Text style={styles.text}>{t("General.noInternet")}</Text>
    </GenericViewContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: "center",
  },
  icon: {
    height: 100,
    width: 100,
    color: theme.colors.iconSubdued,
  },
  text: {
    fontSize: theme.fontSize.normal,
    fontWeight: "bold",
    marginTop: theme.spacing.tight,
    color: theme.colors.textSubdued,
  },
});
