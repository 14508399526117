import React, { useEffect, useRef, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { StyleSheet, Text, View } from "react-native";
import theme from "../../../../utilities/theme";
import Slider from "@react-native-community/slider";
import { getProbabilitiesWithCIFromLR } from "../helpers/lr_formatting";
import LinkIcon from "../../../../resources/icons/link-outline.svg";
import SuggestionIcon from "../../../../resources/icons/suggestion.svg";
import { throttle } from "lodash";
import { useFocusEffect } from "@react-navigation/native";
import { Actions } from "./types";
import { SourceType, Test } from "../../../../api/hooks/useTestQuery/types";
import { GenericViewContainer } from "../../../../components/GenericViewContainer";
import { Card } from "../../../../components/Card";
import { ExplanatoryRow } from "../../../../components/ExplanatoryRow";
import { LRValuesCarousel } from "./components/LRValuesCarousel";
import { Spacer } from "../../../../components/Spacer";
import { IconButton, IconButtonType } from "../../../../components/IconButton";
import { SpacerAndDivider } from "../../../../components/SpacerAndDivider";

interface Props {
  test: Test;
  preTestProbability: number;
  actions: Actions;
}

function getSourceStringFromSourceType(type: SourceType, t: TFunction) {
  switch (type) {
    case SourceType.metaAnalysis:
      return t("Database.metaAnalysisDescription");
    case SourceType.singleStudy:
      return t("Database.singleStudyDescription");
  }
}

export function LRCalculatorView({
  test,
  preTestProbability: initialPreTestProbability,
  actions,
}: Props) {
  if (test === null) {
    return null;
  }

  const { t } = useTranslation();
  const [testLRIndex, setTestLRIndex] = useState(test.currentLRIndex);
  const [preTestProbability, setPreTestProbability] = useState(
    initialPreTestProbability
  );

  useFocusEffect(
    React.useCallback(() => {
      setPreTestProbability(initialPreTestProbability);
    }, [initialPreTestProbability])
  );

  const [postTestProbability, setPostTestProbability] =
    useState("will be calculated");

  useEffect(() => {
    setPostTestProbability(
      getProbabilitiesWithCIFromLR(preTestProbability, test.lrs[testLRIndex])
    );
  }, [preTestProbability, testLRIndex, test.lrs, test.currentLRIndex]);

  const throttleSetPreTestProbability = useRef(
    throttle((value: number) => {
      setPreTestProbability(value);
    }, 100)
  ).current;

  return (
    <GenericViewContainer noHorizontalPadding>
      <View style={styles.cardContainer}>
        <Card>
          <ExplanatoryRow
            header={t("Database.diseaseTitleHeader")}
            title={test.diseaseTitle}
          />
        </Card>
      </View>
      <Spacer spacing="loose" />
      <LRValuesCarousel
        test={test}
        onTestLRIndexChanged={(index) => {
          setTestLRIndex(index);
        }}
      />
      <Spacer spacing="base" />
      <View style={styles.cardContainer}>
        <Card>
          <ExplanatoryRow header={"Pre-Test Probability"}>
            <View style={styles.sliderAndTextContainer}>
              <Slider
                value={preTestProbability}
                style={styles.slider}
                minimumValue={0}
                maximumValue={100}
                minimumTrackTintColor={theme.colors.sliderMinimumTrack}
                maximumTrackTintColor={theme.colors.sliderMaximumTrack}
                thumbTintColor={theme.colors.sliderMinimumTrack}
                onValueChange={throttleSetPreTestProbability}
              />
              <Text style={styles.preTestText}>{`${preTestProbability.toFixed(
                0
              )}%`}</Text>
              {test.hasProbabilitySuggestion && (
                <IconButton
                  onPress={() =>
                    actions.onProbabilitySuggestionPress(testLRIndex)
                  }
                  icon={SuggestionIcon}
                  style={IconButtonType.plainColored}
                />
              )}
            </View>
          </ExplanatoryRow>
          <SpacerAndDivider />
          <ExplanatoryRow
            header={"Post-Test Probability (95% CI)"}
            title={`${postTestProbability}`}
          />
        </Card>
      </View>
      <Spacer spacing="loose" />
      <View style={styles.cardContainer}>
        <Card>
          <ExplanatoryRow
            header={t("Database.testPopulationTitleHeader")}
            title={test.population}
          />
          <SpacerAndDivider />
          <ExplanatoryRow
            header={t("Database.testGoldStandardTitleHeader")}
            title={test.goldStandard}
          />
          <SpacerAndDivider />
          <ExplanatoryRow
            header={t("Database.testSourceTypeTitleHeader")}
            title={getSourceStringFromSourceType(test.sourceType, t)}
          />
          {test.comment && (
            <>
              <SpacerAndDivider />
              <ExplanatoryRow
                header={t("Database.testCommentTitleHeader")}
                title={test.comment}
              />
            </>
          )}
          <SpacerAndDivider />
          <ExplanatoryRow header={t("Database.testSourceReferenceTitleHeader")}>
            <View style={styles.linkContainer}>
              <Text style={styles.referenceText}>{test.referenceTitle}</Text>
              {test.referenceLink !== null && (
                <IconButton
                  onPress={() => actions.onReferencePress(test.referenceLink!)}
                  icon={LinkIcon}
                  style={IconButtonType.plainColored}
                />
              )}
            </View>
          </ExplanatoryRow>
        </Card>
      </View>
    </GenericViewContainer>
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    width: "100%",
    alignItems: "center",
    paddingHorizontal: theme.spacing.defaultHorizontalGutter,
  },
  sliderAndTextContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  slider: {
    flex: 1,
    marginRight: theme.spacing.base,
  },
  preTestText: {
    width: 44,
    textAlign: "right",
    fontSize: theme.fontSize.normal,
    marginRight: theme.spacing.base,
  },
  linkContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  referenceText: {
    fontSize: theme.fontSize.normal,
    paddingRight: theme.spacing.subtitleIndent,
  },
});
