import {
  ResultType,
  SearchResult,
} from "../../../sections/Database/Search/types";
import { useEffect, useState } from "react";
import { useQuery } from "../useQuery/useQuery";
import { APISearchResult } from "./types";

export function useSearchResults(query: string) {
  const [results, setResults] = useState<SearchResult[]>([]);
  const { execute, isLoading, error } = useQuery<APISearchResult[]>({
    server: "database",
    path: `search/?query=${query}`,
    type: "GET",
    onCompletion: (response) => {
      const mappedResponse: SearchResult[] = response.data.map((element) => {
        return {
          id: element.id,
          type: typeMapper(element.type),
          title: element.title,
        };
      });
      setResults(mappedResponse);
    },
  });
  useEffect(() => {
    if (query !== "") {
      execute();
    } else {
      setResults([]);
    }
  }, [query]);
  return { results, isLoading, error };
}

function typeMapper(string: string): ResultType {
  switch (string) {
    case "probability":
      return ResultType.probabilities;
    case "disease":
      return ResultType.disease;
    case "testDisease":
      return ResultType.lrList;
    case "testName":
      return ResultType.lrOne;
    default:
      throw new Error("bad search result type enum");
  }
}
