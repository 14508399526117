import Toast from "react-native-toast-message";

export function showErrorToast(reason?: string) {
  Toast.show({
    type: "error",
    text1: "Error",
    text2: reason ? reason : "Something went wrong",
    position: "bottom",
  });
}

export function showInfoToast(
  title: string,
  infiniteDuration: boolean = false,
  subtitle?: string
) {
  Toast.show({
    type: "info",
    text1: title,
    text2: subtitle,
    position: "bottom",
    autoHide: !infiniteDuration,
  });
}

export function showSuccessToast(title: string, subtitle?: string) {
  Toast.show({
    type: "success",
    text1: title,
    text2: subtitle,
    position: "bottom",
  });
}
