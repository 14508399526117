import { useContext, useRef, useState } from "react";
import Purchases from "react-native-purchases";
import { Offerings, SubscriptionState } from "./types";
import { useUserInfo } from "../../../../api/hooks/useUserInfo/useUserInfo";
import { SessionContext } from "../../../../context/Session/Session";
import { Platform } from "react-native";

export function useGetSubscription() {
  const isFirst = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [subscription, setSubscription] = useState<SubscriptionState | null>(
    null
  );
  const { setAccess } = useContext(SessionContext);
  let offerings: Offerings;
  const { execute: getUserInfo } = useUserInfo(
    (userInfo) => {
      setAccess(userInfo.subscription.databaseAccessLevel);
      if (offerings) {
        setSubscription({
          activeSubscriptionType: userInfo.subscription.activeSubscription,
          annualTitle: offerings.annualTitle,
          monthlyTitle: offerings.monthlyTitle,
          annualPrice: offerings.annualPrice,
          monthlyPrice: offerings.monthlyPrice,
          annualId: offerings.annualId,
          monthlyId: offerings.monthlyId,
        });
      }
    },
    (err) => {
      setError(err);
    }
  );

  const getOfferings = async () => {
    const purchasesOfferings = await Purchases.getOfferings();
    console.log(purchasesOfferings);
    if (
      purchasesOfferings.current?.annual?.identifier &&
      purchasesOfferings.current?.monthly?.identifier &&
      purchasesOfferings.current?.annual?.product &&
      purchasesOfferings.current?.monthly?.product
    ) {
      offerings = {
        annualTitle: purchasesOfferings.current.annual.product.title,
        monthlyTitle: purchasesOfferings.current.monthly.product.title,
        annualPrice: purchasesOfferings.current.annual.product.priceString,
        monthlyPrice: purchasesOfferings.current.monthly.product.priceString,
        annualId: purchasesOfferings.current.annual.product.identifier,
        monthlyId: purchasesOfferings.current.monthly.product.identifier,
      };
    } else {
      setError(new Error("offerings data missing from revenue cat"));
    }
  };

  const fetch = async () => {
    setError(null);
    try {
      if (Platform.OS !== "web") {
        await getOfferings();
        await getUserInfo();
      } else {
        await getUserInfo();
      }
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  };

  const updateSubscription = (updates: Partial<SubscriptionState>) => {
    if (subscription) {
      setSubscription({ ...subscription, ...updates });
    } else {
      throw "no current subscription set";
    }
  };

  if (isFirst.current) {
    isFirst.current = false;
    fetch();
  }

  return {
    isLoading,
    error,
    subscription,
    refresh: fetch,
    updateSubscription,
  };
}
