import React, { useState } from "react";
import { LRListView } from "./LRListView";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useLRsQuery } from "../../../../api/hooks/useLRsQuery/useLRsQuery";
import { DiseaseId, LR } from "../../../../api/hooks/useLRsQuery/types";
import { GenericScreen } from "../../../../components/GenericScreen";
import {
  SortType,
  LRListScreenNavigationProp,
  LRListScreenRouteProp,
  Actions,
} from "./types";
import { useAnalytics } from "../../../../api/hooks/useAnalytics/useAnalytics";

export function LRListScreen() {
  const [sortType, setSortType] = useState<SortType>("abc");
  const route = useRoute<LRListScreenRouteProp>();
  const navigation = useNavigation<LRListScreenNavigationProp>();
  const routes = navigation.getState()?.routes;
  const id: DiseaseId =
    route.params.diseaseId.type === "Test"
      ? { id: route.params.diseaseId.id, type: "test" }
      : { id: route.params.diseaseId.id, type: "database" };
  const { lrs, isLoading, error } = useLRsQuery(id);
  const sendAnalyticEvent = useAnalytics();

  const actions: Actions = {
    onLRRowPress: (id, testName, diseaseName) => {
      sendAnalyticEvent({
        name: "test_list_row_pressed",
        data: JSON.stringify({ test: testName, disease: diseaseName }),
      });
      if (routes.length === 1) {
        navigation.navigate("LRModal", {
          screen: "LRCalculatorModal",
          params: {
            id: {
              id: id,
              type: "lr",
            },
          },
        });
      } else {
        navigation.navigate("LRCalculator", {
          id: {
            id: id,
            type: "lr",
          },
        });
      }
    },
    onBlockedLRRowPress: () => {
      navigation.navigate("AccountModal");
    },
    onSortButtonPress: (type) => {
      setSortType(type);
    },
  };

  const sortedTests = lrs ? sortTests(lrs, sortType) : [];

  return (
    <GenericScreen
      content={
        <LRListView tests={sortedTests} actions={actions} sortType={sortType} />
      }
      error={error}
      isLoading={isLoading}
    />
  );
}

function sortTests(tests: LR[], sortType: SortType): LR[] {
  switch (sortType) {
    case "abc":
      return tests.sort((a, b) => {
        return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
      });
    case "bestRuleIn":
      return tests.sort((a, b) => {
        return a.avg < b.avg ? 1 : -1;
      });
    case "bestRuleOut":
      return tests.sort((a, b) => {
        return a.avg > b.avg ? 1 : -1;
      });
  }
}
