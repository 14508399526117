import React, {useEffect, useRef, useState} from "react";
import {
  Animated,
  FlatList, Platform,
  StyleSheet,
  useWindowDimensions,
} from "react-native";
import theme from "../../../../../utilities/theme";
import { Test } from "../../../../../api/hooks/useTestQuery/types";
import { LRValuesCard } from "./LRValuesCard";
import { Paginator } from "../../../../../components/Paginator";

interface Props {
  test: Test;
  onTestLRIndexChanged: (index: number) => void;
}

export function LRValuesCarousel({ test, onTestLRIndexChanged }: Props) {
  const { width: screenWidth } = useWindowDimensions();
  const scrollX = useRef(new Animated.Value(0)).current;
  const [carouselIndex, setCarouselIndex] = useState(test.currentLRIndex);
  const flatlistRef = useRef<FlatList>(null);
  const snapOffsetArray = getSnapOffsetArray(test.lrs.length, screenWidth);
  useEffect(() => {
    onTestLRIndexChanged(carouselIndex);
  }, [carouselIndex, onTestLRIndexChanged]);

  const nextPress = () => {
    flatlistRef.current?.scrollToIndex({
      index:
        carouselIndex === snapOffsetArray.length - 1
          ? carouselIndex
          : carouselIndex + 1,
    });
  };
  const prevPress = () => {
    flatlistRef.current?.scrollToIndex({
      index: carouselIndex === 0 ? carouselIndex : carouselIndex - 1,
    });
  };

  return (
    <>
      <FlatList
        onLayout={() => {
          if (Platform.OS === "web") {
            flatlistRef.current?.scrollToIndex({
              index: carouselIndex
            });
          }
        }}
        keyboardShouldPersistTaps={"handled"}
        ref={flatlistRef}
        contentOffset={{
          x: snapOffsetArray[test.currentLRIndex],
          y: 0,
        }}
        showsHorizontalScrollIndicator={false}
        style={styles.container}
        data={[...Array(test.lrs.length).keys()]}
        horizontal
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => <LRValuesCard lrIndex={item} test={test} />}
        bounces={false}
        decelerationRate={0}
        renderToHardwareTextureAndroid
        snapToOffsets={getSnapOffsetArray(test.lrs.length, screenWidth)}
        onScroll={(e) => {
          Animated.event([{ nativeEvent: { contentOffset: { x: scrollX } } }], {
            useNativeDriver: false,
          });
          const activeCarouselCardIndex = getActiveCarouselCardIndex(
            e.nativeEvent.contentOffset.x,
            snapOffsetArray
          );
          setCarouselIndex(activeCarouselCardIndex);
        }}
        scrollEventThrottle={16}
      />
      {test.lrs.length > 1 ? (
        <Paginator
          size={test.lrs.length}
          index={carouselIndex}
          nextPress={nextPress}
          prevPress={prevPress}
        />
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  card: {
    flex: 1,
    width: "100%",
    paddingHorizontal: theme.spacing.defaultHorizontalGutter,
  },
  container: {
    width: "100%",
    paddingBottom: theme.spacing.base,
  },
});

function getSnapOffsetArray(size: number, screenWidth: number) {
  let array: number[] = [];
  for (let i = 0; i < size; i++) {
    array.push(getCarouselOffsetFromIndex(i, size, screenWidth));
  }
  return array;
}

function getCarouselOffsetFromIndex(
  index: number,
  size: number,
  screenWidth: number
) {
  if (index === 0) {
    return 0;
  } else if (index === size - 1) {
    return (
      (screenWidth - carouseCardOffsetConstant) * index -
      theme.spacing.defaultHorizontalGutter -
      theme.spacing.extraCarouselWidthSubstraction +
      theme.spacing.spaceBetweenCarouselCards
    );
  } else {
    return (
      (screenWidth - carouseCardOffsetConstant) * index -
      theme.spacing.defaultHorizontalGutter
    );
  }
}

function getActiveCarouselCardIndex(
  currentOffset: number,
  offsetArray: number[]
) {
  return closestIndex(offsetArray, currentOffset);
}
function closestIndex(haystack: number[], needle: number) {
  const closest = haystack.reduce((a, b) => {
    return Math.abs(b - needle) < Math.abs(a - needle) ? b : a;
  });
  return haystack.indexOf(closest);
}

const carouseCardOffsetConstant =
  theme.spacing.extraCarouselWidthSubstraction +
  theme.spacing.spaceBetweenCarouselCards;
