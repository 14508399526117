import {
  Actions,
  BrowseProbabilityPresentingComplaintsScreenNavigationProp,
  BrowseProbabilityPresentingComplaintsScreenRouteProp,
} from "./types";
import { GenericScreen } from "../../../components/GenericScreen";
import React from "react";
import { GenericList } from "../../../components/GenericList";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useBrowseProbabilityPresentingComplaintsQuery } from "../../../api/hooks/useBrowseProbabilityPresentingComplaintsQuery/useBrowseProbabilityPresentingComplaintsQuery";
import { Platform } from "react-native";
import { useAnalytics } from "../../../api/hooks/useAnalytics/useAnalytics";

export function BrowseProbabilityPresentingComplaintsScreen() {
  const navigation =
    useNavigation<BrowseProbabilityPresentingComplaintsScreenNavigationProp>();
  const route =
    useRoute<BrowseProbabilityPresentingComplaintsScreenRouteProp>();
  const { isLoading, error, presentingComplaints } =
    useBrowseProbabilityPresentingComplaintsQuery(route.params.specialty);
  const sendAnalyticEvent = useAnalytics();

  const actions: Actions = {
    onRowPress: (name, index) => {
      sendAnalyticEvent({
        name: "browse_probability_presenting_complaint_pressed",
        data: JSON.stringify({
          speciality: route.params.specialty,
          presenting_complaint: name,
        }),
      });
      navigation.navigate("Probability", {
        id: presentingComplaints[index].id,
      });
    },
  };

  const names: string[] = presentingComplaints
    ? presentingComplaints.map((disease) => disease.name)
    : [];

  return (
    <GenericScreen
      content={
        <GenericList
          names={names}
          onRowPress={actions.onRowPress}
          showCaret={Platform.OS === "ios"}
        />
      }
      error={error}
      isLoading={isLoading}
    />
  );
}
