import {
  getQualitativeStringFromLR,
  getQuantitativeTestAccuracy,
} from "../../helpers/lr_formatting";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, useWindowDimensions, View } from "react-native";
import theme from "../../../../../utilities/theme";
import { Test } from "../../../../../api/hooks/useTestQuery/types";
import { Card } from "../../../../../components/Card";
import { ExplanatoryRow } from "../../../../../components/ExplanatoryRow";
import { SpacerAndDivider } from "../../../../../components/SpacerAndDivider";
import { getTestTypeStringFromEnum } from "../../helpers/getTestTypeStringFromEnum";

interface Props {
  test: Test;
  lrIndex: number;
}

export function LRValuesCard({ test, lrIndex }: Props) {
  const { t } = useTranslation();
  const { width: screenWidth } = useWindowDimensions();
  const styles = getStyles(test.lrs.length, lrIndex, screenWidth);
  return (
    <View style={styles.container}>
      <Card>
        <ExplanatoryRow
          header={t("Database.testTitleHeader")}
          title={test.lrs[lrIndex].title}
        />
        <SpacerAndDivider />
        <ExplanatoryRow
          header={t("Database.testTypeTitleHeader")}
          title={getTestTypeStringFromEnum(test.lrs[lrIndex].type)}
        />
        <SpacerAndDivider />
        <ExplanatoryRow
          header={t("Database.testQualitativeAccuracyTitleHeader")}
          title={getQualitativeStringFromLR(test.lrs[lrIndex])}
        />
        <SpacerAndDivider />
        <ExplanatoryRow
          header={t("Database.testQuantitativeAccuracyTitleHeader")}
          title={getQuantitativeTestAccuracy(test.lrs[lrIndex])}
        />
      </Card>
    </View>
  );
}

function getStyles(length: number, index: number, screenWidth: number) {
  let marginLeft = 0;
  let marginRight = theme.spacing.spaceBetweenCarouselCards;
  let width =
    screenWidth -
    2 * theme.spacing.defaultHorizontalGutter -
    theme.spacing.extraCarouselWidthSubstraction;
  if (index === 0) {
    marginLeft = theme.spacing.defaultHorizontalGutter;
  } else if (length - 1 === index) {
    marginRight = theme.spacing.defaultHorizontalGutter;
  }
  if (length === 1) {
    width = screenWidth - 2 * theme.spacing.defaultHorizontalGutter;
  }
  return StyleSheet.create({
    container: {
      width: width,
      marginLeft: marginLeft,
      marginRight: marginRight,
    },
  });
}
