import { Text, TouchableOpacity } from "react-native";
import theme from "../utilities/theme";
import React from "react";

interface Props {
  title: string;
  onPress: () => void;
}

export function GenericTextButton({ title, onPress }: Props) {
  return (
    <TouchableOpacity onPress={onPress}>
      <Text style={{ color: theme.colors.buttonDefault }}>{title}</Text>
    </TouchableOpacity>
  );
}
