//value is sort priority
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import {
  DatabaseStackParamList,
  GlobalStackParamList,
  MainTabNavigationNavigationProp,
} from "../../../navigation/types";
import { CompositeNavigationProp, RouteProp } from "@react-navigation/native";
import { FilterState } from "../../../components/types";

export enum ResultType {
  disease = 0,
  lrList = 1,
  lrOne = 3,
  probabilities = 2,
}

export interface SearchResult {
  id: number;
  type: ResultType;
  title: string;
}

export type SearchFilter = "tests" | "probabilities" | "diseases";

export type ResultRowId = {
  id: number;
  name: string;
  type: ResultType;
};

export type Actions = {
  onSearchRowPress: ({ id }: ResultRowId) => void;
  onBlockedSearchRowPress: () => void;
  onClearButtonPress: () => void;
};

export type SearchScreenNavigationProp = CompositeNavigationProp<
  MainTabNavigationNavigationProp,
  NativeStackNavigationProp<DatabaseStackParamList, "Search">
>;

export type SearchScreenRouteProp = RouteProp<DatabaseStackParamList, "Search">;

export type SearchFilterScreenRouteProp = RouteProp<
  GlobalStackParamList,
  "SearchFilter"
>;

export type SearchFilterScreenNavigationProp = NativeStackNavigationProp<
  GlobalStackParamList,
  "SearchFilter"
>;
