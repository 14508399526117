import { Actions } from "./types";
import { GenericListViewContainer } from "../../../components/GenericListViewContainer";
import { DDxResult, QualitativeProbability } from "../types";
import React from "react";
import { FlatList, ListRenderItemInfo, View } from "react-native";
import { GenericRow } from "../../../components/GenericRow";
import { Badge } from "../../../components/Badge";
import theme from "../../../utilities/theme";
import { obscureString } from "../../../utilities";
import { useIsIdBlocked } from "../../../api/hooks/useIsIdBlocked/useIsIdBlocked";

interface Props {
  actions: Actions;
  results: DDxResult[];
}

export function DDxResultsView({ actions, results }: Props) {
  const isIdBlocked = useIsIdBlocked();

  return (
    <GenericListViewContainer>
      <FlatList
        keyExtractor={(item) => String(item.id)}
        data={results}
        renderItem={({ item }: ListRenderItemInfo<DDxResult>) => {
          const complications =
            item.complications !== ""
              ? `complicated by ${item.complications}`
              : undefined;
          return !isIdBlocked(item.id) ? (
            <GenericRow
              primaryTitle={item.name}
              secondaryTitle={complications}
              rightView={
                <View style={{ flexDirection: "row" }}>
                  {item.emergent ? (
                    <Badge title={"EMERGENT"} color={"textCritical"} />
                  ) : null}
                  <>
                    <View style={{ marginLeft: theme.spacing.tight }} />
                    <Badge
                      title={probabilityName[item.probability.qualitative]}
                      color={probabilityColor[item.probability.qualitative]}
                    />
                  </>
                </View>
              }
              onPress={() => actions.onResultPress(item.id, item.name)}
            />
          ) : (
            <GenericRow
              primaryTitle={obscureString(item.name)}
              secondaryTitle={"subscribe to view"}
              rightView={
                <View style={{ flexDirection: "row" }}>
                  {item.emergent ? (
                    <Badge title={"EMERGENT"} color={"textCritical"} />
                  ) : null}
                  <>
                    <View style={{ marginLeft: theme.spacing.tight }} />
                    <Badge
                      title={probabilityName[item.probability.qualitative]}
                      color={probabilityColor[item.probability.qualitative]}
                    />
                  </>
                </View>
              }
              onPress={() => actions.onBlockedResultPress()}
            />
          );
        }}
      />
    </GenericListViewContainer>
  );
}

const probabilityColor: Record<
  QualitativeProbability,
  keyof typeof theme.colors
> = {
  very_likely: "buttonDefault",
  likely: "buttonDefault",
  unlikely: "buttonSuccess",
  somewhat_unlikely: "buttonSuccess",
  very_unlikely: "buttonWarning",
  extremely_unlikely: "buttonWarning",
};

const probabilityName: Record<QualitativeProbability, string> = {
  very_likely: "very likely",
  likely: "likely",
  unlikely: "unlikely",
  somewhat_unlikely: "somewhat unlikely",
  very_unlikely: "very unlikely",
  extremely_unlikely: "extremely unlikely",
};
