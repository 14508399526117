import { Text, View } from "react-native";
import { IconButton, IconButtonType } from "../../../../components/IconButton";
import RemoveIcon from "../../../../resources/icons/close-circle-outline.svg";
import React from "react";
import theme from "../../../../utilities/theme";
import { SpacerAndDivider } from "../../../../components/SpacerAndDivider";
import { Finding } from "../types";

interface Props {
  finding: Finding;
  showLowerBorder?: boolean;
  onRemovePress: (finding: Finding) => void;
}

export function FindingRow({
  finding,
  showLowerBorder = true,
  onRemovePress,
}: Props) {
  return (
    <View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <IconButton
          onPress={() => onRemovePress(finding)}
          icon={RemoveIcon}
          style={IconButtonType.plainColored}
          color="iconDefault"
        />
        <Text
          style={{
            color: theme.colors.textPrimary,
            fontSize: theme.fontSize.normal,
            paddingLeft: theme.spacing.tight,
          }}
        >
          {finding.name}
        </Text>
      </View>
      {showLowerBorder ? <SpacerAndDivider /> : null}
    </View>
  );
}
