import { TFunction } from "react-i18next";
import { LR } from "../../../../api/hooks/useLRsQuery/types";

export function getQualitativeStringFromLR(lr: LR) {
  return lr.avg > 1
    ? positiveLROutput(lr) + " " + "rule-in"
    : negativeLROutput(lr) + " " + "rule-out";
}

export function positiveLROutput(lr: LR): string {
  if (lr.max > 20) {
    return "excellent";
  } else if (lr.avg > 10) {
    return "very good";
  } else if (lr.avg > 5) {
    return "good";
  } else if (lr.avg > 2) {
    return "fair";
  } else if (lr.avg >= 1) {
    return "poor";
  } else {
    throw new Error("LR Error"); //impossible to get here if passes correct sign
  }
}

export function negativeLROutput(lr: LR): string {
  if (lr.avg < 0.05) {
    return "excellent";
  } else if (lr.avg < 0.1) {
    return "very good";
  } else if (lr.avg < 0.2) {
    return "good";
  } else if (lr.avg < 0.5) {
    return "fair";
  } else if (lr.avg <= 1) {
    return "poor";
  } else {
    throw new Error("LR Error"); //impossible to get here if passes correct sign
  }
}

export function getQuantitativeAndQualitativeTestAccuracy(lr: LR) {
  return (
    getQualitativeStringFromLR(lr) + ", " + getQuantitativeTestAccuracy(lr)
  );
}

export function getQuantitativeTestAccuracy(lr: LR) {
  return `LR ${lr.avg.toFixed(1)} (${lr.min.toFixed(1)}-${lr.max.toFixed(1)})`;
}

export function getProbabilityFromLR(preTest: number, lr: number) {
  const x = 1 / (1 - preTest / 100) - 1;
  const y = lr * x + 1;
  const z = (1 - 1 / y) * 100;
  return z.toFixed(0);
}

export function getProbabilitiesWithCIFromLR(preTest: number, lr: LR): string {
  return `${getProbabilityFromLR(preTest, lr.avg)}% (${getProbabilityFromLR(
    preTest,
    lr.min
  )}-${getProbabilityFromLR(preTest, lr.max)})`;
}
