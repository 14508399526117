import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import {
  DatabaseStackParamList,
  MainTabNavigationNavigationProp,
} from "../../../navigation/types";
import { CompositeNavigationProp, RouteProp } from "@react-navigation/native";

export interface Actions {
  onRowPress: (name: string, index: number) => void;
}
export type Category = "Diseases" | "Tests" | "Probabilities";

export interface CategoryInfo {
  name: string;
}

export const categories: Record<Category, CategoryInfo> = {
  Diseases: { name: "diseases" },
  Tests: { name: "tests" },
  Probabilities: { name: "probabilities" },
};

export type BrowseScreenNavigationProp = NativeStackNavigationProp<
  DatabaseStackParamList,
  "Browse"
>;

export type BrowseTestSpecialtyScreenNavigationProp = NativeStackNavigationProp<
  DatabaseStackParamList,
  "BrowseTestSpecialty"
>;

export type BrowseDiseaseSpecialtyScreenNavigationProp =
  NativeStackNavigationProp<DatabaseStackParamList, "BrowseDiseaseSpecialty">;

export type BrowseDiseaseSubcategoryScreenNavigationProp =
  NativeStackNavigationProp<DatabaseStackParamList, "BrowseDiseaseSubcategory">;

export type BrowseDiseaseSubcategoryScreenRouteProp = RouteProp<
  DatabaseStackParamList,
  "BrowseDiseaseSubcategory"
>;

export type BrowseTestDiseasesScreenNavigationProp = NativeStackNavigationProp<
  DatabaseStackParamList,
  "BrowseTestDiseases"
>;

export type BrowseTestDiseasesScreenRouteProp = RouteProp<
  DatabaseStackParamList,
  "BrowseTestDiseases"
>;

export type BrowseProbabilityPresentingComplaintsScreenNavigationProp =
  NativeStackNavigationProp<
    DatabaseStackParamList,
    "BrowseProbabilityPresentingComplaints"
  >;

export type BrowseProbabilityPresentingComplaintsScreenRouteProp = RouteProp<
  DatabaseStackParamList,
  "BrowseProbabilityPresentingComplaints"
>;

export type BrowseDiseasesScreenNavigationProp = CompositeNavigationProp<
  NativeStackNavigationProp<DatabaseStackParamList, "BrowseDiseases">,
  MainTabNavigationNavigationProp
>;

export type BrowseDiseasesScreenRouteProp = RouteProp<
  DatabaseStackParamList,
  "BrowseDiseases"
>;
