import { useEffect, useState } from "react";
import { useQuery } from "../useQuery/useQuery";
import { PresentingComplaint } from "./types";

export function useBrowseProbabilityPresentingComplaintsQuery(
  specialty: string
) {
  const [presentingComplaints, setPresentingComplaints] = useState<
    PresentingComplaint[]
  >([]);
  const { execute, isLoading, error } = useQuery<PresentingComplaint[]>({
    server: "database",
    path: `browse/probabilities/${specialty}`,
    type: "GET",
    onCompletion: (response) => {
      setPresentingComplaints(
        response.data.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        )
      );
    },
  });
  useEffect(() => {
    execute();
  }, []);
  return { presentingComplaints, isLoading, error };
}
