import React, { useState } from "react";
import { SearchFilterView } from "./SearchFilterView";
import { useNavigation, useRoute } from "@react-navigation/native";
import { FilterState } from "../../../components/types";
import {
  SearchFilter,
  SearchFilterScreenNavigationProp,
  SearchFilterScreenRouteProp,
} from "./types";

interface SearchFilterState extends FilterState {
  filter: SearchFilter;
}

export function SearchFilterScreen() {
  const route = useRoute<SearchFilterScreenRouteProp>();
  const navigation = useNavigation<SearchFilterScreenNavigationProp>();
  const initialSelectedFilters = route.params.selectedFilters;
  const [filterStates, setFilterStates] = useState(
    initFilterStates(initialSelectedFilters)
  );
  React.useEffect(() => {
    navigation.addListener("beforeRemove", (e) => {
      if (e.data.action.type === "NAVIGATE") {
        return;
      }
      e.preventDefault();
      const selectedFilters = filterStates
        .filter((state) => state.selected)
        .map((state) => state.filter);
      navigation.navigate("MainTabNavigation", {
        screen: "Database",
        params: { screen: "Search", params: { selectedFilters } },
      });
    });
  }, [navigation]);

  const toggleFilter = (filterTitle: string) => {
    const newStates: SearchFilterState[] = [...filterStates];
    newStates.forEach((state) => {
      if (state.title == filterTitle) {
        state.selected = !state.selected;
      }
    });
    setFilterStates(newStates);
  };

  return (
    <SearchFilterView filters={filterStates} toggleFilter={toggleFilter} />
  );
}

const filters: SearchFilter[] = ["diseases", "tests", "probabilities"];

const filterTitles: Record<SearchFilter, string> = {
  diseases: "diseases",
  tests: "tests",
  probabilities: "probabilities",
};

function initFilterStates(selectedFilters: SearchFilter[]) {
  const states: SearchFilterState[] = [];
  filters.forEach((filter) => {
    states.push({
      filter: filter,
      title: filterTitles[filter],
      selected: selectedFilters.includes(filter),
    });
  });
  return states;
}
