import { Acuity, AgeType, Finding, Sex } from "./Home/types";

export interface DDxInput {
  age: number | null;
  ageType: AgeType;
  acuity: Acuity;
  sex: Sex;
  findings: Finding[];
}

export interface DDxResult {
  id: number;
  name: string;
  emergent: boolean;
  probability: { quantitative: number; qualitative: QualitativeProbability };
  pathogenesis: string;
  complications: string;
}

export type QualitativeProbability =
  | "very_likely" //> 50%
  | "likely" //>10-50
  | "somewhat_unlikely" //1-10
  | "unlikely" //0.1-1
  | "very_unlikely" //<0.1
  | "extremely_unlikely";

export type DDxFilter =
  | "emergent"
  | "autoimmune"
  | "endocrine"
  | "genetic"
  | "infectious"
  | "neoplastic"
  | "vascular"
  | "other";

export const ALL_DDX_FILTERS: DDxFilter[] = [
  "emergent",
  "autoimmune",
  "endocrine",
  "genetic",
  "infectious",
  "neoplastic",
  "vascular",
  "other",
];

export const ALL_DDX_FILTERS_WITHOUT_EMERGENT: DDxFilter[] = [
  "autoimmune",
  "endocrine",
  "genetic",
  "infectious",
  "neoplastic",
  "vascular",
  "other",
];
