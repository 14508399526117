import { useQuery } from "../useQuery/useQuery";
import {
  DDxInput,
  DDxResult,
  QualitativeProbability,
} from "../../../sections/DDx/types";
import axios from "axios";
import { APIResult } from "./types";

export function useDDxQuery(
  input: DDxInput,
  onCompletion: (results: DDxResult[]) => void,
  onError: (error: Error) => void
) {
  const { getResults } = useGetDDxResults(onCompletion, onError);
  const { startDDx } = useStartDDxQuery(
    input,
    () => {
      getResults();
    },
    (error) => onError(error)
  );
  const { cancelDDx: cancelDDxAndExecuteQuery } = useCancelDDxQuery(
    () => {
      startDDx();
    },
    (error) => onError(error)
  );
  const { cancelDDx } = useCancelDDxQuery();
  return { generateDDx: cancelDDxAndExecuteQuery, cancelDDx };
}

function useStartDDxQuery(
  input: DDxInput,
  onCompletion: () => void,
  onError: (error: Error) => void
) {
  const { execute } = useQuery({
    server: "database",
    path: `ddx/query`,
    type: "POST",
    onCompletion,
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        // @ts-ignore
        onError(new Error(error.response.data.reason));
      } else {
        onError(error);
      }
    },
  });

  const json = JSON.stringify({
    acuity: input.acuity === "any" ? undefined : input.acuity,
    age: input.age
      ? {
          num: input.age,
          type: input.ageType,
        }
      : undefined,
    sex: input.sex === "either" ? undefined : input.sex,
    symptoms: input.findings.map((finding) => finding.id),
  });

  return { startDDx: () => execute(json) };
}

export function useCancelDDxQuery(
  onCompletion?: () => void,
  onError?: (error: Error) => void
) {
  const { execute } = useQuery({
    server: "database",
    path: `ddx/cancel`,
    type: "POST",
    onCompletion,
    onError,
  });
  return { cancelDDx: execute };
}

export function useGetDDxResults(
  onCompletion: (results: DDxResult[]) => void,
  onError: (error: Error) => void
) {
  const { execute } = useQuery<APIResult[]>({
    server: "database",
    path: `ddx/result`,
    type: "GET",
    onCompletion: (response) => {
      onCompletion(
        response.data.map((apiResult) => {
          const summedIncidence: number = response.data
            .map((el) => Number(el.incidence))
            .reduce((a, b) => a + b);
          const quantitativeProbability =
            (Number(apiResult.incidence) / summedIncidence) * 100;
          return {
            id: Number(apiResult.id.slice(1)),
            name: apiResult.name,
            emergent: apiResult.emergent,
            probability: {
              quantitative: quantitativeProbability,
              qualitative: getQualitativeProbabilityFromIncidence(
                quantitativeProbability
              ),
            },
            pathogenesis: apiResult.pathogenesis,
            complications: apiResult.complications,
          };
        })
      );
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 404) {
          sleepThenExecute(execute);
        } else {
          onError(error);
        }
      } else {
        onError(error);
      }
    },
  });
  return { getResults: execute };
}

async function sleepThenExecute(func: () => void) {
  await sleep(500);
  func();
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function getQualitativeProbabilityFromIncidence(
  percent: number
): QualitativeProbability {
  if (percent >= 50) {
    return "very_likely";
  } else if (percent >= 10) {
    return "likely";
  } else if (percent >= 1) {
    return "somewhat_unlikely";
  } else if (percent >= 0.1) {
    return "unlikely";
  } else if (percent >= 0.01) {
    return "very_unlikely";
  } else {
    return "extremely_unlikely";
  }
}
