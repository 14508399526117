import { Actions, Acuity, AgeType, Sex } from "./types";
import { GenericViewContainer } from "../../../components/GenericViewContainer";
import { Card } from "../../../components/Card";
import { TextInput, View, Text } from "react-native";
import theme from "../../../utilities/theme";
import { GenericRadioButtons } from "../../../components/GenericRadioButtons";
import { ExplanatoryRow } from "../../../components/ExplanatoryRow";
import { SpacerAndDivider } from "../../../components/SpacerAndDivider";
import { Spacer } from "../../../components/Spacer";
import { FindingRow } from "./components/FindingRow";
import { DDxInput } from "../types";
import { GenerateDDxButton } from "./components/GenerateDDxButton";
import { GenericButton } from "../../../components/GenericButton";

interface Props {
  actions: Actions;
  state: DDxInput;
  isLoading: boolean;
}

export function HomeView({ actions, state, isLoading }: Props) {
  const demographicsCard = (
    <Card>
      <ExplanatoryRow
        header={"Age"}
        children={
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <TextInput
              value={state.age ? String(state.age) : undefined}
              style={{
                width: 40,
                borderWidth: 0.5,
                padding: theme.spacing.extraTight,
                borderColor: theme.colors.radioButtonBorder,
                borderRadius: 6,
              }}
              textAlign={"center"}
              keyboardType={"number-pad"}
              placeholder={"Any"}
              placeholderTextColor={theme.colors.textPlaceholder}
              onChangeText={(text) => {
                actions.onAgeInputChange(Number(text), state.ageType);
              }}
            />
            <View style={{ marginRight: theme.spacing.base }} />
            <GenericRadioButtons
              onPress={(index) => {
                actions.onAgeInputChange(
                  state.age,
                  index == 0 ? "months" : "years"
                );
              }}
              activeIndex={ageTypeToToggleIndex(state.ageType)}
              titles={["months", "years"]}
            />
          </View>
        }
      />
      <SpacerAndDivider spacing={"base"} />
      <ExplanatoryRow
        header={"Sex"}
        children={
          <GenericRadioButtons
            onPress={(index) => {
              actions.onSexSelect(sexToggleIndexToType(index));
            }}
            activeIndex={sexTypeToToggleIndex(state.sex)}
            titles={["male", "female", "either"]}
          />
        }
      />
      <SpacerAndDivider spacing={"base"} />
      <ExplanatoryRow
        header={"Acuity"}
        children={
          <GenericRadioButtons
            onPress={(index) => {
              actions.onAcuitySelect(acuityToggleIndexToType(index));
            }}
            activeIndex={acuityTypeToToggleIndex(state.acuity)}
            titles={["acute", "chronic", "any"]}
          />
        }
      />
    </Card>
  );

  const findingsCard = (
    <Card>
      <ExplanatoryRow header="Findings">
        <Spacer spacing={"tight"} />
        {state.findings.map((finding, index) => {
          return (
            <FindingRow
              key={finding.id}
              finding={finding}
              showLowerBorder={index != state.findings.length - 1}
              onRemovePress={(finding) => actions.onRemoveFindingPress(finding)}
            />
          );
        })}
        <Spacer spacing={"base"} />
        <GenericButton
          width={"100%"}
          type={"outlined"}
          onPress={actions.onAddFindingButtonPress}
          title={"Add Finding"}
        />
      </ExplanatoryRow>
    </Card>
  );

  const disclaimer = (
    <Text style={{ color: theme.colors.textSecondary }}>
      <Text style={{ fontWeight: "600" }}>Note:</Text> The disease database does
      not include some common (and many uncommon) diseases. As a result,
      calculated differentials will miss many potential etiologies.
      Nephrological diseases, however, are well represented.
    </Text>
  );

  return (
    <GenericViewContainer keyboardShouldPersistTaps={"never"}>
      {demographicsCard}
      <Spacer spacing={"loose"} />
      {findingsCard}
      <Spacer spacing={"loose"} />
      <View style={{ width: "100%" }}>
        <GenerateDDxButton
          onPress={actions.onGenerateDDxButtonPress}
          isLoading={isLoading}
        />
      </View>
      <Spacer spacing={"loose"} />
      {disclaimer}
    </GenericViewContainer>
  );
}

function sexToggleIndexToType(index: number): Sex {
  switch (index) {
    case 0:
      return "male";
    case 1:
      return "female";
    case 2:
      return "either";
    default:
      throw new Error("invalid sex toggle index");
  }
}

function sexTypeToToggleIndex(sex: Sex): number {
  switch (sex) {
    case "male":
      return 0;
    case "female":
      return 1;
    case "either":
      return 2;
  }
}

function acuityToggleIndexToType(index: number): Acuity {
  switch (index) {
    case 0:
      return "acute";
    case 1:
      return "chronic";
    case 2:
      return "any";
    default:
      throw new Error("invalid acuity toggle index");
  }
}

function acuityTypeToToggleIndex(acuity: Acuity): number {
  switch (acuity) {
    case "acute":
      return 0;
    case "chronic":
      return 1;
    case "any":
      return 2;
  }
}

function ageTypeToToggleIndex(ageType: AgeType): number {
  switch (ageType) {
    case "months":
      return 0;
    case "years":
      return 1;
  }
}
