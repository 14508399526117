import {
  LoginResponse,
  PasswordLoginInfo,
  PasswordLoginResponse,
  Source,
} from "./types";
import { useQuery } from "../../../api/hooks/useQuery/useQuery";
import axios from "axios";
import { Platform } from "react-native";

export function useServerPasswordLogin({
  passwordLoginInfo,
  source,
  onCompletion,
  onError,
}: {
  passwordLoginInfo: PasswordLoginInfo;
  source: Source;
  onCompletion: (
    response: PasswordLoginResponse,
    username?: string,
    userId?: string
  ) => void;
  onError: (error: Error) => void;
}): { execute: () => void } {
  const { execute: executeWithoutJson } = useQuery<LoginResponse>({
    server: "auth",
    path: `auth/password-login-or-sign-up`,
    type: "POST",
    onCompletion: (response) => {
      if (response) {
        if (response.status === 202) {
          onCompletion("EmailNeedsValidation");
        } else if (response.status === 200) {
          onCompletion(
            "EmailIsValidated",
            response.data.username,
            response.data.userId
          );
        }
      }
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          onCompletion("BadPassword");
        }
      } else {
        onError(error);
      }
    },
  });

  const execute = () =>
    executeWithoutJson(JSON.stringify({ ...passwordLoginInfo, source }));

  return { execute };
}
