import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { SvgProps } from "react-native-svg";
import theme from "../utilities/theme";
import { PlatformSpecificIcon } from "./PlatformSpecificIcon";

interface Props {
  isSelected: boolean;
  title: string;
  onPress: () => void;
  icon: React.FC<SvgProps>;
}

export function TabBarButton({ isSelected, title, onPress, icon }: Props) {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      {isSelected ? (
        <>
          <PlatformSpecificIcon
            size={theme.dimensions.iconDefault}
            color={theme.colors.buttonDefault}
            icon={icon}
          />
          <Text style={styles.selectedTitle}>{title}</Text>
        </>
      ) : (
        <>
          <PlatformSpecificIcon
            color={theme.colors.buttonSecondary}
            size={theme.dimensions.iconDefault}
            icon={icon}
          />
          <Text style={styles.unSelectedTitle}>{title}</Text>
        </>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 4,
    marginBottom: 4,
  },
  selectedTitle: {
    fontSize: 10,
    color: theme.colors.buttonDefault,
  },
  unSelectedTitle: {
    fontSize: 10,
    color: theme.colors.textSecondary,
  },
});
