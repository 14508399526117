import React from "react";
import { StyleSheet, Text } from "react-native";
import { useTranslation } from "react-i18next";
import theme from "../../../../utilities/theme";
import { GenericViewContainer } from "../../../../components/GenericViewContainer";

export function NoResultsView() {
  return (
    <GenericViewContainer style={{ flexGrow: 1, justifyContent: "center" }}>
      <Text
        style={{
          fontSize: theme.fontSize.normal,
          fontWeight: "bold",
          marginTop: theme.spacing.tight,
          color: theme.colors.textSubdued,
        }}
      >
        No Results
      </Text>
    </GenericViewContainer>
  );
}
