import React, { useContext, useEffect } from "react";
import "./i18n.config";
import Toast from "react-native-toast-message";
import { NavigationContainer } from "@react-navigation/native";
import AppNavigator from "./navigation/AppNavigator";
import { Session, SessionContext } from "./context/Session/Session";
import { LoginScreen } from "./sections/Login/LoginScreen";
import { useUserInfo } from "./api/hooks/useUserInfo/useUserInfo";
import { StatusBar } from "expo-status-bar";
import { logError } from "./utilities";
import { AppState, SafeAreaView } from "react-native";
import { TermsAndConditionsScreen } from "./sections/Login/TermsAndConditionsScreen";
import { LoadingView } from "./components/LoadingView";

function App() {
  return (
    <>
      <SafeAreaView style={{ flex: 1 }}>
        <Session>
          <MainContainer />
          <Toast />
        </Session>
      </SafeAreaView>
      <StatusBar style="dark" />
    </>
  );
}

function MainContainer() {
  const { hasAcceptedTermsAndConditions, isLoggedIn, setAccess } =
    useContext(SessionContext);
  const { execute: refreshAccess } = useUserInfo(
    (userInfo) => {
      setAccess(userInfo.subscription.databaseAccessLevel);
    },
    (error) => {
      logError(error);
    }
  );
  useEffect(() => {
    const subscription = AppState.addEventListener("change", (appState) => {
      if (appState !== "active") {
        refreshAccess();
      }
      subscription.remove();
    });
  }, []);

  if (isLoggedIn === null || hasAcceptedTermsAndConditions === null) {
    return <LoadingView />;
  } else if (isLoggedIn && hasAcceptedTermsAndConditions) {
    return (
      <NavigationContainer>
        <AppNavigator />
      </NavigationContainer>
    );
  } else {
    return isLoggedIn ? <TermsAndConditionsScreen /> : <LoginScreen />;
  }
}

//import StorybookUI from '../storybook';
//export default StorybookUI;
export default App;
