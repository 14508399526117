import React from "react";
import { StyleSheet, View } from "react-native";
import theme from "../utilities/theme";

export function Divider() {
  return <View style={styles.divider} />;
}

const styles = StyleSheet.create({
  divider: {
    width: "100%",
    borderBottomColor: theme.colors.surfaceDefault,
    borderBottomWidth: 1,
  },
});
