import React from "react";
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { SvgProps } from "react-native-svg";
import theme from "../utilities/theme";
import { PlatformSpecificIcon } from "./PlatformSpecificIcon";

export enum IconButtonType {
  circled,
  plainNoColor,
  plainColored,
}

interface Props {
  onPress?: () => void;
  icon: React.FC<SvgProps>;
  style?: IconButtonType;
  color?: keyof typeof theme.colors;
}

export function IconButton({
  style = IconButtonType.plainNoColor,
  onPress,
  icon,
  color,
}: Props) {
  return (
    <TouchableOpacity style={getContainerStyle(style)} onPress={onPress}>
      <PlatformSpecificIcon
        color={color ? theme.colors[color] : getIconColor(style)}
        size={getIconSize(style)}
        icon={icon}
      />
    </TouchableOpacity>
  );
}

function getIconColor(type: IconButtonType) {
  switch (type) {
    case IconButtonType.circled:
      return theme.colors.textOnFilledButton;
    case IconButtonType.plainColored:
      return theme.colors.buttonDefault;
    case IconButtonType.plainNoColor:
      return theme.colors.textPrimary;
  }
}

function getIconSize(type: IconButtonType) {
  switch (type) {
    case IconButtonType.circled:
      return theme.dimensions.iconDefault * 0.8;
    case IconButtonType.plainColored:
      return theme.dimensions.iconDefault;
    case IconButtonType.plainNoColor:
      return theme.dimensions.iconDefault;
  }
}

function getContainerStyle(type: IconButtonType): StyleProp<ViewStyle> {
  if (type === IconButtonType.circled) {
    return {
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 100,
      backgroundColor: theme.colors.buttonDefault,
      width: theme.dimensions.iconDefault * 1.6,
      height: theme.dimensions.iconDefault * 1.6,
    };
  } else {
    return {
      width: theme.dimensions.iconDefault,
      height: theme.dimensions.iconDefault,
    };
  }
}
