import React from "react";
import { useTranslation } from "react-i18next";
import { getSearchResultTypeStringFromEnum } from "../helpers";
import { SearchResult } from "../types";
import { GenericRow } from "../../../../components/GenericRow";
import { Badge } from "../../../../components/Badge";
import { obscureString } from "../../../../utilities";

interface Props {
  item: SearchResult;
  onPress: () => void;
  onBlockedPress: () => void;
  isBlocked: boolean;
}

export default function SearchResultRow({
  item,
  onPress,
  isBlocked,
  onBlockedPress,
}: Props) {
  const { t } = useTranslation();
  const badgeTitle = getSearchResultTypeStringFromEnum(item.type, t);
  return !isBlocked ? (
    <GenericRow
      primaryTitle={item.title}
      rightView={badgeTitle ? <Badge title={badgeTitle} /> : null}
      onPress={onPress}
    />
  ) : (
    <GenericRow
      primaryTitle={obscureString(item.title)}
      secondaryTitle={"subscribe to view"}
      rightView={badgeTitle ? <Badge title={badgeTitle} /> : null}
      onPress={onBlockedPress}
    />
  );
}
