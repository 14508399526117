import React, { useLayoutEffect } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  Actions,
  ProbabilityModalScreenNavigationProp,
  ProbabilityModalScreenRouteProp,
} from "./types";
import { ProbabilityView } from "./ProbabilityView";
import ReferenceIcon from "../../../resources/icons/book-outline.svg";
import _ from "lodash";
import { HeaderButton } from "../../../components/HeaderButton";
import { View } from "react-native";
import theme from "../../../utilities/theme";
import { useProbabilitiesQuery } from "../../../api/hooks/useProbabilitiesQuery/useProbabilitiesQuery";
import { IconButton, IconButtonType } from "../../../components/IconButton";
import { Probability } from "../../../api/hooks/useProbabilitiesQuery/types";
import { GenericScreen } from "../../../components/GenericScreen";

export function ProbabilityModalScreen() {
  const route = useRoute<ProbabilityModalScreenRouteProp>();
  const navigation = useNavigation<ProbabilityModalScreenNavigationProp>();
  const { probabilities, isLoading, error } = useProbabilitiesQuery({
    id: route.params.testDiseaseId,
    type: "test",
  });
  const references = probabilities ? getUniqueReferences(probabilities) : [];
  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <IconButton
            style={IconButtonType.plainColored}
            onPress={() =>
              navigation.navigate("References", {
                references: references,
              })
            }
            icon={ReferenceIcon}
          />
          <View style={{ marginLeft: theme.spacing.base }} />
          <HeaderButton content={"Done"} />
        </View>
      ),
    });
  });

  const isFromModal = navigation.getState().index == 2;

  const actions: Actions = {
    onProbabilityRowPress: (probability, type) => {
      isFromModal
        ? navigation.navigate("LRModal", {
            screen: "LRCalculatorModal",
            params: {
              id: {
                id: route.params.lrId!,
                type: "lr",
              },
              preTestProbability: probability,
            },
          })
        : navigation.navigate("MainTabNavigation", {
            screen: "Database",
            params: {
              screen: "LRCalculator",
              params: {
                id: {
                  id: route.params.lrId!,
                  type: "lr",
                },
                preTestProbability: probability,
              },
            },
          });
    },
  };

  return (
    <GenericScreen
      content={
        <ProbabilityView
          probabilities={probabilities}
          type="Test"
          actions={actions}
        />
      }
      error={error}
      isLoading={isLoading}
    />
  );
}

function getUniqueReferences(probabilities: Probability[]) {
  const references = probabilities.map((probability) => probability.source);
  return _.uniqBy(references, function (e) {
    return e.string;
  });
}
