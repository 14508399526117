import { APILR, LR } from "../useLRsQuery/types";

export type TestId = {
  id: number;
  type: "lr" | "test";
};

export type APITest = {
  referenceLink: string;
  referenceTitle: string;
  diseaseTitle: string;
  diseaseId: number;
  sourceType: string;
  comment: string;
  population: string;
  goldStandard: string;
  currentLRIndex: number;
  generalLRTitle: string;
  lrs: APILR[];
  hasProbabilitySuggestion: boolean;
};

export interface Test {
  diseaseTitle: string;
  diseaseId: number;
  generalLRTitle: string;
  currentLRIndex: number;
  lrs: LR[];
  sourceType: SourceType;
  referenceTitle: string;
  referenceLink: string | null;
  goldStandard: string;
  population: string;
  comment: string | null;
  hasProbabilitySuggestion: boolean;
}

export enum SourceType {
  metaAnalysis,
  singleStudy,
}
