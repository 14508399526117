import React, { useState } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import { ALL_DDX_FILTERS, DDxFilter } from "../types";
import {
  DDxFilterScreenNavigationProp,
  DDxFilterScreenRouteProp,
} from "./types";
import { FilterState } from "../../../components/types";
import { DDxFilterView } from "./DDxFilterView";

interface DDxFilterState extends FilterState {
  filter: DDxFilter;
}

export function DDxFilterScreen() {
  const route = useRoute<DDxFilterScreenRouteProp>();
  const navigation = useNavigation<DDxFilterScreenNavigationProp>();
  const initialSelectedFilters = route.params.selectedFilters;
  const [filterStates, setFilterState] = useState<DDxFilterState[]>(
    initFilterStates(initialSelectedFilters)
  );

  React.useEffect(() => {
    navigation.addListener("beforeRemove", (e) => {
      if (e.data.action.type === "NAVIGATE") {
        return;
      }
      e.preventDefault();
      navigation.navigate("MainTabNavigation", {
        screen: "DDx",
        params: {
          screen: "DDxResults",
          params: {
            selectedFilters: filterStates
              .filter((state) => state.selected)
              .map((state) => state.filter),
          },
        },
      });
    });
  }, [navigation]);

  const toggleFilter = (filterTitle: string) => {
    const newStates: DDxFilterState[] = [...filterStates];
    newStates.forEach((state) => {
      if (state.title === filterTitle) {
        state.selected = !state.selected;
      }
    });
    setFilterState(newStates);
  };

  return <DDxFilterView filters={filterStates} toggleFilter={toggleFilter} />;
}

function initFilterStates(selectedFilters: DDxFilter[]) {
  const states: DDxFilterState[] = [];
  ALL_DDX_FILTERS.forEach((filter) => {
    states.push({
      filter: filter,
      title: filterTitles[filter],
      selected: selectedFilters.includes(filter),
    });
  });
  return states;
}

const filterTitles: Record<DDxFilter, string> = {
  other: "other",
  endocrine: "endocrine",
  genetic: "genetic",
  infectious: "infectious",
  vascular: "vascular",
  autoimmune: "autoimmune",
  neoplastic: "neoplastic",
  emergent: "emergent only",
};
