import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import theme from "../utilities/theme";
import RightArrowIcon from "../resources/icons/chevron-forward.svg";
import { Divider } from "./Divider";
import { PlatformSpecificIcon } from "./PlatformSpecificIcon";

interface Props {
  primaryTitle: string;
  secondaryTitle?: string;
  tertiaryTitle?: string;
  rightView?: JSX.Element | null;
  onPress?: () => void;
  showLowerBorder?: boolean;
  showCaret?: boolean;
}

export function GenericRow({
  primaryTitle,
  secondaryTitle,
  tertiaryTitle,
  rightView,
  onPress,
  showLowerBorder = true,
  showCaret = false,
}: Props) {
  const content = (
    <View
      style={{
        ...styles.container,
        paddingEnd: showCaret ? 0 : 32,
      }}
    >
      <View style={styles.leftContainer}>
        <Text style={styles.primaryTitleText}>{primaryTitle}</Text>
        {tertiaryTitle && (
          <Text style={styles.secondaryTitleText}>{tertiaryTitle}</Text>
        )}
        {secondaryTitle && (
          <Text style={styles.secondaryTitleText}>{secondaryTitle}</Text>
        )}
      </View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <View style={styles.rightContainer}>{rightView}</View>
        {showCaret ? (
          <View
            style={{
              marginLeft: theme.spacing.tight,
              marginRight: theme.spacing.base,
            }}
          >
            <PlatformSpecificIcon
              icon={RightArrowIcon}
              size={theme.dimensions.smallIconSize}
              color={theme.colors.textSubdued}
            />
          </View>
        ) : null}
      </View>
    </View>
  );
  return onPress ? (
    <>
      <TouchableOpacity onPress={onPress}>{content}</TouchableOpacity>
      {showLowerBorder ? <Divider /> : null}
    </>
  ) : (
    <>
      {content}
      {showLowerBorder ? <Divider /> : null}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingStart: theme.spacing.loose,
    paddingVertical: theme.spacing.base,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.colors.surfaceSecondary,
    minHeight: theme.dimensions.rowHeight,
    width: "100%",
  },
  leftContainer: {
    flexShrink: 1,
  },
  rightContainer: {
    marginLeft: 12,
    flexShrink: 0,
  },
  primaryTitleText: {
    color: theme.colors.textPrimary,
    fontSize: theme.fontSize.normal,
  },
  secondaryTitleText: {
    paddingTop: theme.spacing.extraTight,
    color: theme.colors.textSecondary,
    fontSize: theme.fontSize.small,
  },
});
