import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { StyleSheet, TextInput, useWindowDimensions, View } from "react-native";
import theme from "../utilities/theme";
import { useFocusEffect } from "@react-navigation/native";
import CloseIcon from "../resources/icons/close-outline.svg";
import { IconButton, IconButtonType } from "./IconButton";
import { debounce } from "lodash";

interface Props {
  placeholder: string;
  onExternalSearchFieldTextChange: (text: string) => void;
}

export function SearchBox({
  placeholder,
  onExternalSearchFieldTextChange,
}: Props) {
  const [internalSearchFieldText, setInternalSearchFieldText] = useState("");
  const [externalSearchFieldText, setExternalSearchFieldText] = useState("");
  const textRef = useRef(null);

  const debouncer = useMemo(
    () => debounce((input: string) => setExternalSearchFieldText(input), 300),
    []
  );

  useEffect(() => {
    debouncer(internalSearchFieldText);
    return () => {
      debouncer.cancel();
    };
  }, [internalSearchFieldText]);

  useEffect(() => {
    onExternalSearchFieldTextChange(externalSearchFieldText);
  }, [externalSearchFieldText]);

  useFocusEffect(
    useCallback(() => {
      // @ts-ignore
      setTimeout(() => textRef?.current?.focus(), 100);
    }, [])
  );
  const windowDimension = useWindowDimensions();
  const searchBarWidth = windowDimension.width / 2;
  return (
    <View
      style={{
        width: searchBarWidth,
        flexDirection: "row",
        alignItems: "center",
        height: 44,
      }}
    >
      <TextInput
        selectionColor={theme.colors.textSecondary}
        style={{
          flex: 1,
          color: theme.colors.textPrimary,
          fontSize: theme.fontSize.normal,
          padding: theme.spacing.tight,
        }}
        placeholderTextColor={theme.colors.textPlaceholder}
        placeholder={placeholder}
        ref={textRef}
        onChangeText={setInternalSearchFieldText}
        value={internalSearchFieldText}
      />
      <IconButton
        icon={CloseIcon}
        style={IconButtonType.plainNoColor}
        onPress={() => {
          setInternalSearchFieldText("");
          setExternalSearchFieldText("");
        }}
      />
    </View>
  );
}
