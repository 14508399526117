import React from "react";
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { SvgProps } from "react-native-svg";
import theme from "../../../../utilities/theme";

interface Props {
  onPress?: () => void;
  isLoading: boolean;
}

export function GenerateDDxButton({ onPress, isLoading }: Props) {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        backgroundColor: isLoading
          ? theme.colors.buttonSuccess
          : theme.colors.buttonDefault,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10,
        borderWidth: 0,
        height: theme.dimensions.rowHeight,
      }}
    >
      {isLoading ? (
        <ActivityIndicator
          color={theme.colors.textOnFilledButton}
          size={"small"}
        />
      ) : null}
      <Text
        style={[
          {
            paddingHorizontal: theme.spacing.base,
            fontSize: theme.fontSize.normal,
            color: theme.colors.textOnFilledButton,
          },
        ]}
      >
        {"Generate DDx"}
      </Text>
    </TouchableOpacity>
  );
}
