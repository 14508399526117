import { Platform } from "react-native";
import { SvgProps } from "react-native-svg";
import { hexToCSSFilter } from "hex-to-css-filter";

import React from "react";

interface Props {
  icon: React.FC<SvgProps>;
  size: number;
  color: string;
}

export function PlatformSpecificIcon({ icon: Icon, size, color }: Props) {
  if (Platform.OS !== "web") {
    // @ts-ignore
    return <Icon style={{ width: size, height: size, color: color }} />;
  } else {
    const cssFilter = hexToCSSFilter(color);
    return (
      <img
        style={{ filter: cssFilter.filter.slice(0, -1) }}
        height={size}
        width={size}
        src={Icon as unknown as string}
      />
    );
  }
}
