import React from "react";
import { Actions, categories, Category } from "./types";
import { FlatList, ListRenderItemInfo, Platform } from "react-native";
import { GenericRow } from "../../../components/GenericRow";
import { GenericListViewContainer } from "../../../components/GenericListViewContainer";
import ChevronIcon from "../../../resources/icons/chevron-forward.svg";
import { PlatformSpecificIcon } from "../../../components/PlatformSpecificIcon";
import theme from "../../../utilities/theme";

interface Props {
  actions: Actions;
}

export function BrowseView({ actions }: Props) {
  return (
    <GenericListViewContainer>
      <FlatList
        data={Object.keys(categories)}
        renderItem={({ item, index }: ListRenderItemInfo<Category>) => (
          <GenericRow
            primaryTitle={categories[item].name}
            onPress={() => actions.onRowPress(item, index)}
            showCaret={Platform.OS === "ios"}
          />
        )}
      />
    </GenericListViewContainer>
  );
}
