import React from "react";
import BanIcon from "../resources/icons/ban-outline.svg";
import { StyleSheet, Text } from "react-native";
import theme from "../utilities/theme";
import { useTranslation } from "react-i18next";
import { GenericViewContainer } from "./GenericViewContainer";
import { PlatformSpecificIcon } from "./PlatformSpecificIcon";

interface Props {
  message?: string;
}

export function ErrorView({ message }: Props) {
  const { t } = useTranslation();
  return (
    <GenericViewContainer style={{ flexGrow: 1, justifyContent: "center" }}>
      <PlatformSpecificIcon
        icon={BanIcon}
        size={75}
        color={theme.colors.iconSubdued}
      />

      <Text
        style={{
          fontSize: theme.fontSize.normal,
          fontWeight: "bold",
          marginTop: theme.spacing.base,
          color: theme.colors.textSubdued,
        }}
      >
        {message ? message : t("General.genericErrorMessage")}
      </Text>
    </GenericViewContainer>
  );
}
