import { useQuery } from "../useQuery/useQuery";
import { useEffect, useState } from "react";
import { APITest, SourceType, Test, TestId } from "./types";
import { TestType } from "../useLRsQuery/types";

export function useTestQuery({ id, type }: TestId) {
  const [test, setTest] = useState<Test | null>(null);
  const { execute, isLoading, error } = useQuery<APITest>({
    server: "database",
    path:
      type === "test"
        ? `tests/test-from-test-id/${id}`
        : `tests/test-from-lr-id/${id}`,
    type: "GET",
    onCompletion: (response) => {
      const element = response.data;
      const mappedResponse: Test = {
        comment: element.comment,
        currentLRIndex: element.currentLRIndex,
        diseaseTitle: element.diseaseTitle,
        diseaseId: element.diseaseId,
        generalLRTitle: element.generalLRTitle,
        goldStandard: element.goldStandard,
        lrs: element.lrs.map((lr) => {
          return {
            avg: lr.avg,
            id: lr.id,
            max: lr.max,
            min: lr.min,
            title: lr.title,
            disease: lr.disease,
            type: testTypeMapper(lr.type),
          };
        }),
        population: element.population,
        referenceLink: element.referenceLink,
        referenceTitle: element.referenceTitle,
        sourceType: sourceTypeMapper(element.sourceType),
        hasProbabilitySuggestion: element.hasProbabilitySuggestion,
      };
      setTest(mappedResponse);
    },
  });
  useEffect(() => {
    execute();
  }, []);
  return { test, isLoading, error };
}

function testTypeMapper(string: string): TestType {
  switch (string) {
    case "lab":
      return TestType.lab;
    case "EKG":
      return TestType.EKG;
    case "radiology":
      return TestType.radiology;
    case "history":
      return TestType.history;
    case "decisionRule":
      return TestType.decisionRule;
    case "physicalExam":
      return TestType.physicalExam;
    default:
      throw new Error("bad search source type enum");
  }
}

function sourceTypeMapper(string: string): SourceType {
  switch (string) {
    case "singleStudy":
      return SourceType.singleStudy;
    case "metaAnalysis":
      return SourceType.metaAnalysis;
    default:
      throw new Error("bad search source type enum");
  }
}
