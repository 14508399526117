import {
  Actions,
  BrowseTestDiseasesScreenNavigationProp,
  BrowseTestDiseasesScreenRouteProp,
} from "./types";
import { GenericScreen } from "../../../components/GenericScreen";
import React from "react";
import { GenericList } from "../../../components/GenericList";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useBrowseTestDiseasesQuery } from "../../../api/hooks/useBrowseTestDiseasesQuery/useBrowseDiseasesQuery";
import { Platform } from "react-native";
import { useAnalytics } from "../../../api/hooks/useAnalytics/useAnalytics";

export function BrowseTestDiseasesScreen() {
  const navigation = useNavigation<BrowseTestDiseasesScreenNavigationProp>();
  const route = useRoute<BrowseTestDiseasesScreenRouteProp>();
  const { isLoading, error, diseases } = useBrowseTestDiseasesQuery(
    route.params.specialty
  );
  const sendAnalyticEvent = useAnalytics();

  const actions: Actions = {
    onRowPress: (name, index) => {
      sendAnalyticEvent({
        name: "browse_tests_for_disease_pressed",
        data: JSON.stringify({
          specialty: route.params.specialty,
          disease: diseases[index].name,
        }),
      });
      navigation.navigate("LRList", {
        diseaseName: diseases[index].name,
        diseaseId: { type: "Test", id: diseases[index].id },
      });
    },
  };

  const names: string[] = diseases
    ? diseases.map((disease) => disease.name)
    : [];

  return (
    <GenericScreen
      content={
        <GenericList
          names={names}
          onRowPress={actions.onRowPress}
          showCaret={Platform.OS === "ios"}
        />
      }
      error={error}
      isLoading={isLoading}
    />
  );
}
