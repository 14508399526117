import React, {
  createContext,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { LoginStatus, UserInfo } from "./types";
import { Access } from "../../api/hooks/useUserInfo/types";
import { Platform, PlatformOSType } from "react-native";
import Purchases from "react-native-purchases";
import { setNormalToken, setRefreshToken } from "../../cookies/cookieManager";

interface SessionContextInterface {
  userInfo: UserInfo;
  setUserId: (userId: string | null) => void;
  setUsername: (username: string | null) => void;
  isLoggedIn: boolean;
  hasAcceptedTermsAndConditions: boolean;
  access: Access;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean | null>>;
  setHasAcceptedTermsAndConditions: (
    hasAcceptedTermsAndConditions: boolean | null
  ) => void;
  setAccess: React.Dispatch<React.SetStateAction<Access | null>>;
  logOut: () => void;
}

export const SessionContext = createContext<SessionContextInterface>({
  access: "none",
  userInfo: {
    userId: null,
    username: null,
  },
  setUserId: () => {},
  setUsername: () => {},
  setAccess: () => {},
  setHasAcceptedTermsAndConditions: () => {},
  isLoggedIn: false,
  hasAcceptedTermsAndConditions: false,
  setIsLoggedIn: () => {},
  logOut: () => {},
});

interface Props {
  children: ReactNode;
}

export function Session({ children }: Props) {
  const userInfo = useRef<UserInfo>({
    userId: null,
    username: null,
  });
  const [isLoggedIn, setIsLoggedInState] = useState<boolean | null>(null);
  const [hasAcceptedTermsAndConditions, setHasAcceptedTermsAndConditionsState] =
    useState<boolean | null>(null);
  const [access, setAccessState] = useState<Access>("none");

  useEffect(() => {
    const asyncGetUserInfo = async () => {
      const userInfo = await getUserInfo();
      if (isLoggedIn && userInfo) {
        if (Platform.OS !== "web") {
          Purchases.configure({
            apiKey: getRevCatKey(Platform.OS),
            appUserID: userInfo.userId,
          });
        }
        setUserId(userInfo.userId);
        setUsername(userInfo.username);
      }
    };
    asyncGetUserInfo();
  }, [isLoggedIn]);

  useEffect(() => {
    const asyncGetLoginAndAccessStatus = async () => {
      const loginStatus = await getLoginStatus();
      if (loginStatus) {
        setIsLoggedIn(loginStatus.isLoggedIn);
        setHasAcceptedTermsAndConditions(loginStatus.hasAcceptedTerms);
        setAccess(loginStatus.access);
      }
    };
    asyncGetLoginAndAccessStatus();
  }, []);

  const setHasAcceptedTermsAndConditions = (
    hasAcceptedTerms: boolean | null
  ) => {
    setHasAcceptedTermsAndConditionsState(hasAcceptedTerms);
    if (hasAcceptedTerms === null) {
      AsyncStorage.removeItem("has_accepted_terms");
    } else {
      AsyncStorage.setItem("has_accepted_terms", hasAcceptedTerms.toString());
    }
  };

  const setIsLoggedIn = (isLoggedIn: boolean | null) => {
    setIsLoggedInState(isLoggedIn);
    if (isLoggedIn === null) {
      AsyncStorage.removeItem("is_logged_in");
    } else {
      AsyncStorage.setItem("is_logged_in", isLoggedIn.toString());
    }
  };

  const setUserId = (userId: string | null) => {
    userInfo.current.userId = userId;
    if (userId === null) {
      AsyncStorage.removeItem("user_id");
    } else {
      AsyncStorage.setItem("user_id", userId);
    }
  };
  const setAccess = (access: Access | null) => {
    if (access === null) {
      setAccessState("none");
      AsyncStorage.removeItem("access");
    } else {
      setAccessState(access);
      AsyncStorage.setItem("access", access);
    }
  };
  const setUsername = (name: string | null) => {
    userInfo.current.username = name;
    if (name === null) {
      AsyncStorage.removeItem("username");
    } else {
      AsyncStorage.setItem("username", name);
    }
  };

  const logOut = () => {
    setHasAcceptedTermsAndConditions(false);
    setUsername(null);
    setUserId(null);
    setAccess("none");
    setRefreshToken(null);
    setNormalToken(null);
    if (Platform.OS !== "web") {
      Purchases.logOut();
    }
    setIsLoggedIn(false);
  };

  return (
    <SessionContext.Provider
      value={{
        userInfo: userInfo.current,
        access,
        hasAcceptedTermsAndConditions: hasAcceptedTermsAndConditions ?? false,
        isLoggedIn: isLoggedIn ?? false,
        setUserId,
        setUsername,
        setAccess,
        setHasAcceptedTermsAndConditions,
        setIsLoggedIn,
        logOut,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

async function getLoginStatus(): Promise<LoginStatus | null> {
  const values = await AsyncStorage.multiGet([
    "is_logged_in",
    "has_accepted_terms",
    "access",
  ]);
  const isLoggedIn = values[0][1] === "true";
  const hasAcceptedTerms = values[1][1] === "true";
  const access = values[2][1] as Access;

  return {
    isLoggedIn,
    hasAcceptedTerms,
    access,
  };
}

async function getUserInfo(): Promise<UserInfo | null> {
  const values = await AsyncStorage.multiGet(["user_id", "username"]);
  const userId = values[0][1];
  const username = values[1][1];

  if (userId && username) {
    return {
      userId,
      username,
    };
  } else {
    return null;
  }
}

function getRevCatKey(platform: PlatformOSType) {
  switch (platform) {
    case "android":
      return "goog_xZWBfLmJgGNsreNOWyHyWFTNfnr";
    case "ios":
      return "appl_hxMPHJJsjQoUyaeiPQQnfiRchDV";
    case "web":
      return "strp_rPYeEPHOtDhcauVXbBdahYWbjJh";
    default:
      throw new Error("platform not supported for rev cat");
  }
}
