import { useQuery } from "../useQuery/useQuery";
import { Platform } from "react-native";

export function useAnalytics() {
  const { execute } = useQuery<string[]>({
    server: "analytics",
    path: `event`,
    type: "POST",
  });

  const send = (json: AnalyticEvent) =>
    execute(JSON.stringify({ ...json, origin: Platform.OS }));

  return send;
}
