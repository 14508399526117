import React from "react";
import { Spacer } from "./Spacer";
import { Divider } from "./Divider";
import theme from "../utilities/theme";

interface Props {
  spacing?: keyof typeof theme.spacing;
}

export function SpacerAndDivider({ spacing = "tight" }: Props) {
  return (
    <>
      <Spacer spacing={spacing} />
      <Divider />
      <Spacer spacing={spacing} />
    </>
  );
}
