import React, { useLayoutEffect, useState } from "react";
import {
  Actions,
  DDxResultScreenNavigationProp,
  DDxResultsScreenRouteProp,
} from "./types";
import { DDxResultsView } from "./DDxResultsView";
import { useNavigation, useRoute } from "@react-navigation/native";
import FilterIcon from "../../../resources/icons/filter-outline.svg";
import { DDxFilter, DDxResult } from "../types";
import { NoResultsView } from "../../Database/Search/components/NoResultsView";
import { HeaderButton } from "../../../components/HeaderButton";
import { useAnalytics } from "../../../api/hooks/useAnalytics/useAnalytics";

export function DDxResultsScreen() {
  const route = useRoute<DDxResultsScreenRouteProp>();
  const navigation = useNavigation<DDxResultScreenNavigationProp>();
  const [results, setResults] = useState<DDxResult[]>(route.params.results!);
  const selectedFilters = route.params.selectedFilters;
  const sendAnalyticEvent = useAnalytics();

  const actions: Actions = {
    onResultPress: (id, name) => {
      sendAnalyticEvent({
        name: "ddx_result_pressed",
        data: JSON.stringify({ disease_name: name }),
      });
      navigation.navigate("Disease", { diseaseId: id });
    },
    onBlockedResultPress: () => {
      navigation.navigate("AccountModal");
    },
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButton
          content={FilterIcon}
          onPress={() =>
            navigation.navigate("DDxFilter", {
              selectedFilters: selectedFilters,
            })
          }
        />
      ),
    });
  });

  const filteredResults = filterResultsForFilters(results, selectedFilters);

  return filteredResults.length > 0 ? (
    <DDxResultsView actions={actions} results={filteredResults} />
  ) : (
    <NoResultsView />
  );
}

function filterResultsForFilters(
  results: DDxResult[],
  selectedFilters: DDxFilter[]
) {
  return results.filter((result) => {
    if (selectedFilters.includes("emergent") && !result.emergent) {
      return false;
    }
    if (
      selectedFilters.includes("autoimmune") &&
      result.pathogenesis === "autoimmune"
    ) {
      return true;
    }
    if (
      selectedFilters.includes("infectious") &&
      result.pathogenesis === "infectious"
    ) {
      return true;
    }
    if (
      selectedFilters.includes("endocrine") &&
      result.pathogenesis === "hormonal"
    ) {
      return true;
    }
    if (
      selectedFilters.includes("vascular") &&
      result.pathogenesis === "vascular wall abnormality"
    ) {
      return true;
    }
    if (
      selectedFilters.includes("genetic") &&
      result.pathogenesis === "genetic"
    ) {
      return true;
    }
    if (
      selectedFilters.includes("neoplastic") &&
      result.pathogenesis === "neoplasm"
    ) {
      return true;
    }
    if (
      selectedFilters.includes("other") &&
      !ALL_NON_OTHER_PATHOGENESES.includes(result.pathogenesis)
    ) {
      return true;
    }
    return false;
  });
}

const ALL_NON_OTHER_PATHOGENESES = [
  "autoimmune",
  "neoplastic",
  "vascular wall abnormality",
  "genetic",
  "infectious",
  "hormonal",
];
