import { useQuery } from "../useQuery/useQuery";
import { Platform } from "react-native";
import { Access, APIDatabaseAccessLevel, APIUserInfo, UserInfo } from "./types";

export function useUserInfo(
  onCompletion: (userInfo: UserInfo) => void,
  onError: (error: Error) => void
): {
  execute: () => void;
} {
  const { execute } = useQuery<APIUserInfo>({
    server: "auth",
    path: `auth/user-info`,
    type: "GET",
    onCompletion: (completedResponse) => {
      const mappedResponse: UserInfo = {
        username: completedResponse.data.username,
        subscription: {
          databaseAccessLevel: serverAccessToMobileAccess(
            completedResponse.data.subscription.databaseAccessLevel
          ),
          activeSubscription:
            completedResponse.data.subscription.activeSubscription,
        },
      };
      onCompletion(mappedResponse);
    },
    onError: onError,
  });
  return { execute };
}

export function serverAccessToMobileAccess(
  serverAccess: APIDatabaseAccessLevel
): Access {
  switch (serverAccess) {
    case "none":
      return "none";
    case "fullFreePass":
      return "free_pass";
    case "fullInstitutional":
      return "institutional";
    case "fullIndividualGoogle":
      return Platform.OS === "android"
        ? "individual_same_platform"
        : "individual_different_platform";
    case "fullIndividualApple":
      return Platform.OS === "ios"
        ? "individual_same_platform"
        : "individual_different_platform";
    case "fullIndividualWeb":
      return Platform.OS === "web"
        ? "individual_same_platform"
        : "individual_different_platform";
  }
  throw `invalid access type of ${serverAccess} from server`;
}
