import React from "react";
import { ActivityIndicator, StyleSheet, Text } from "react-native";
import theme from "../utilities/theme";
import { GenericViewContainer } from "./GenericViewContainer";

export function LoadingView() {
  return (
    <GenericViewContainer style={{ flexGrow: 1, justifyContent: "center" }}>
      <ActivityIndicator size="large" color={theme.colors.iconSubdued} />
    </GenericViewContainer>
  );
}
