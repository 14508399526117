import React, { useEffect, useLayoutEffect, useState } from "react";
import { HomeView } from "./HomeView";
import {
  Actions,
  HomeScreenNavigationProp,
  HomeScreenRouteProp,
} from "./types";
import {
  useIsFocused,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { Text, TouchableOpacity } from "react-native";
import theme from "../../../utilities/theme";
import {
  ALL_DDX_FILTERS_WITHOUT_EMERGENT,
  DDxInput,
  DDxResult,
  QualitativeProbability,
} from "../types";
import { useDDxQuery } from "../../../api/hooks/useDDxQuery/useDDxQuery";
import { showErrorToast, showInfoToast } from "../../../utilities/toast";
import { logError } from "../../../utilities";
import { HeaderButton } from "../../../components/HeaderButton";
import { useAnalytics } from "../../../api/hooks/useAnalytics/useAnalytics";

export function HomeScreen() {
  const sendAnalyticEvent = useAnalytics();
  const navigation = useNavigation<HomeScreenNavigationProp>();
  const route = useRoute<HomeScreenRouteProp>();
  const [isLoading, setIsLoading] = useState(false);
  const isFocused = useIsFocused();
  const [state, setState] = useState<DDxInput>({
    age: null,
    ageType: "years",
    acuity: "any",
    sex: "either",
    findings: [],
  });
  const { generateDDx } = useDDxQuery(
    state,
    (results) => {
      if (isFocused) {
        navigation.navigate("DDxResults", {
          results: sortResults(results),
          selectedFilters: ALL_DDX_FILTERS_WITHOUT_EMERGENT,
        });
      }
      sendAnalyticEvent({ name: "ddx_loaded" });
      setIsLoading(false);
    },
    (error) => {
      logError(error);
      showErrorToast(error.message);
      setIsLoading(false);
    }
  );

  useEffect(() => {
    if (route.params?.findingToAdd) {
      const newFinding = route.params.findingToAdd;
      const _findings = [...state.findings];
      if (!_findings.map((el) => el.id).includes(newFinding.id)) {
        _findings.push(newFinding);
      }
      setState({
        ...state,
        findings: _findings,
      });
    }
  }, [route.params?.findingToAdd]);

  const actions: Actions = {
    onSexSelect: (sex) => {
      setState({ ...state, sex: sex });
    },
    onAcuitySelect: (acuity) => {
      setState({ ...state, acuity: acuity });
    },
    onAgeInputChange: (age, ageType) => {
      console.log(ageType);
      setState({ ...state, age: age, ageType: ageType });
    },
    onAddFindingPress: (finding) => {
      let _findings = [...state.findings];
      _findings.push(finding);
      setState({ ...state, findings: _findings });
    },
    onRemoveFindingPress: (finding) => {
      let _findings = [...state.findings];
      _findings = _findings.filter((item) => item.id !== finding.id);
      setState({ ...state, findings: _findings });
    },
    onGenerateDDxButtonPress: () => {
      if (!validateInput(state)) {
        showInfoToast("Add one or more findings");
        return;
      }
      sendAnalyticEvent({
        name: "request_ddx",
        data: JSON.stringify({
          findings: state.findings.map((finding) => finding.name),
          sex: state.sex,
          age: `${state.age} ${state.ageType}`,
          acuity: state.acuity,
        }),
      });
      setIsLoading(true);
      generateDDx();
    },
    onResetButtonPress: () => {
      setState({
        age: null,
        ageType: "years",
        acuity: "any",
        sex: "either",
        findings: [],
      });
    },
    onAddFindingButtonPress: () => {
      navigation.navigate("SearchFindings");
    },
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButton onPress={actions.onResetButtonPress} content={"Reset"} />
      ),
    });
  });

  return <HomeView actions={actions} state={state} isLoading={isLoading} />;
}

function validateInput(input: DDxInput): boolean {
  if (input.findings.length === 0) {
    return false;
  }
  return true;
}

function sortResults(results: DDxResult[]): DDxResult[] {
  return results.sort((a, b) => {
    return (
      sortScore[b.probability.quantitative] -
      sortScore[a.probability.quantitative]
    );
  });
}

const sortScore: Record<QualitativeProbability, number> = {
  very_likely: 5,
  likely: 4,
  unlikely: 3,
  somewhat_unlikely: 2,
  very_unlikely: 1,
  extremely_unlikely: 0,
};
