import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { SvgProps } from "react-native-svg";
import theme from "../utilities/theme";
import { PlatformSpecificIcon } from "./PlatformSpecificIcon";

export type GenericButtonType = "clear" | "outlined" | "filled";

interface Props {
  title: string;
  type?: GenericButtonType;
  color?: keyof typeof theme.colors;
  onPress?: () => void;
  icon?: React.FC<SvgProps>;
  paddingVertical?: keyof typeof theme.spacing;
  width?: string | number;
  showBorder?: boolean;
}

export function GenericButton({
  title,
  type = "clear",
  color = "buttonDefault",
  paddingVertical,
  onPress,
  width,
  icon: Icon,
  showBorder = true,
}: Props) {
  const backgroundColor = backgroundColorFromType(type, color);

  const borderColor =
    type === "outlined" ? theme.colors[color] : theme.colors["transparent"];

  let textColor: string;
  switch (type) {
    case "outlined":
      textColor = theme.colors[color];
      break;
    case "filled":
      textColor = theme.colors.surfaceSecondary;
      break;
    default:
      textColor = theme.colors[color];
  }

  const borderWidth = type === "clear" || !showBorder ? 0 : 1;
  return (
    <View style={{ width: width }}>
      <TouchableOpacity onPress={onPress}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 10,
            borderWidth: borderWidth,
            backgroundColor: theme.colors[backgroundColor],
            borderColor: borderColor,
            paddingHorizontal: theme.spacing.tight,
            paddingVertical: theme.spacing[paddingVertical ?? "extraTight"],
          }}
        >
          {Icon && (
            <PlatformSpecificIcon
              color={textColor}
              size={theme.dimensions.iconDefault}
              icon={Icon}
            />
          )}
          <Text
            style={{
              paddingHorizontal: theme.spacing.base,
              fontSize: theme.fontSize.normal,
              color: textColor,
              paddingVertical: theme.spacing.tight,
            }}
          >
            {title}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}

function backgroundColorFromType(
  type: GenericButtonType,
  color: keyof typeof theme.colors
): keyof typeof theme.colors {
  switch (type) {
    case "clear":
      return "transparent";
    case "outlined":
      return "surfaceSecondary";
    case "filled":
      return color;
  }
}
