import axios, { AxiosResponse } from "axios";
import { Platform } from "react-native";
import { useContext, useEffect, useRef, useState } from "react";
import { SessionContext } from "../../../context/Session/Session";
import { QueryType, Server } from "./types";
import Constants from "expo-constants";
import {
  getNormalToken,
  getRefreshToken,
  setNormalToken,
  setRefreshToken,
} from "../../../cookies/cookieManager";

export function useQuery<T>({
  server,
  path,
  type,
  onCompletion,
  onError,
}: {
  server: Server;
  path: string;
  type: QueryType;
  onCompletion?: (response: AxiosResponse<T>) => void;
  onError?: (error: Error) => void;
}): {
  isLoading: boolean;
  error: Error | null;
  execute: (json?: string) => void;
} {
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const attempts = useRef(0);
  const { logOut } = useContext(SessionContext);
  let baseURL: string;
  switch (server) {
    case "auth":
      baseURL = __DEV__
        ? Constants.expoConfig!.extra!.DEV_AUTH_SERVER
        : Constants.expoConfig!.extra!.PROD_AUTH_SERVER;
      break;
    case "analytics":
      baseURL = __DEV__
        ? Constants.expoConfig!.extra!.DEV_ANALYTICS_SERVER
        : Constants.expoConfig!.extra!.PROD_ANALYTICS_SERVER;
      break;
    case "database":
      baseURL = __DEV__
        ? Constants.expoConfig!.extra!.DEV_DATABASE_SERVER
        : Constants.expoConfig!.extra!.PROD_DATABASE_SERVER;
      break;
  }

  const handleError = async (error: Error) => {
    console.error(error.message);
    if (axios.isAxiosError(error)) {
      if (error?.response?.status === 401) {
        logOut();
      } else if (String(error?.response?.status)[0] === "5") {
        attempts.current = attempts.current + 1;
        if (attempts.current < 3) {
          console.log(`failed on attempt ${attempts.current}, trying again`);
          await sleep(1000);
          await fetch();
        } else {
          console.log("too many fails");
        }
      } else {
        if (onError) {
          onError(error);
        }
        setError(error);
      }
    } else {
      if (onError) {
        onError(error);
      }
      setError(error);
    }
  };

  const fetch = async (json?: string) => {
    setIsLoading(true);
    const headers =
      Platform.OS === "web"
        ? { "Content-Type": "application/json" }
        : {
            "Content-Type": "application/json",
            Cookie: `doclogica-normal-token=${await getNormalToken()};doclogica-refresh-token=${await getRefreshToken()}`,
          };
    const postParams = [
      `${baseURL}/${path}`,
      json,
      {
        headers: headers,
        withCredentials: Platform.OS === "web",
      },
    ] as const;
    const getParams = [
      `${baseURL}/${path}`,
      {
        headers: headers,
        withCredentials: Platform.OS === "web",
      },
    ] as const;

    switch (type) {
      case "GET":
        try {
          // @ts-ignore
          const axiosResponse = await axios.get<T>(...getParams);
          if (Platform.OS !== "web") {
            if (axiosResponse.headers["set-cookie"]) {
              await parseAndStoreCookies(
                axiosResponse.headers["set-cookie"][0]
              );
            }
          }
          if (onCompletion) {
            // @ts-ignore
            onCompletion(axiosResponse);
          }
        } catch (error) {
          await handleError(error);
        }
        break;
      case "POST":
        try {
          console.log(...postParams);
          // @ts-ignore
          const axiosResponse = await axios.post<T>(...postParams);
          if (Platform.OS !== "web") {
            if (axiosResponse.headers["set-cookie"]) {
              await parseAndStoreCookies(
                axiosResponse.headers["set-cookie"][0]
              );
            }
          }
          if (onCompletion) {
            // @ts-ignore
            onCompletion(axiosResponse);
          }
        } catch (error) {
          await handleError(error);
        }
        break;
    }
    setIsLoading(false);
  };

  async function parseAndStoreCookies(cookieString: string) {
    let refreshToken: string | undefined = undefined;
    let normalToken: string | undefined = undefined;
    const commaSplitItems = cookieString.split(", ");
    commaSplitItems.forEach((commaSplitItem) => {
      const semiColonSplitItems = commaSplitItem.split(";");
      semiColonSplitItems.forEach((semiColonSplitItem) => {
        const equalSplit = semiColonSplitItem.split("=");
        if (equalSplit[0] == "doclogica-refresh-token") {
          refreshToken = equalSplit[1];
        }
        if (equalSplit[0] == "doclogica-normal-token") {
          normalToken = equalSplit[1];
        }
      });
    });
    if (normalToken && refreshToken) {
      console.log(`storing normal token: ${normalToken}`);
      console.log(`storing refresh token: ${refreshToken}`);
      await setRefreshToken(refreshToken);
      await setNormalToken(normalToken);
    } else {
      throw new Error("no cookies");
    }
  }
  return {
    isLoading,
    error,
    execute: (json?: string) => fetch(json),
  };
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
