import * as Google from "expo-auth-session/providers/google";
import { useEffect, useState } from "react";
import { useQuery } from "../../../api/hooks/useQuery/useQuery";
import { LoginResponse } from "./types";
import { Platform } from "react-native";

export function useGoogleLogin({
  onCompletion,
  onError,
}: {
  onCompletion: (userName: string, userId: string) => void;
  onError: (error: Error) => void;
}): {
  execute: () => void;
} {
  const [request1, googleResponse, promptGoogleAsync] = Google.useAuthRequest({
    expoClientId:
      "628334874711-b6tclj8icau0cg231i31gbomldql91o9.apps.googleusercontent.com",
    clientId: __DEV__
      ? "628334874711-sbs4q88etmvnjock0ahmcv1urce0f1uh.apps.googleusercontent.com"
      : "628334874711-7gpi3rjr4t1lk1mje2thb58jrmu3espr.apps.googleusercontent.com",
    webClientId:
      "628334874711-vaon8615bh0p5fod6rqv4p43d0ii7g95.apps.googleusercontent.com",
    iosClientId:
      "628334874711-gjhegofga6gohobpo51q9db361hl7l8e.apps.googleusercontent.com",
    clientSecret:
      Platform.OS === "web" ? "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" : undefined,
  });
  const { execute: serverGoogleLogin } = useQuery<LoginResponse>({
    server: "auth",
    path: `auth/google-login`,
    type: "POST",
    onError: (error) => onError(error),
    onCompletion: (response) => {
      onCompletion(response.data.username, response.data.userId);
    },
  });

  useEffect(() => {
    if (googleResponse?.type === "success") {
      const json = JSON.stringify({
        token: googleResponse.params.id_token,
        source: Platform.OS,
      });
      serverGoogleLogin(json);
    } else if (googleResponse?.type === "error") {
      onError(new Error("google API login error"));
    }
  }, [googleResponse]);

  return { execute: promptGoogleAsync };
}
