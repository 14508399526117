import { StyleProp, StyleSheet, TextInput, TextStyle } from "react-native";
import React from "react";
import theme from "../utilities/theme";

export interface Props {
  style?: StyleProp<TextStyle>;
  placeholder: string;
  onChangeText?: (text: string) => void;
  secureTextEntry?: boolean;
}

export function GenericTextInput({
  style,
  placeholder,
  onChangeText,
  secureTextEntry = false,
}: Props) {
  return (
    <TextInput
      onChangeText={onChangeText}
      placeholderTextColor={theme.colors.textPlaceholder}
      style={[styles.textInput, style]}
      placeholder={placeholder}
      secureTextEntry={secureTextEntry}
    />
  );
}

const styles = StyleSheet.create({
  textInput: {
    padding: theme.spacing.tight,
    borderWidth: 1,
    borderRadius: 10,
    height: theme.dimensions.rowHeight,
    fontSize: theme.fontSize.normal,
    borderColor: theme.colors.textInputBorderDefault,
  },
});
