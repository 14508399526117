import React, { useContext } from "react";
import { SessionContext } from "../../context/Session/Session";
import { logError } from "../../utilities";
import { showErrorToast, showInfoToast } from "../../utilities/toast";
import { AccountScreenNavigationProp, Actions } from "./types";
import { useRestorePurchases } from "./hooks/useRestorePurchase/useRestorePurchases";
import { useGetSubscription } from "./hooks/useGetSubscription/useGetSubscription";
import { usePurchaseSubscription } from "./hooks/usePurchaseSubscription/usePurchaseSubscription";
import { useServerDeleteAccount } from "./hooks/useServerDeleteAccount/useServerDeleteAccount";
import { useServerLogout } from "./hooks/useServerLogout/useServerLogout";
import { showSuccessToast } from "../../utilities/toast/toasts";
import { GenericScreen } from "../../components/GenericScreen";
import { AccountView } from "./AccountView";
import * as Linking from "expo-linking";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { Access } from "../../api/hooks/useUserInfo/types";
import { useServerDeleteNormalToken } from "./hooks/useServerDeleteNormalToken/useServerDeleteNormalToken";
import { Platform } from "react-native";

export function AccountScreen() {
  const navigation = useNavigation<AccountScreenNavigationProp>();
  const { error, isLoading, subscription, refresh } = useGetSubscription();
  const { execute: deleteNormalTokenAndThenRefresh } =
    useServerDeleteNormalToken({
      onCompletion: () => {
        refresh();
      },
      onError: (error) => {
        showErrorToast("There was a problem restoring your purchases.");
        logError(error);
      },
    });
  const { access, logOut } = useContext(SessionContext);

  const { execute: restorePurchases } = useRestorePurchases({
    onCompletion: () => {
      showSuccessToast("Success", "Purchases have been successfully restored.");
      deleteNormalTokenAndThenRefresh();
    },
    onError: (error) => {
      logError(error);
      showErrorToast("There was a problem restoring your purchases.");
    },
  });
  const { execute: purchaseMonthlySubscription } = usePurchaseSubscription(
    subscription?.monthlyId ?? null,
    () => {
      deleteNormalTokenAndThenRefresh();
    }
  );
  const { execute: purchaseAnnualSubscription } = usePurchaseSubscription(
    subscription?.annualId ?? null,
    () => {
      deleteNormalTokenAndThenRefresh();
    }
  );
  const { execute: deleteAccount } = useServerDeleteAccount(
    () => logOut(),
    (error) => {
      logError(error);
      showErrorToast("There was a problem deleting your account.");
    }
  );
  const { execute: executeLogout } = useServerLogout(
    () => logOut(),
    (error) => {
      logError(error);
      showErrorToast("There was a problem logging out from your account.");
    }
  );

  const actions: Actions = {
    logout: executeLogout,
    deleteAccount,
    restorePurchases: () => {
      showInfoToast("Working...", true);
      restorePurchases();
    },
    purchaseMonthlySubscription,
    purchaseAnnualSubscription,
    showInfo: () => {
      navigation.navigate("About");
    },
    contactUs: () => {
      Linking.openURL(`mailto:contact@doclogica.com`);
    },
    deleteNormalTokenAndThenRefresh,
  };

  useFocusEffect(
    React.useCallback(() => {
      refresh();
    }, [])
  );

  const showPurchaseSubscription =
    !hasActiveDifferentPlatformSubscription(access) &&
    access !== "free_pass" &&
    access !== "institutional" &&
    Platform.OS !== "web";

  return (
    <GenericScreen
      content={
        <AccountView
          subscription={subscription}
          actions={actions}
          showPurchaseSubscription={showPurchaseSubscription}
        />
      }
      error={error}
      isLoading={isLoading}
    />
  );
}

function hasActiveDifferentPlatformSubscription(access: Access) {
  if (access === "individual_different_platform") {
    return true;
  } else {
    return false;
  }
}
