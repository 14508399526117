import { TermsAndConditionsView } from "./TermsAndConditionsView";
import * as Linking from "expo-linking";
import { useContext } from "react";
import { SessionContext } from "../../context/Session/Session";

export function TermsAndConditionsScreen() {
  const { logOut, setHasAcceptedTermsAndConditions } =
    useContext(SessionContext);
  return (
    <TermsAndConditionsView
      onExternalTermsAndConditionsPress={() => {
        Linking.openURL(`https://app.doclogica.com/html/termsofuse.html`);
      }}
      onAcceptPress={() => setHasAcceptedTermsAndConditions(true)}
      onDeclinePress={() => logOut()}
    />
  );
}
