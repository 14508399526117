import React from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import { DiseaseView } from "./DiseaseView";
import { useDiseaseQuery } from "../../../api/hooks/useDiseaseQuery/useDiseaseQuery";
import {
  Actions,
  DiseaseScreenNavigationProp,
  DiseaseScreenRouteProp,
} from "./types";
import { Linking, View } from "react-native";
import { GenericScreen } from "../../../components/GenericScreen";
import { useIsIdBlocked } from "../../../api/hooks/useIsIdBlocked/useIsIdBlocked";
export function DiseaseScreen() {
  const route = useRoute<DiseaseScreenRouteProp>();
  const { disease, isLoading, error } = useDiseaseQuery(route.params.diseaseId);
  const navigation = useNavigation<DiseaseScreenNavigationProp>();
  const isBlocked = useIsIdBlocked();

  const actions: Actions = {
    onTestButtonPress: () => {
      navigation.navigate("LRModal", {
        screen: "LRListModal",
        params: {
          diseaseName: disease!.name,
          diseaseId: { type: "Database", id: disease!.id },
        },
      });
    },
    onWikiButtonPress: () => {
      if (disease!.wiki) {
        Linking.openURL(disease!.wiki);
      }
    },
    onReferenceButtonPress: () => {
      navigation.navigate("References", {
        references: disease!.sources,
      });
    },
    onFindingRowPress: (findingId) => {
      if (!isBlocked(findingId)) {
        navigation.push("Disease", {
          diseaseId: findingId,
        });
      } else {
        navigation.navigate("AccountModal");
      }
    },
  };

  return (
    <GenericScreen
      content={<DiseaseView disease={disease} actions={actions} />}
      error={error}
      isLoading={isLoading}
    />
  );
}
